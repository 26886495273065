import { gql } from "@apollo/client";

export const DELETE_DISCLOSURE_MUTATION = gql`
  mutation DeleteDisclosure($deleteDisclosureInput: DeleteDisclosureInput!) {
    deleteDisclosure(deleteDisclosureInput: $deleteDisclosureInput) {
      data
      succeeded
    }
  }
`;

export default DELETE_DISCLOSURE_MUTATION;
