import { useQuery } from "@apollo/client";
import { useCaseId } from "../globalStore/hooks";

import { GET_ATTRIBUTES_QUERY } from "../graphql/queries/getAttributes";
import { AttributesQuery_ATTRIBUTE_TYPES, CONFIGURABLES } from "./CONSTANTS";

export function useQueryAtrributes({
  type,
  attributeTypes = AttributesQuery_ATTRIBUTE_TYPES.WRITABLE,
}) {
  const caseId = useCaseId();
  const getAttributesInput = {
    caseId,
    type,
    attributeTypes,
    version: CONFIGURABLES.REACT_APP_FACADE_VERSION,
  };
  const { data, ...rest } = useQuery(GET_ATTRIBUTES_QUERY, {
    variables: { getAttributesInput },
  });

  let result;
  if (data) {
    const [attributes] = data.attributes;
    result = { attributes };
  }
  return { data: result, ...rest };
}
