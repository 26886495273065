import { DISCLOSURE_SOURCES } from "../../utils/CONSTANTS";
import { DiclosureSourcePicklist } from "./DiclosureSourcePicklist";
import { DiclosureSourceSearch } from "./DiclosureSourceSearch";
import { DiclosureSourceSpecified } from "./DiclosureSourceSpecified";

export function RenderByDisclosureSource(question) {
  const { disclosureSource } = question;
  switch (disclosureSource) {
    case DISCLOSURE_SOURCES.SPECIFIED:
      return <DiclosureSourceSpecified question={question} />;
    case DISCLOSURE_SOURCES.PICKLIST:
      return <DiclosureSourcePicklist question={question} />;
    case DISCLOSURE_SOURCES.SEARCH:
      return <DiclosureSourceSearch question={question} />;
    default:
      return null;
  }
}
