export function getGraphQlUri() {
  return window.REACT_APP_GRAPHQL_API_URL;
}

export function setGraphQlUri(uri) {
  window.REACT_APP_GRAPHQL_API_URL = uri;
}

export function getSubscriptionKey() {
  return window.Ocp_Apim_Subscription_Key;
}

export function setSubscriptionKey(key) {
  window.Ocp_Apim_Subscription_Key = key;
}
