import propTypes from "prop-types";
import parse from "html-react-parser";
import { GelFormField, GelLabel, GelTooltip } from "@tal-gel/components";
import {
  GelBoxLayout,
  GelContainerLite,
  GelScreenDetectorContext,
} from "@tal-gel/components";
import { useQueryHintTextList } from "../utils/useQueryHintTextList";
import { WarningMessage } from "./WarningMessage";
import { AlertBar } from "./UIUtilities/Alert/AlertBar";
import {
  BASE_QUES_TYPES,
  CUSTOM_QUES_TYPE,
  ALERT_TYPES,
} from "../utils/CONSTANTS";
import { LabelText } from "./UIUtilities/LabelText/LabelText";
import { FormField } from "./QuestionInput/FormField";
import { useContext } from "react";

export function QuestionContainer({ question, hasLinkedQuestions, children }) {
  const { hintTextList } = useQueryHintTextList();
  const { questionText, questionType, customProperties } = question;
  const { CMS, DO_NOT_DISPLAY, CUSTOM_TYPE, STYLE } = customProperties || {};
  const tooltip =
    hintTextList &&
    typeof hintTextList[CMS] === "string" &&
    parse(hintTextList[CMS] || "");

  const doNotDisplay = DO_NOT_DISPLAY && DO_NOT_DISPLAY === "true";

  const labelOnly =
    questionType === BASE_QUES_TYPES.HEADING &&
    CUSTOM_TYPE !== CUSTOM_QUES_TYPE.PICKLIST_HEADING &&
    CUSTOM_TYPE !== CUSTOM_QUES_TYPE.WARNING;

  const warning =
    questionType === BASE_QUES_TYPES.HEADING &&
    CUSTOM_TYPE === CUSTOM_QUES_TYPE.WARNING &&
    CUSTOM_TYPE !== CUSTOM_QUES_TYPE.PICKLIST_HEADING;

  const { screen, isXsScreen, isSmScreen, isMdScreen } = useContext(
    GelScreenDetectorContext
  );

  if (doNotDisplay) {
    return children;
  }

  const label = questionText || "question text not received";

  if (labelOnly) {
    switch (STYLE) {
      case ALERT_TYPES.INFO_ALERT:
        return (
          <GelContainerLite
            gutter={{ left: 0, bottom: 15 }}
            style={{
              width: "fit-content",
            }}
          >
            <AlertBar alertText={label} infoType></AlertBar>
          </GelContainerLite>
        );
      case ALERT_TYPES.INFO_ALERT_TABULAR:
        return (
          <GelContainerLite
            style={{
              width:
                isXsScreen(screen) || isSmScreen(screen) || isMdScreen(screen)
                  ? "100%"
                  : "85%",
            }}
            gutter={{ left: 30 }}
          >
            <AlertBar alertText={label} infoType></AlertBar>
          </GelContainerLite>
        );
      default:
        return (
          <>
            <GelLabel
              style={{
                display: "block",
                paddingBottom: hasLinkedQuestions ? "20px" : "unset",
              }}
            >
              <GelBoxLayout space="auto" distribution="start">
                <LabelText
                  labelText={label}
                  labelTextNormal={!hasLinkedQuestions}
                ></LabelText>
                {tooltip ? (
                  <GelTooltip tooltipFixedAtTopLevel right>
                    {tooltip}
                  </GelTooltip>
                ) : (
                  ""
                )}
              </GelBoxLayout>
            </GelLabel>
            {hasLinkedQuestions && <GelBoxLayout>{children}</GelBoxLayout>}
          </>
        );
    }
  }
  if (warning) {
    return (
      <GelLabel>
        <GelBoxLayout space="auto" distribution="start">
          <WarningMessage message={questionText} warningType />
        </GelBoxLayout>
      </GelLabel>
    );
  }

  if (hasLinkedQuestions) {
    return <>{children}</>;
  }

  if (CUSTOM_TYPE === CUSTOM_QUES_TYPE.TABLE) {
    return (
      <FormField
        questionText={questionText}
        customProperties={customProperties}
      >
        {children}
      </FormField>
    );
  }

  return <GelFormField>{children}</GelFormField>;
}
QuestionContainer.propTypes = {
  question: propTypes.shape({
    questionText: propTypes.string,
    questionType: propTypes.string,
    customProperties: propTypes.shape({
      CUSTOM_TYPE: propTypes.string,
      CMS: propTypes.string,
      DO_NOT_DISPLAY: propTypes.string,
      STYLE: propTypes.string,
    }),
  }).isRequired,
  hasLinkedQuestions: propTypes.bool,
  children: propTypes.node,
};
