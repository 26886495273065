import { useSelector } from "react-redux";

export function useCaseId() {
  const caseId = useSelector((state) => state.caseId);
  return caseId;
}
export function useBmiData() {
  const bmiData = useSelector((state) => state.bmiData);
  return bmiData;
}

export function useCurrentUserRole() {
  const currentUserRole = useSelector((state) => state.currentUserRole);
  return currentUserRole;
}
export function useApplicationStatus() {
  const applicationStatus = useSelector((state) => state.applicationStatus);
  return applicationStatus;
}

export function useSectionData() {
  const sectionData = useSelector((state) => state.sectionData);
  return sectionData;
}

export function useConfigData() {
  const configData = useSelector((state) => state.configData);
  return configData;
}

export function usePages() {
  const pagesData = useSelector((state) => state.pages);
  return pagesData;
}

export function useNavigationButtonStatus() {
  const navigationButtonStatus = useSelector(
    (state) => state.navigationButtonStatus
  );
  return navigationButtonStatus;
}
export function useErrorData() {
  const errorData = useSelector((state) => state.errorData);
  return errorData;
}
export function useEmpTableRow() {
  const empRowData = useSelector((state) => state.empTableRowValues);
  return empRowData;
}

export function useSectionExpandData() {
  const sectionExpandData = useSelector((state) => state.sectionExpandData);
  return sectionExpandData;
}
