import PropTypes from "prop-types";
import { GelBoxLayout } from "@tal-gel/components";
import { ColumnText } from "../CustomTablesShared/LabelColumn";
import { CUSTOM_QUES_TYPE, INFO_MESSAGES } from "../../../utils/CONSTANTS";
import { QuestionInputContainer } from "../../QuestionInput/QuestionInputContainer";
import ReadOnlyTextBox from "../../QuestionInput/NumericQuestionReadOnly";
import { setIncomeValue } from "../../../globalStore/income-slice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateBaseQuestionAnswerInCache } from "../../../utils/commonFunctions";
import { CaptionText } from "../../CaptionText";
import { useAnswerBaseQuestion } from "../../../utils/useAnswerBaseQuestion";

function IncomeRow({ childBaseQuestions, labelData, parentQuestion }) {
  const { customProperties } = parentQuestion;
  const { CDI_NAME } = customProperties;
  return (
    <GelBoxLayout alignment="start">
      <ColumnText {...labelData} />
      {childBaseQuestions.map((question) =>
        question.customProperties &&
        question.customProperties.CUSTOM_TYPE === CUSTOM_QUES_TYPE.READONLY ? (
          <IncomeCellReadOnly
            key={question.code}
            question={question}
            parentCdiName={CDI_NAME}
          />
        ) : (
          <IncomeCellUserInput
            key={question.code}
            question={question}
            parentCdiName={CDI_NAME}
          />
        )
      )}
    </GelBoxLayout>
  );
}

IncomeRow.propTypes = {
  childBaseQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      customProperties: PropTypes.shape({
        CUSTOM_TYPE: PropTypes.string,
      }).isRequired,
    }).isRequired
  ).isRequired,
  labelData: PropTypes.shape({
    label: PropTypes.string.isRequired,
    tooltipCode: PropTypes.string,
  }),
  parentQuestion: PropTypes.object.isRequired,
};

export default IncomeRow;

function IncomeCellUserInput({ question, parentCdiName }) {
  const dispatch = useDispatch();
  const { answerValue } = question;
  const incomeData = useSelector((state) => state.incomeData);
  const questionUnSaved =
    (incomeData && incomeData[question.code].unSaved) || false;
  const answerValueFromState =
    incomeData && incomeData[question.code].cdiValue !== null
      ? incomeData[question.code].cdiValue.toString()
      : null;

  const answerValueString =
    answerValue !== null ? answerValue.toString() : null;

  function handleIncomeInputValueChange(e) {
    const { name: code, value: cdiValue } = e.target;
    dispatch(
      setIncomeValue({
        code,
        cdiValue,
      })
    );

    parentCdiName &&
      updateBaseQuestionAnswerInCache({
        baseQuestionCode: code,
        answerValue: cdiValue,
      });
  }
  return (
    <>
      <QuestionInputContainer
        key={question.code}
        question={{
          ...question,
          answerValue: parentCdiName ? answerValueFromState : answerValueString,
          callbackFunctions: [
            ...question.callbackFunctions,
            handleIncomeInputValueChange,
          ],
        }}
        answerBaseQuestionRequired={!parentCdiName}
      />
      {questionUnSaved && parentCdiName && (
        <CaptionText
          errorType
          textMessage={INFO_MESSAGES.UNSAVED_WARNING_MESSAGE}
          marginTop="none"
          marginBottom="xsmall"
        ></CaptionText>
      )}
    </>
  );
}

IncomeCellUserInput.propTypes = {
  question: PropTypes.object.isRequired,
  parentCdiName: PropTypes.string,
};

function IncomeCellReadOnly({ question, parentCdiName }) {
  const { code, customProperties = {} } = question;
  const { answerBaseQuestion } = useAnswerBaseQuestion(question.answerValue);
  const incomeData = useSelector((state) => state.incomeData);
  const answerValue =
    incomeData &&
    incomeData[code].cdiValue !== null &&
    incomeData[code].cdiValue;

  const { PREFIX, SUFFIX } = customProperties || {};

  useEffect(() => {
    if (
      typeof answerValue === "number" &&
      question.answerValue !== answerValue.toString()
    ) {
      if (parentCdiName) {
        setIncomeValue({
          code: code,
          cdiValue: answerValue,
        });

        updateBaseQuestionAnswerInCache({
          baseQuestionCode: code,
          answerValue: answerValue,
        });
      } else {
        answerBaseQuestion({
          baseQuestionCode: code,
          answer: answerValue.toString() || "",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerValue]);
  return (
    <>
      <ReadOnlyTextBox
        key={code}
        questionCode={code}
        answerValue={answerValue ? answerValue.toString() : "0"}
        redBorder={typeof answerValue === "number" && answerValue < 0}
        prefix={PREFIX}
        suffix={SUFFIX}
      />
    </>
  );
}

IncomeCellReadOnly.propTypes = {
  question: PropTypes.object.isRequired,
  parentCdiName: PropTypes.string,
};
