import PropTypes from "prop-types";
import { GelStandardDateInput } from "@tal-gel/components";
import { useState } from "react";
import { FormField } from "./FormField";
import { CaptionText } from "../CaptionText";
import { ERROR_MESSAGES } from "../../utils/CONSTANTS";

export function DateQuestion({
  question,
  minDate,
  maxDate,
  validationErrorMessage,
  validateControl,
  requiredErrorMessage = ERROR_MESSAGES.REACT_APP_ERR_MSG_REQ,
  withLabel = false,
  isDateRequired = true,
  nullable = true,
}) {
  const {
    code,
    answerValue,
    callbackFunctions,
    questionText,
    customProperties = {},
  } = question;
  const [dateValue, setDateValue] = useState(answerValue || "");
  const [compareError, setCompareError] = useState(false);
  const [requiredError, setRequiredError] = useState();
  const [invalidError, setInvalidError] = useState(false);

  const minimum =
    typeof minDate !== "undefined" && +minDate.replaceAll("-", "");
  const maximum =
    typeof maxDate !== "undefined" && +maxDate.replaceAll("-", "");

  function handleChange(e) {
    const { value } = e.target;
    setDateValue(value);

    const inputValue = +value.replaceAll("-", "");
    setCompareError(
      (minimum && inputValue < minimum) || (maximum && inputValue > maximum)
    );
  }
  function onBlur(e) {
    if (!nullable && (!dateValue || compareError)) {
      if (answerValue) {
        setDateValue(answerValue);
        setInvalidError(answerValue);
      }
      setRequiredError(
        !compareError && !dateValue && !answerValue && isDateRequired
      );

      setCompareError(compareError && !answerValue);
    } else {
      if (
        callbackFunctions &&
        Array.isArray(callbackFunctions) &&
        callbackFunctions.length
      ) {
        callbackFunctions.forEach((callback) => {
          if (typeof callback === "function") {
            callback(e);
          }
        });
      }
      setInvalidError(false);
      setRequiredError(false);
    }
  }

  const gelStandardDateInputElement = (
    <>
      <GelStandardDateInput
        id={code}
        name={code}
        value={dateValue}
        onBlur={onBlur}
        onChange={handleChange}
        error={requiredError || validateControl || compareError}
        caption={
          ((requiredError || validateControl) && requiredErrorMessage) ||
          (compareError && validationErrorMessage) ||
          ""
        }
        style={{ maxWidth: 180 }}
      />
      {invalidError && (
        <CaptionText
          textMessage={ERROR_MESSAGES.INVALID_INPUT}
          errorType
        ></CaptionText>
      )}
    </>
  );

  if (withLabel)
    return (
      <FormField
        questionText={questionText}
        customProperties={customProperties}
      >
        {gelStandardDateInputElement}
      </FormField>
    );

  return gelStandardDateInputElement;
}

DateQuestion.propTypes = {
  question: PropTypes.shape({
    code: PropTypes.string.isRequired,
    answerValue: PropTypes.string,
    callbackFunctions: PropTypes.arrayOf(PropTypes.func),
    questionText: PropTypes.string,
    customProperties: PropTypes.object,
  }).isRequired,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  validationErrorMessage: PropTypes.string,
  requiredErrorMessage: PropTypes.string,
  withLabel: PropTypes.bool,
  validateControl: PropTypes.bool,
  isDateRequired: PropTypes.bool,
  nullable: PropTypes.bool,
};

export default DateQuestion;
