import PropTypes from "prop-types";
import { GelBoxLayout } from "@tal-gel/components";
import { ColumnText } from "./LabelColumn";
function HeadingRow({ headings, spacingNode = null }) {
  return (
    <GelBoxLayout space={[...Array(headings.length).fill(1), "auto"]}>
      {headings.map(({ label, tooltipCode }, index) => (
        <ColumnText
          key={`${label}-${index}`}
          label={label}
          tooltipCode={tooltipCode}
          labelType
        />
      ))}
      {spacingNode}
    </GelBoxLayout>
  );
}

HeadingRow.propTypes = {
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      tooltipCode: PropTypes.string,
    })
  ),
  spacingNode: PropTypes.node,
};

export default HeadingRow;
