import { createSlice } from "@reduxjs/toolkit";
import { resetStore, setHandoverData } from "./shared-actions";
const initialState = "";

export const caseIdSlice = createSlice({
  name: "caseId",
  initialState,

  reducers: {
    setCaseId: (_, action) => {
      return action.payload.caseId;
    },
    resetCaseId: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(resetStore.fulfilled, () => {
      return initialState;
    });

    builder.addCase(setHandoverData.type, (_, action) => {
      return action.payload.caseId;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setCaseId, resetCaseId } = caseIdSlice.actions;

export default caseIdSlice.reducer;
