import { gql } from "@apollo/client";

export const GET_ATTRIBUTES_QUERY = gql`
  query AttributesQuery($getAttributesInput: GetAttributesInput!) {
    attributes(getAttributesInput: $getAttributesInput) {
      caseId
      attributes {
        attributeName
        attributeValue
      }
      attributeValues @client
    }
  }
`;
// type @client @export(as: "getAttributesInput.type")

export default GET_ATTRIBUTES_QUERY;
