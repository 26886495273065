import { useCallback } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_OCCUPATIONS_QUERY } from "../graphql/queries/getOccupations";

export function useQueryOccupations({ limit, searchText }) {
  const result = useQuery(GET_OCCUPATIONS_QUERY, {
    variables: {
      occupationsInput: {
        searchText,
        limit,
      },
    },
  });

  return result;
}

export function useQueryOccupationsLazy({ limit }) {
  const [executeQuery, result] = useLazyQuery(GET_OCCUPATIONS_QUERY);
  // console.log("occupation", { result });

  const queryOccupationsLazy = useCallback(
    ({ searchText }) => {
      if (searchText) {
        executeQuery({
          variables: {
            searchText,
            occupationsInput: {
              searchText,
              limit,
            },
          },
        });
      }
    },
    [executeQuery, limit]
  );

  return [queryOccupationsLazy, result];
}
