import { string } from "prop-types";
import { cache } from "../graphql/config/GraphQLAPIProvider";
import { BASE_QUESTION_FIELDS } from "../graphql/fragments/baseQuestionFields";
import { DISCLOSURE_FIELDS } from "../graphql/fragments/disclosureFields";
import { SECTION_FIELDS } from "../graphql/fragments/sectionFields";
import { PAGE_FIELDS } from "../graphql/fragments/pageFields";
import { LIST_FIELDS } from "../graphql/fragments/listFields";
import {
  CUSTOM_QUES_TYPE,
  CUSTOM_TABLE_TYPE,
  TI_HANDOVER_BQ_CODES,
  APPLICATION_STATUS,
  DISCLOSURE_SOURCES,
  PAGE_STATUSES,
  SECTION_STATUSES,
  CDI_NAMES,
} from "../utils/CONSTANTS";
import parse from "html-react-parser";

export function getBaseQuestionDataFromCache({ baseQuestionCode }) {
  const baseQuestion =
    cache.readFragment({
      id: cache.identify({
        code: baseQuestionCode,
        __typename: "BaseQuestionsType",
      }),
      fragment: BASE_QUESTION_FIELDS,
    }) || {};
  return baseQuestion;
}

export function updateBaseQuestionAnswerInCache({
  baseQuestionCode,
  answerValue,
}) {
  cache.modify({
    id: cache.identify({
      code: baseQuestionCode,
      __typename: "BaseQuestionsType",
    }),
    fields: {
      answerValue(currentValue) {
        return answerValue && answerValue.toString();
      },
      answerText(currentValue) {
        return answerValue && answerValue.toString();
      },
    },
    broadcast: false,
  });
}

export function getDisclosureByBaseQuestionFromCache({
  baseQuestionCode,
  aliasName,
}) {
  const disclosure =
    cache.readFragment({
      id: cache.identify({
        baseQuestionCode: baseQuestionCode,
        aliasName: aliasName,
        __typename: "DisclosureType",
      }),
      fragment: DISCLOSURE_FIELDS,
    }) || {};
  return disclosure;
}

export function evictDisclosure({ baseQuestionCode, aliasName }) {
  const id = cache.identify({
    baseQuestionCode,
    aliasName,
    __typename: "DisclosureType",
  });
  cache.evict({
    id,
    fieldName: ["reflexiveQuestions"],
  });
  cache.evict({
    id,
    fieldName: "triggeredCondAlias",
  });
  removeDisclosureFromBaseQuestion({ baseQuestionCode, aliasName });
  cache.gc();
}

export function removeDisclosureFromBaseQuestion({
  baseQuestionCode,
  aliasName,
}) {
  cache.modify({
    id: cache.identify({
      code: baseQuestionCode,
      __typename: "BaseQuestionsType",
    }),
    fields: {
      disclosures(currentValues) {
        const newDisclosures = currentValues.filter(
          ({ aliasName: attachedAliasName }) => attachedAliasName !== aliasName
        );
        return newDisclosures;
      },
    },
    broadcast: true,
  });
}

export function addDisclosureToBaseQuestion({ baseQuestionCode, aliasName }) {
  cache.modify({
    id: cache.identify({
      code: baseQuestionCode,
      __typename: "BaseQuestionsType",
    }),
    fields: {
      disclosures(currentValues) {
        // first check if the alias is not already added by the question.
        if (
          currentValues.findIndex(
            ({ aliasName: attachedAliasName }) =>
              aliasName === attachedAliasName
          ) === -1
        ) {
          return [
            ...currentValues,
            {
              aliasName,
              __typename: "disclosuresMetaType",
            },
          ];
        }
        return [...currentValues];
      },
    },
    broadcast: true,
  });
}

export function getSectionDataFromCache({ code }) {
  const section =
    cache.readFragment({
      id: cache.identify({
        code: code,
        __typename: "SectionsType",
      }),
      fragment: SECTION_FIELDS,
    }) || {};
  return section;
}

export function getListByName(listName) {
  const list = cache.readFragment({
    id: cache.identify({
      listName,
      __typename: "ListByNameType",
    }),
    fragment: LIST_FIELDS,
  });
  return list;
}

export function getSectionStatus({ sectionCode, sectionBaseQuestions }) {
  const { baseQuestions } = getSectionDataFromCache({
    code: sectionCode,
  });

  if (baseQuestions && baseQuestions.length > 0) {
    const visibleQuestions = baseQuestions.filter(
      (baseQuestion) => baseQuestion.visible
    );

    if (!verifyIfAnyBaseQuestionAnswered(baseQuestions)) {
      // check if section is completed when questions are pre-populated
      const prePopulatedQuestions = visibleQuestions.filter(
        ({ visible, answerText, answerValue, customProperties }) =>
          visible &&
          customProperties &&
          stringToBoolean(customProperties.IS_PREPOPULATED) &&
          answerText &&
          answerValue
      );
      if (
        prePopulatedQuestions &&
        prePopulatedQuestions.length !== visibleQuestions.length
      ) {
        return SECTION_STATUSES.Not_Started;
      }
    }

    for (let baseQuestion of visibleQuestions) {
      if (baseQuestion.questionType !== "HEADING") {
        if (
          !baseQuestion.answerText ||
          !baseQuestion.answerValue ||
          checkChildBQForInconsistency(baseQuestion.conditionalQuestionMeta) ||
          (baseQuestion.disclosureSource === DISCLOSURE_SOURCES.SEARCH &&
            baseQuestion.disclosures.length === 0) ||
          (baseQuestion.disclosureSource === DISCLOSURE_SOURCES.PICKLIST &&
            baseQuestion.disclosures.length === 0)
        ) {
          return SECTION_STATUSES.Incomplete;
        }
        if (baseQuestion.disclosures && baseQuestion.disclosures.length > 0) {
          const isDisclosureTreeComplete = isDisclosureQuestionAnswered({
            baseQuestion,
          });
          if (!isDisclosureTreeComplete) return SECTION_STATUSES.Incomplete;
        }

        if (
          baseQuestion.customProperties &&
          baseQuestion.customProperties.TABLE_TYPE ===
            CUSTOM_TABLE_TYPE.EMP_HISTORY
        ) {
          if (
            baseQuestion.answerText &&
            !stringToBoolean(baseQuestion.answerValue)
          ) {
            return SECTION_STATUSES.Incomplete;
          }
        }
      }

      if (baseQuestion.questionType === "HEADING") {
        if (
          baseQuestion.customProperties &&
          baseQuestion.customProperties.CUSTOM_TYPE ===
            CUSTOM_QUES_TYPE.PICKLIST_HEADING
        )
          if (
            baseQuestion.customProperties &&
            baseQuestion.customProperties.LINKEDQUESTION
          ) {
            for (let sectionBaseQuestion of sectionBaseQuestions) {
              if (
                sectionBaseQuestion &&
                sectionBaseQuestion.linkedQuestions &&
                sectionBaseQuestion.linkedQuestions.length > 0
              ) {
                const { childBaseQuestions } =
                  sectionBaseQuestion.linkedQuestions.find(
                    (linkedQuestion) =>
                      linkedQuestion.code === baseQuestion.code
                  );
                if (childBaseQuestions && childBaseQuestions.length > 0) {
                  const anyPickListItemSelected =
                    checkIfAnyPicklistQuestionAnswered({
                      questions: childBaseQuestions,
                    });
                  if (!anyPickListItemSelected) {
                    return SECTION_STATUSES.Incomplete;
                  }
                }
              }
            }
          } else {
            const { childBaseQuestions } = sectionBaseQuestions.find(
              (sectionBaseQuestion) =>
                sectionBaseQuestion.code === baseQuestion.code
            );
            if (childBaseQuestions && childBaseQuestions.length > 0) {
              const anyPickListItemSelected =
                checkIfAnyPicklistQuestionAnswered({
                  questions: childBaseQuestions,
                });
              if (!anyPickListItemSelected) {
                return SECTION_STATUSES.Incomplete;
              }
            }
          }
      }
    }
  } else {
    console.log(
      "Error Logging: Page load failed: BaseQuestions object is undefined in cache"
    );
  }
  return SECTION_STATUSES.Completed;
}

export function isDisclosureQuestionAnswered({ baseQuestion }) {
  let reflexiveQuestionsAnswered = true;
  for (let disclosure of baseQuestion.disclosures) {
    const { reflexiveQuestions } = getDisclosureByBaseQuestionFromCache({
      baseQuestionCode: baseQuestion.code,
      aliasName: disclosure.aliasName,
    });
    if (reflexiveQuestions && reflexiveQuestions.length > 0) {
      reflexiveQuestionsAnswered = checkIfQuestionsAnswered({
        questions: reflexiveQuestions,
      });
      if (!reflexiveQuestionsAnswered) {
        return reflexiveQuestionsAnswered;
      }
    }
  }
  return reflexiveQuestionsAnswered;
}

export function getDisclosureByBQCodeFromCache({
  baseQuestionCode,
  aliasName,
}) {
  const disclosure =
    cache.readFragment({
      id: cache.identify({
        baseQuestionCode,
        aliasName: aliasName,
        __typename: "DisclosureType",
      }),
      fragment: DISCLOSURE_FIELDS,
    }) || {};
  return disclosure;
}

function checkIfQuestionsAnswered({ questions }) {
  for (let question of questions) {
    if (!question.answerText || !question.answerValue) {
      return false;
    }
  }
  return true;
}

function checkIfAnyPicklistQuestionAnswered({ questions }) {
  for (let question of questions) {
    if (question.customProperties.CUSTOM_TYPE === CUSTOM_QUES_TYPE.PICKLIST) {
      if (
        stringToBoolean(question.answerValue) &&
        question.answerText &&
        question.visible
      ) {
        if (question.disclosures && question.disclosures.length > 0) {
          const isDisclosureTreeComplete = isDisclosureQuestionAnswered({
            baseQuestion: question,
          });
          if (!isDisclosureTreeComplete) return false;
        }
        if (
          question.picklistQuestionMeta &&
          question.picklistQuestionMeta.picklistItems &&
          question.picklistQuestionMeta.picklistItems.length > 0 &&
          (!question.disclosures ||
            (question.disclosures && question.disclosures.length === 0))
        ) {
          return false;
        }
        return true;
      }
    }
  }
  return false;
}

export function stringToBoolean(stringInput) {
  if (!stringInput) return false;

  switch (stringInput.toString().toLowerCase().trim()) {
    case "true":
    case "yes":
    case "1":
      return true;
    case "false":
    case "no":
    case "0":
      return false;
    default:
      return Boolean(string);
  }
}

export function showHLPage(applicationStatus) {
  const { answerValue: handOverToTI } =
    getBaseQuestionDataFromCache({
      baseQuestionCode: TI_HANDOVER_BQ_CODES.App_TacTI_IND0,
    }) ||
    getBaseQuestionDataFromCache({
      baseQuestionCode: TI_HANDOVER_BQ_CODES.App_TacTI_CCI_IND0,
    });

  return (
    handOverToTI &&
    (handOverToTI === "false" ||
      applicationStatus === APPLICATION_STATUS.TELE_INTERVIEW)
  ); //do not show health and life style page when handoverToTI is marked as null or true
}

export function checkChildBQForInconsistency(conditionalQuestionMeta) {
  let isAnswerInconsistent = true;
  if (
    conditionalQuestionMeta &&
    conditionalQuestionMeta.requiresLinkedDisclosure
  ) {
    for (let childQuestionCode of conditionalQuestionMeta.childQuestionCodes) {
      const { baseQuestionCode } = childQuestionCode;
      const { answerValue } = getBaseQuestionDataFromCache({
        baseQuestionCode,
      });
      if (
        (!answerValue || stringToBoolean(answerValue)) &&
        !baseQuestionCode.includes("_Heading_")
      ) {
        return (isAnswerInconsistent = false);
      }
      isAnswerInconsistent = true;
    }
  } else {
    isAnswerInconsistent = false;
  }
  return isAnswerInconsistent;
}

export function htmlParse(value) {
  return parse(value);
}

export async function fetchJson(url) {
  const response = await fetch(url);
  const json = await response.json();
  return json;
}

export function getPageDataFromCache(code) {
  const pages =
    cache.readFragment({
      id: cache.identify({
        code: code,
        __typename: "UrepagesType",
      }),
      fragment: PAGE_FIELDS,
    }) || {};
  return pages;
}

const verifyIfAnyBaseQuestionAnswered = (baseQuestions) => {
  for (let baseQuestion of baseQuestions) {
    const { visible, customProperties } = baseQuestion;
    const IS_PREPOPULATED = stringToBoolean(customProperties.IS_PREPOPULATED);
    if (
      visible &&
      customProperties.CUSTOM_TYPE !== CUSTOM_QUES_TYPE.READONLY &&
      customProperties.CUSTOM_TYPE !== CUSTOM_QUES_TYPE.TABLE &&
      !IS_PREPOPULATED
    ) {
      if (
        customProperties.CUSTOM_TYPE === CUSTOM_QUES_TYPE.HEIGHT ||
        customProperties.CUSTOM_TYPE === CUSTOM_QUES_TYPE.WEIGHT
      ) {
        if (+baseQuestion.answerText > 0 && +baseQuestion.answerValue > 0) {
          return true;
        }
      } else {
        if (baseQuestion.answerText && baseQuestion.answerValue) {
          return true;
        }
      }
    }
  }
  return false;
};

export function isAnyBaseQuestionAnswered(pageCode) {
  const { sections } = getPageDataFromCache(pageCode);
  for (let section of sections) {
    const { baseQuestions } = section;
    if (verifyIfAnyBaseQuestionAnswered(baseQuestions)) return true;
  }

  return false;
}

export function getPageStatus(pageCode) {
  if (isAnyBaseQuestionAnswered(pageCode)) {
    return PAGE_STATUSES.Incomplete;
  } else {
    return PAGE_STATUSES.Not_Started;
  }
}

export function filterPageByCode(pages, pageCode) {
  return (
    pages && pages.length > 0 && pages.find(({ code }) => code === pageCode)
  );
}

export function getPageCodeFromBaseQuestionCode(baseQuestionCode) {
  var baseQuestionIdSplit = baseQuestionCode.split("_");
  var pageCode = baseQuestionIdSplit[0] + "_" + baseQuestionIdSplit[1];
  return pageCode;
}

export function getCachedDisclosuresByPage(pageCode) {
  const { sections } = getPageDataFromCache(pageCode);
  let allDisclosures = [];

  sections.forEach((section) => {
    const { baseQuestions } = section;
    baseQuestions.forEach((baseQuestion) => {
      const { visible, disclosures, code } = baseQuestion;
      if (visible && disclosures && disclosures.length > 0) {
        const disclosureswithBaseQuestionCode = disclosures.map(
          (disclosure) => {
            return { ...disclosure, baseQuestionCode: code };
          }
        );
        allDisclosures.push(...disclosureswithBaseQuestionCode);
      }
    });
  });
  return allDisclosures;
}

export function updateReflexiveQAnswersInCache(
  answerDisclosure,
  baseQuestionCode
) {
  cache.modify({
    id: cache.identify({
      aliasName: answerDisclosure.aliasName,
      baseQuestionCode: baseQuestionCode,
      __typename: "DisclosureType",
    }),
    fields: {
      reflexiveQuestions() {
        const { reflexiveQuestions } = answerDisclosure;
        let newCurrentValues = [...reflexiveQuestions];
        return newCurrentValues;
      },
    },
    broadcast: true,
  });
}

// Income Table Data Common Functions

export function convertIncomeDataToCdiArray(incomeData, tableType) {
  let incomeTableCdiAttributes = [];
  Object.keys(incomeData).forEach((key) => {
    if (
      (incomeData[key].cdiName &&
        incomeData[key].cdiValue !== null &&
        incomeData[key].tableType === tableType) ||
      (tableType === CUSTOM_TABLE_TYPE.SELF_INCOME_ADDBACK_ITEMS &&
        incomeData[key].tableType === CUSTOM_TABLE_TYPE.SELF_INCOME_EXPENSE)
    )
      incomeTableCdiAttributes.push({
        attributeName: incomeData[key].cdiName,
        attributeValue: incomeData[key].cdiValue,
      });
  });
  return incomeTableCdiAttributes;
}

export function isAnyCdiValueNull(incomeData, TABLE_TYPE) {
  const checkNull = (element) =>
    element.cdiValue === null &&
    (TABLE_TYPE === element.tableType ||
      (TABLE_TYPE === CUSTOM_TABLE_TYPE.SELF_INCOME_ADDBACK_ITEMS &&
        element.tableType === CUSTOM_TABLE_TYPE.SELF_INCOME_EXPENSE));

  const isAnyCdiValueNull = Object.keys(incomeData)
    .map((key) => {
      return {
        cdiValue: incomeData[key].cdiValue,
        tableType: incomeData[key].tableType,
      };
    })
    .some(checkNull);
  return isAnyCdiValueNull;
}

export function getCdiNameForTable(TABLE_TYPE) {
  const { EMPLOYMENT_INCOME_PAGE } = CDI_NAMES;
  const incomeTableAnsweredCdis =
    TABLE_TYPE === CUSTOM_TABLE_TYPE.SELF_INCOME_ADDBACK_ITEMS
      ? [
          {
            attributeName: EMPLOYMENT_INCOME_PAGE.SEMP_INCOME_EXP_TABLE,
            attributeValue: true,
          },
          {
            attributeName: EMPLOYMENT_INCOME_PAGE.SEMP_ADDBACKS_TABLE,
            attributeValue: true,
          },
        ]
      : [
          {
            attributeName: EMPLOYMENT_INCOME_PAGE.EMPLOYED_INCOME_TABLE,
            attributeValue: true,
          },
        ];
  return incomeTableAnsweredCdis;
}

export function convertIntoArray(obj) {
  return Object.keys(obj).map((key) => {
    return obj[key];
  });
}

export function jsonParser(objStr) {
  return JSON.parse(objStr);
}

export function jsonStringify(obj) {
  return JSON.stringify(obj);
}

export function calculateIncomeIncludingSuper({
  currentAnnualIncomeExSuper,
  employerSuperContribution,
}) {
  return Math.trunc(currentAnnualIncomeExSuper + employerSuperContribution);
}
