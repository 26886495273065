import { GelTag } from "@tal-gel/components";
import propTypes from "prop-types";

export function Tag({ code, text, onDelete, onClick }) {
  return (
    <GelTag
      id={code}
      tagId={code}
      onDelete={onDelete}
      onClick={onClick}
      small
      secondary
      icon="ExitCircleFilled"
    >
      {text}
    </GelTag>
  );
}

Tag.propTypes = {
  code: propTypes.string,
  text: propTypes.string,
  onDelete: propTypes.func,
  onClick: propTypes.func,
};
