import propTypes from "prop-types";
import {
  GelNumberInput,
  convertEventTargetValueToNumeric,
} from "@tal-gel/components";
import { useState, useEffect } from "react";
import {
  NUMERIC_QUESTION_TYPE,
  PROPERTIES,
  ERROR_MESSAGES,
  SECTION_STATUSES,
} from "../../utils/CONSTANTS";
import { FormField } from "./FormField";
import { CaptionText } from "../CaptionText";
import { useSectionContext } from "../../contexts/SectionContext";

export function NumericQuestion({
  question,
  withLabel = false,
  nullable = true,
}) {
  const [compareError, setCompareError] = useState(false);
  const [invalidError, setInvalidError] = useState(false);
  const [isHandleChange, setisHandleChanger] = useState(false);
  const [keyValue, toggleKeyValue] = useState(false);
  const {
    code,
    answerValue,
    caseDataQuestionMeta,
    callbackFunctions,
    properties,
    questionText,
    customProperties = {},
  } = question;

  const [inputValue, setInputValue] = useState(answerValue || "");
  const [decimalPoints, setDecimalPoints] = useState(null);
  const [isInteger, setIsInteger] = useState(true);
  const {
    validationMessage,
    minimum = NUMERIC_QUESTION_TYPE.DEFAULT_MIN_VALUE,
    maximum = NUMERIC_QUESTION_TYPE.DEFAULT_MAX_VALUE,
  } = caseDataQuestionMeta || {};
  const caption = "";
  const customValidationMessage = `Minimum value should be ${minimum}. Maximum length for this field is
  ${maximum.toString().length}
  digits`;
  const sectionContext = useSectionContext();

  const {
    validationMessage: compareValidationMessage,
    minimum: minimumValue,
    maximum: maximumValue,
    PREFIX,
    SUFFIX,
  } = customProperties || {};

  useEffect(() => {
    if (properties) {
      const decimals = properties.filter(
        (prop) => prop.propertyName === PROPERTIES.DECIMAL_POINTS
      )[0];
      if (
        typeof decimals !== "undefined" &&
        decimals.hasOwnProperty(PROPERTIES.PROPERTY_NAME)
      ) {
        const { propertyValue } = decimals;
        setDecimalPoints(propertyValue);
        setIsInteger(false);
      }
    }
  }, [properties]);
  function handleChange(e) {
    const { value = "" } = (e && e.target) || {};
    setInputValue(value.trim());
    setCompareError(
      (typeof minimum !== "undefined" &&
        (+value < minimum || +value < minimumValue)) ||
        (typeof maximum !== "undefined" &&
          (+value > maximum || +value > maximumValue))
    );

    setisHandleChanger(true);
  }

  function setAllStates() {
    setInvalidError(true);
    setInputValue(answerValue);
    toggleKeyValue(!keyValue);
    setCompareError(false);
  }

  function onBlur(e) {
    const convertedValue = convertEventTargetValueToNumeric(inputValue);
    if (!nullable && answerValue && (!inputValue || compareError)) {
      setAllStates();
    } else {
      setInvalidError(false);
      if (
        typeof parseInt(convertedValue) === "number" &&
        !compareError &&
        isHandleChange
      ) {
        if (
          callbackFunctions &&
          Array.isArray(callbackFunctions) &&
          callbackFunctions.length
        ) {
          callbackFunctions.forEach((callback) => {
            if (typeof callback === "function") {
              callback(e);
            }
            setisHandleChanger(false);
          });
        }
      }
    }
  }

  const gelNumberInputElement = (
    <>
      <GelNumberInput
        width={200}
        id={code}
        name={code}
        key={code + keyValue}
        placeholder="Numeric input"
        integer={isInteger}
        value={inputValue}
        decimals={decimalPoints}
        preventNegative
        onBlur={onBlur}
        onChange={handleChange}
        required
        requiredErrorMsg={ERROR_MESSAGES.REACT_APP_ERR_MSG_REQ}
        error={
          compareError ||
          ((inputValue === 0 ? inputValue === false : !inputValue) &&
            sectionContext === SECTION_STATUSES.Incomplete)
        }
        caption={
          compareError &&
          (validationMessage ||
            compareValidationMessage ||
            customValidationMessage ||
            caption)
        }
        prefix={PREFIX}
        suffix={SUFFIX}
      />
      {invalidError && (
        <CaptionText
          textMessage={ERROR_MESSAGES.INVALID_INPUT}
          errorType
        ></CaptionText>
      )}
    </>
  );

  if (withLabel)
    return (
      <FormField
        questionText={questionText}
        customProperties={customProperties}
      >
        {gelNumberInputElement}
      </FormField>
    );

  return gelNumberInputElement;
}

NumericQuestion.propTypes = {
  question: propTypes.shape({
    code: propTypes.string.isRequired,
    answerValue: propTypes.string,
    questionText: propTypes.string,
    customProperties: propTypes.object,
    caseDataQuestionMeta: propTypes.shape({
      minimum: propTypes.number,
      maximum: propTypes.number,
      validationMessage: propTypes.string,
    }),
    properties: propTypes.arrayOf(
      propTypes.shape({
        propertyName: propTypes.string,
        propertyValue: propTypes.string,
      })
    ),
    callbackFunctions: propTypes.arrayOf(propTypes.func),
  }).isRequired,
  withLabel: propTypes.bool,
  nullable: propTypes.bool,
};
