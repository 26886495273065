import PropTypes from "prop-types";
import HintText from "../../HintText";
import { GelLabel, GelBoxLayout, GelParagraph } from "@tal-gel/components";

function LabelColumn({ labelRows }) {
  if (labelRows && labelRows.length) {
    return (
      <>
        {labelRows.map(({ label, tooltipCode }, index) => {
          return (
            <ColumnText
              key={`${label}-${index}`}
              label={label}
              tooltipCode={tooltipCode}
              labelType={index === 0}
            />
          );
        })}
      </>
    );
  }

  return null;
}

LabelColumn.propTypes = {
  labelRows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      tooltipCode: PropTypes.string,
    })
  ),
};

export default LabelColumn;

export function ColumnText({ label, tooltipCode, labelType }) {
  const TextLabel = labelType ? GelLabel : GelParagraph;

  if (tooltipCode) {
    return (
      <GelBoxLayout distribution="start" space="auto">
        <TextLabel>{label}</TextLabel>
        <HintText hintTextCode={tooltipCode} />
      </GelBoxLayout>
    );
  }
  return <TextLabel>{label}</TextLabel>;
}

ColumnText.propTypes = {
  label: PropTypes.string.isRequired,
  tooltipCode: PropTypes.string,
  labelType: PropTypes.bool,
};
