import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { resetCache } from "../graphql/config/GraphQLAPIProvider";

export const resetStore = createAsyncThunk("resetStore", async () => {
  try {
    const result = await resetCache();
    return result;
  } catch (e) {
    console.error({ e });
  }
});

export const setHandoverData = createAction("setHandoverData");
