import propTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  GelContainerLite,
  GelRowLayout,
  GelBoxLayout,
  GelForm,
  GelLabel,
  GelParagraph,
} from "@tal-gel/components";

import { useAnswerBaseQuestion } from "../utils/useAnswerBaseQuestion";
import { BaseQuestion } from "./BaseQuestion";
import { QuestionContainer } from "./QuestionContainer";
import { BooleanQuestion } from "./QuestionInput/BooleanQuestion";
import { ConfirmDialog } from "./ConfirmDialog";
import {
  APPLICATION_STATUS,
  ERROR_MESSAGES,
  URE_PAGE_CODES,
  REACT_APP_TI_HANDOVER_BASEQ_CODES,
  REACT_APP_TI_CONFIDENTIAL_BASEQ_CODES,
} from "../utils/CONSTANTS";
import { useCurrentUserRole } from "../globalStore/hooks";
import { WarningMessage } from "./WarningMessage";
import { useApplicationStatus } from "../globalStore/hooks";
import { useDispatch } from "react-redux";
import { changeNavigationButtonStatus } from "../globalStore/navigationButtonStatus-slice";
import { updatePageStatus } from "../globalStore/pages-slice";

export function TeleInterviewServicePage({ pageInfo }) {
  return (
    <GelForm labelAtTop className="section-content">
      <GelContainerLite gutter="small">
        <GelRowLayout gutter="small">
          <GelLabel>TELE-INTERVIEW SERVICE</GelLabel>
          <TeleInterviewStaticContent />
          <TeleInterviewQuestions
            baseQuestions={pageInfo.sections[0].baseQuestions}
          />
        </GelRowLayout>
      </GelContainerLite>
    </GelForm>
  );
}

function TeleInterviewStaticContent() {
  return (
    <GelBoxLayout alignment="start">
      <GelRowLayout gutter="small">
        <GelLabel>About Tele-interviewing</GelLabel>
        <GelParagraph>
          Tele-interviewing gives you the option to have TAL call your client to
          complete the health and lifestyle questions of their Accelerated
          Protection application. You can find out more by reading our adviser
          information about Tele-interviewing and Tele-underwriting. To help
          your client prepare for the Tele-interview, you can use Preparing for
          tele-interviewing and tele-underwriting. To use the Tele-interview
          service your client must be contactable on an Australian telephone
          number.
        </GelParagraph>
      </GelRowLayout>
      <GelRowLayout gutter="small">
        <GelLabel>Signature collection process</GelLabel>
        <GelParagraph>
          You now have choices as to how you provide the Policy Declaration &
          Authority signature
        </GelParagraph>
        <GelParagraph>
          1. <GelLabel>Tele-interview Voice Signature</GelLabel> offering. We
          can collect a voice signature during the Tele- interview. For
          eligibility refer to Tele-interviewing and Tele-underwriting.
        </GelParagraph>
        <GelParagraph>
          2. <GelLabel>Electronic Signature Authority</GelLabel> offering. On
          behalf of your client/s, you can select to agree to the Electronic
          Signature Authority Declarations and Authorities. On Submit, TAL will
          email the appropriate copy of the Application Summary to the Life
          Insured/s and the Policy Owner/s email addresses.
        </GelParagraph>
      </GelRowLayout>
      <GelRowLayout gutter="small">
        <GelLabel>Select Tele-interview and book</GelLabel>
        <GelParagraph>How to book:</GelParagraph>
        <GelParagraph>
          1. Select <GelLabel>‘Yes’</GelLabel> to the question below
        </GelParagraph>
        <GelParagraph>
          2. Complete the remainder of the online applications and{" "}
          <GelLabel>Submit</GelLabel>
        </GelParagraph>
        <GelParagraph>
          3. TAL will contact your client shortly to schedule the booking and
          send both you and your clients an email for the booking confirmation.
        </GelParagraph>
      </GelRowLayout>
    </GelBoxLayout>
  );
}

function TeleInterviewQuestions({ baseQuestions }) {
  const currentUserRole = useCurrentUserRole();
  const tiHandoverBaseQCodes = REACT_APP_TI_HANDOVER_BASEQ_CODES;
  const tiConfidentialBaseQCodes = REACT_APP_TI_CONFIDENTIAL_BASEQ_CODES;
  // filter out TI handover and confidential questions when they're visible
  const visibleBaseQuestions = baseQuestions
    .filter(
      ({ visible, code }) =>
        visible &&
        (tiHandoverBaseQCodes.includes(code) ||
          tiConfidentialBaseQCodes.includes(code))
    )
    .map((baseQuestion) => {
      const { code } = baseQuestion;
      if (tiHandoverBaseQCodes.includes(code)) {
        return <TIHandOverQuestion key={code} question={baseQuestion} />;
      }
      if (tiConfidentialBaseQCodes.includes(code)) {
        return <TIConfidentialQuestion key={code} question={baseQuestion} />;
      }
      return null;
    });

  if (visibleBaseQuestions.length && currentUserRole) {
    return visibleBaseQuestions;
  }

  return null;
}
TeleInterviewQuestions.propTypes = {
  baseQuestions: propTypes.arrayOf(
    propTypes.shape({
      code: propTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

function TIHandOverQuestion({ question }) {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const applicationStatus = useApplicationStatus();
  const dispatch = useDispatch();
  const { answerValue, code, customProperties } = question;
  const { answerBaseQuestion, error } = useAnswerBaseQuestion(
    answerValue,
    0,
    onErrorCallback
  );

  let uiCustomization = {};

  // useEffect(() => {
  // if (answerValue === null) {
  //   answerBaseQuestion({
  //     baseQuestionCode: code,
  //     answer: "false",
  //     answerBaseQuestionCallback: answerBaseQuestionCallback,
  //   });
  // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  uiCustomization = {
    ...uiCustomization,
    readOnly:
      customProperties.CUSTOM_TYPE === "READONLY" ||
      applicationStatus === APPLICATION_STATUS.TELE_INTERVIEW,
  };

  const callbackFunctions = [];

  function openConfirmDialog() {
    setShowConfirmDialog(true);
  }

  function onErrorCallback(error) {
    dispatch(
      changeNavigationButtonStatus({
        disableContinueButton: false,
        disableBackButton: false,
      })
    );
  }

  function answerBaseQuestionCallback(data) {
    dispatch(
      changeNavigationButtonStatus({
        disableContinueButton: false,
        disableBackButton: false,
      })
    );
  }

  function answerHandoverQuestion(e) {
    const { value } = (e && e.target) || {};

    dispatch(
      changeNavigationButtonStatus({
        disableContinueButton: true,
        disableBackButton: true,
      })
    );

    answerBaseQuestion({
      baseQuestionCode: code,
      answer: value || "true",
      refetchAllQuestions: value === "false",
      answerBaseQuestionCallback: answerBaseQuestionCallback,
    });

    if (value === "false") {
      dispatch(updatePageStatus({ code: URE_PAGE_CODES.App_HLstyle }));
    }
  }

  if (answerValue === "false") {
    callbackFunctions.push(openConfirmDialog);
  }

  if (answerValue === "true") {
    callbackFunctions.push(answerHandoverQuestion);
    // callbackFunctions.push(handleUserResponse);
  }

  const tiHandOverQ = { ...question, uiCustomization, callbackFunctions };

  return (
    <>
      <ConfirmDialog
        title="Warning"
        message={
          <GelParagraph>
            You have changed the answer to this question.{" "}
            <b>
              If you continue, this will update the status of this screen and
              DELETE any answers provided to the Health and Lifestyle Questions
              for all lives on this application.
            </b>{" "}
            Click Yes to continue or No to remain with initial selection and
            retain the answers supplied.
          </GelParagraph>
        }
        open={showConfirmDialog}
        yesAction={() => {
          setShowConfirmDialog(false);
          answerHandoverQuestion(); // answer handover question;
        }}
        noAction={() => setShowConfirmDialog(false)}
      />
      <QuestionContainer
        key={`${code}-${answerValue}-${showConfirmDialog}`} // make sure container rerenders when answer value change which in turn will rerender the child BooleanQuestion
        question={question}
      >
        <BooleanQuestion name={code} question={tiHandOverQ} withLabel />
      </QuestionContainer>
      {error && (
        <GelContainerLite gutter="none">
          <GelRowLayout gutter="small">
            <WarningMessage message={ERROR_MESSAGES.SERVER_FAILURE} errorType />
          </GelRowLayout>
        </GelContainerLite>
      )}
      {customProperties.CMS_INFO && (
        <GelContainerLite gutter="none">
          <GelRowLayout gutter="small">
            <WarningMessage messageCode={customProperties.CMS_INFO} infoType />
          </GelRowLayout>
        </GelContainerLite>
      )}
    </>
  );
}
TIHandOverQuestion.propTypes = {
  question: propTypes.shape({
    code: propTypes.string.isRequired,
    answerValue: propTypes.string,
    questionText: propTypes.string.isRequired,
    customProperties: propTypes.shape({
      CMS: propTypes.string,
      CUSTOM_CDI: propTypes.string,
      CMS_INFO: propTypes.string,
      CUSTOM_TYPE: propTypes.string,
    }),
    properties: propTypes.arrayOf(
      propTypes.shape({
        propertyName: propTypes.string.isRequired,
        propertyValue: propTypes.string.isRequired,
      })
    ),
  }),
};

function TIConfidentialQuestion({ question }) {
  const { answerBaseQuestion, error } = useAnswerBaseQuestion(null);
  const { answerValue, code, customProperties } = question;
  const applicationStatus = useApplicationStatus();

  useEffect(() => {
    if (
      question &&
      code &&
      !answerValue &&
      applicationStatus === APPLICATION_STATUS.TELE_INTERVIEW
    ) {
      answerBaseQuestion({
        baseQuestionCode: code,
        answer: customProperties.DEFAULT_VALUE || "false",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (applicationStatus === APPLICATION_STATUS.TELE_INTERVIEW) {
    return (
      <>
        <GelContainerLite gutter="none">
          <GelRowLayout gutter="small">
            <BaseQuestion
              key={`${code}-${answerValue}`}
              baseQuestion={question}
            />
            {error && (
              <WarningMessage
                message={ERROR_MESSAGES.SERVER_FAILURE}
                errorType
              />
            )}
          </GelRowLayout>
        </GelContainerLite>
      </>
    );
  }
  return null;
}
TIConfidentialQuestion.propTypes = {
  question: propTypes.shape({
    code: propTypes.string.isRequired,
    answerValue: propTypes.string,
    customProperties: propTypes.shape({
      DEFAULT_VALUE: propTypes.string,
    }),
  }),
};

TeleInterviewServicePage.propTypes = {
  pageInfo: propTypes.shape({
    text: propTypes.string.isRequired,
    sections: propTypes.array.isRequired,
  }),
};
