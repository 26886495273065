import { getGelTokens } from "@tal-gel/theming";

export function tabularClasses() {
  return {
    tabularDisclosure: {
      backgroundColor: "white",
      boxShadow:
        "0px 3px 5px " +
        getGelTokens().global.themeColorGrayT20 +
        " inset, 0px -3px 5px " +
        getGelTokens().global.themeColorGrayT20 +
        " inset",
    },
    default: {},
  };
}
