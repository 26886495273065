import { createSlice } from "@reduxjs/toolkit";
import { resetStore } from "./shared-actions";

const initialState = {};

export const configSlice = createSlice({
  name: "config",
  initialState,

  reducers: {
    resetConfig: () => {
      return initialState;
    },

    loadConfigData: (_, action) => {
      return action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(resetStore.fulfilled, () => {
      return initialState;
    });
  },
});

export const { loadConfigData } = configSlice.actions;

export default configSlice.reducer;
