import { GelLabel } from "@tal-gel/components";
import propTypes from "prop-types";
import parse from "html-react-parser";

function styleClasses() {
  return {
    normal: {
      fontWeight: "normal",
    },
    default: {},
  };
}

export function LabelText({
  labelText,
  labelId = null,
  labelTextNormal = true,
}) {
  const classes = styleClasses();
  const parseLabelText = parse(labelText);
  return (
    <GelLabel style={labelTextNormal ? classes.normal : {}} htmlFor={labelId}>
      {parseLabelText}
    </GelLabel>
  );
}

LabelText.propTypes = {
  labelText: propTypes.string,
  labelId: propTypes.string,
  labelTextNormal: propTypes.bool,
};
