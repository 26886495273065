import { createSlice } from "@reduxjs/toolkit";
import { resetStore } from "./shared-actions";

const initialState = {};

function calculateSelfEmployedIncome({
  businessIncome,
  businessExpense,
  shareOfBusiness,
}) {
  const businessIncomeCdiValue = businessIncome && +businessIncome.cdiValue;
  const businessExpenseCdiValue = businessExpense && +businessExpense.cdiValue;

  const netBusinessIncome = businessIncomeCdiValue - businessExpenseCdiValue;
  const shareOfNetBusinessIncome = parseInt(
    (netBusinessIncome * (shareOfBusiness || 0)) / 100
  );

  return { netBusinessIncome, shareOfNetBusinessIncome };
}

function calculateTotalIncome({
  shareOfNetBusinessIncome,
  salary,
  superannuation,
  otherBenefits,
}) {
  // const shareOfNetBusinessIncomeCdiValue =
  //   shareOfNetBusinessIncome && shareOfNetBusinessIncome.cdiValue;
  const salaryCdiValue = salary && +salary.cdiValue;
  const superannuationCdiValue = superannuation && +superannuation.cdiValue;
  const otherBenefitsCdiValue = otherBenefits && +otherBenefits.cdiValue;

  return parseInt(
    salaryCdiValue +
      superannuationCdiValue +
      otherBenefitsCdiValue +
      shareOfNetBusinessIncome
  );
}

function calculateTotal(values) {
  // return values.reduce((sum, value) => sum + value, 0);
  const total = values.reduce(function (prev, current) {
    return prev + +current.cdiValue;
  }, 0);

  return total;
}

export const incomeSlice = createSlice({
  name: "income",
  initialState,

  reducers: {
    setShareOfBusiness: (state, action) => {
      if (state.shareOfBusiness !== +action.payload) {
        state.shareOfBusiness = action.payload;
        {
          const { netBusinessIncome, shareOfNetBusinessIncome } =
            calculateSelfEmployedIncome({
              businessIncome: state.App_Inc_SEIT_C1R1_IND0,
              businessExpense: state.App_Inc_SEIT_C1R2_IND0,
              shareOfBusiness: state.shareOfBusiness,
            });
          // new value for lat year
          // state.App_Inc_SEIT_C1R3_IND0.cdiValue = netBusinessIncome;
          state.App_Inc_SEIT_C1R3_IND0 = {
            ...state.App_Inc_SEIT_C1R3_IND0,
            cdiValue: netBusinessIncome,
          };
          state.App_Inc_SEIT_C1R4_IND0.cdiValue = shareOfNetBusinessIncome;
        }
        // total income for last year
        const App_Inc_SEITAB_C1R4_IND0 = calculateTotalIncome({
          shareOfNetBusinessIncome:
            state.App_Inc_SEIT_C1R4_IND0 &&
            state.App_Inc_SEIT_C1R4_IND0.cdiValue,
          salary: state.App_Inc_SEITAB_C1R1_IND0,
          superannuation: state.App_Inc_SEITAB_C1R2_IND0,
          otherBenefits: state.App_Inc_SEITAB_C1R3_IND0,
        });
        state.App_Inc_SEITAB_C1R4_IND0.cdiValue = App_Inc_SEITAB_C1R4_IND0;

        {
          const { netBusinessIncome, shareOfNetBusinessIncome } =
            calculateSelfEmployedIncome({
              businessIncome: state.App_Inc_SEIT_C2R1_IND0,
              businessExpense: state.App_Inc_SEIT_C2R2_IND0,
              shareOfBusiness: state.shareOfBusiness,
            });
          // calculate new value for
          state.App_Inc_SEIT_C2R3_IND0.cdiValue = netBusinessIncome;
          state.App_Inc_SEIT_C2R4_IND0.cdiValue = shareOfNetBusinessIncome;
          // for prev year
          const App_Inc_SEITAB_C2R4_IND0 = calculateTotalIncome({
            shareOfNetBusinessIncome,
            salary: state.App_Inc_SEITAB_C2R1_IND0,
            superannuation: state.App_Inc_SEITAB_C2R2_IND0,
            otherBenefits: state.App_Inc_SEITAB_C2R3_IND0,
          });
          state.App_Inc_SEITAB_C2R4_IND0.cdiValue = App_Inc_SEITAB_C2R4_IND0;
        }
      }
    },

    resetIncome: () => {
      return initialState;
    },

    loadIncomeData: (state, action) => {
      const newState = action.payload.reduce(
        (tempState, { code, cdiValue, cdiName, tableType, unSaved }) => ({
          ...tempState,
          [code]: {
            cdiValue: cdiValue ? +cdiValue : null,
            cdiName: cdiName,
            unSaved: unSaved,
            tableType: tableType || null,
          },
        }),
        {}
      );
      newState.Is_Refetch_Required = false;
      return { ...state, ...newState };
    },
    setIncomeValue: (state, action) => {
      const { code, cdiValue } = action.payload;
      const numberValue = +cdiValue;

      if (typeof numberValue !== "number") {
        return state;
      }
      state[code].unSaved = state[code].cdiValue !== numberValue;
      state[code].cdiValue = numberValue;

      switch (code) {
        case "App_Inc_SEIT_C1R1_IND0": // business income last year
        case "App_Inc_SEIT_C1R2_IND0": // business expense last year
          {
            const { netBusinessIncome, shareOfNetBusinessIncome } =
              calculateSelfEmployedIncome({
                businessIncome: state.App_Inc_SEIT_C1R1_IND0,
                businessExpense: state.App_Inc_SEIT_C1R2_IND0,
                shareOfBusiness: state.shareOfBusiness,
              });
            // new value for lat year
            // state.App_Inc_SEIT_C1R3_IND0.cdiValue = netBusinessIncome;
            state.App_Inc_SEIT_C1R3_IND0 = {
              ...state.App_Inc_SEIT_C1R3_IND0,
              cdiValue: netBusinessIncome,
            };
            state.App_Inc_SEIT_C1R4_IND0.cdiValue = shareOfNetBusinessIncome;
            // total income for last year
            const totalIncomeLastYear = calculateTotalIncome({
              shareOfNetBusinessIncome,
              salary: state.App_Inc_SEITAB_C1R1_IND0,
              superannuation: state.App_Inc_SEITAB_C1R2_IND0,
              otherBenefits: state.App_Inc_SEITAB_C1R3_IND0,
            });
            state.App_Inc_SEITAB_C1R4_IND0.cdiValue = totalIncomeLastYear;
            state.Is_Refetch_Required = true;
          }
          break;
        case "App_Inc_SEITAB_C1R1_IND0": // salary last year
        case "App_Inc_SEITAB_C1R2_IND0": // superannuation last year
        case "App_Inc_SEITAB_C1R3_IND0": // other benefits last year
          const totalIncomeAbTable = calculateTotalIncome({
            shareOfNetBusinessIncome:
              state.App_Inc_SEIT_C1R4_IND0 &&
              state.App_Inc_SEIT_C1R4_IND0.cdiValue,
            salary: state.App_Inc_SEITAB_C1R1_IND0,
            superannuation: state.App_Inc_SEITAB_C1R2_IND0,
            otherBenefits: state.App_Inc_SEITAB_C1R3_IND0,
          });
          state.App_Inc_SEITAB_C1R4_IND0.cdiValue = totalIncomeAbTable;
          state.Is_Refetch_Required = true;
          break;
        case "App_Inc_SEIT_C2R1_IND0": // business income prev year
        case "App_Inc_SEIT_C2R2_IND0": // business expense prev year
          {
            const { netBusinessIncome, shareOfNetBusinessIncome } =
              calculateSelfEmployedIncome({
                businessIncome: state.App_Inc_SEIT_C2R1_IND0,
                businessExpense: state.App_Inc_SEIT_C2R2_IND0,
                shareOfBusiness: state.shareOfBusiness,
              });
            // calculate new value for
            state.App_Inc_SEIT_C2R3_IND0.cdiValue = netBusinessIncome;
            state.App_Inc_SEIT_C2R4_IND0.cdiValue = shareOfNetBusinessIncome;

            // for prev year
            const totalIncomePrevYear = calculateTotalIncome({
              shareOfNetBusinessIncome,
              salary: state.App_Inc_SEITAB_C2R1_IND0,
              superannuation: state.App_Inc_SEITAB_C2R2_IND0,
              otherBenefits: state.App_Inc_SEITAB_C2R3_IND0,
            });
            state.App_Inc_SEITAB_C2R4_IND0.cdiValue = totalIncomePrevYear;
          }
          break;

        case "App_Inc_SEITAB_C2R1_IND0": // salary prev year
        case "App_Inc_SEITAB_C2R2_IND0": // superannuation prev year
        case "App_Inc_SEITAB_C2R3_IND0": // other benefits prev year
          // for prev year
          const totalIncomePrevYearAbTable = calculateTotalIncome({
            shareOfNetBusinessIncome:
              state.App_Inc_SEIT_C2R4_IND0 &&
              state.App_Inc_SEIT_C2R4_IND0.cdiValue,
            salary: state.App_Inc_SEITAB_C2R1_IND0,
            superannuation: state.App_Inc_SEITAB_C2R2_IND0,
            otherBenefits: state.App_Inc_SEITAB_C2R3_IND0,
          });
          state.App_Inc_SEITAB_C2R4_IND0.cdiValue = totalIncomePrevYearAbTable;
          break;
        case "App_Inc_EIT_C1R1_IND0": // Salary/wage current tax year
        case "App_Inc_EIT_C1R2_IND0": // Bonus current tax year
        case "App_Inc_EIT_C1R3_IND0": // Other benefits excluding superannuation current tax year
          // for prev year
          const App_Inc_EIT_C1R4_IND0 = calculateTotal([
            state.App_Inc_EIT_C1R1_IND0,
            state.App_Inc_EIT_C1R2_IND0,
            state.App_Inc_EIT_C1R3_IND0,
          ]);
          state.App_Inc_EIT_C1R4_IND0.cdiValue = App_Inc_EIT_C1R4_IND0;
          break;
        case "App_Inc_EIT_C2R1_IND0": // Salary/wage last tax year
        case "App_Inc_EIT_C2R2_IND0": // Bonus last tax year
        case "App_Inc_EIT_C2R3_IND0": // Other benefits excluding superannuation last tax year
          // for prev year
          const App_Inc_EIT_C2R4_IND0 = calculateTotal([
            state.App_Inc_EIT_C2R1_IND0,
            state.App_Inc_EIT_C2R2_IND0,
            state.App_Inc_EIT_C2R3_IND0,
          ]);
          state.App_Inc_EIT_C2R4_IND0.cdiValue = App_Inc_EIT_C2R4_IND0;
          break;
        case "App_Inc_EIT_C3R1_IND0": // Salary/wage previous tax year
        case "App_Inc_EIT_C3R2_IND0": // Bonus previous tax year
        case "App_Inc_EIT_C3R3_IND0": // Other benefits excluding superannuation previous tax year
          // for prev year
          const App_Inc_EIT_C3R4_IND0 = calculateTotal([
            state.App_Inc_EIT_C3R1_IND0,
            state.App_Inc_EIT_C3R2_IND0,
            state.App_Inc_EIT_C3R3_IND0,
          ]);
          state.App_Inc_EIT_C3R4_IND0.cdiValue = App_Inc_EIT_C3R4_IND0;
          break;
        default:
          break;
      }
    },
    resetUnSavedFlag: (state, action) => {
      const incomeDataArr = action.payload;
      incomeDataArr.forEach((incomeData) => {
        if (state[incomeData.code].unSaved !== undefined)
          state[incomeData.code].unSaved = false;
      });
    },
  },

  extraReducers: (builder) => {
    builder.addCase(resetStore.fulfilled, () => {
      return initialState;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setIncomeValue,
  setShareOfBusiness,
  loadIncomeData,
  resetIncome,
  resetUnSavedFlag,
} = incomeSlice.actions;

export default incomeSlice.reducer;
