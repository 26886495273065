import { gql } from "@apollo/client";
import { CONFIGURABLES } from "../../utils/CONSTANTS";

export const GET_LIST_QUERY = gql`
  query QueryListByName($caseId: ID!, $listName: ID!) {
    lists(id: $caseId, listName: $listName, version: "${CONFIGURABLES.REACT_APP_FACADE_VERSION}") {
      list {
        listName
        listItems {
          code
          description
          label @client
          value @client
        }
      }
    }
  }
`;

export default GET_LIST_QUERY;
