import { createSlice } from "@reduxjs/toolkit";
import { resetStore } from "./shared-actions";
import { convertIntoArray, jsonParser } from "../utils/commonFunctions";
const initialState = {};

function updateTotalForTable(newState, tableHeading) {
  const newStateArr = convertIntoArray(newState);
  const BQCodeTotal = getBQCodeForTotal(newStateArr, tableHeading);
  const totalValue = newStateArr
    .filter(
      ({ tableType, isReadOnly }) => tableType === tableHeading && !isReadOnly
    )
    .reduce((prev, current) => prev + +current.cdiValue, 0);

  if (BQCodeTotal)
    return {
      ...newState,
      [BQCodeTotal]: { ...newState[BQCodeTotal], cdiValue: totalValue },
    };

  return newState;
}

function getBQCodeForTotal(arr, tableHeading) {
  const elem = arr.find(
    ({ tableType, isReadOnly }) => tableType === tableHeading && isReadOnly
  );

  return (elem && elem.code) || null;
}

function getStateValue(elem, newValue) {
  if (elem && elem.cdiValue !== null) {
    return elem.cdiValue;
  }
  return newValue !== null ? +newValue : null;
}

export const assetsAndLiabilitiesSlice = createSlice({
  name: "assetsAndLiabilities",
  initialState,

  reducers: {
    resetAnlTable: () => {
      return initialState;
    },

    loadAnlTableData: (state, action) => {
      const newState = action.payload.reduce(
        (tempState, elem) => ({
          ...tempState,
          [elem.code]: {
            ...elem,
            cdiValue: getStateValue(state[elem.code], elem.cdiValue),
            unSaved: state[elem.code]?.unSaved || elem.unSaved,
          },
        }),
        {}
      );
      return { ...state, ...newState };
    },

    setAnlTableValue: (state, action) => {
      const { name, value } = action.payload;
      const tableHeading = state[name] && state[name].tableType;

      const numberValue = +value;
      if (typeof numberValue !== "number") {
        return state;
      }
      const newState = {
        ...state,
        [name]: { ...state[name], cdiValue: numberValue, unSaved: true },
      };

      const updatedState = updateTotalForTable(newState, tableHeading);

      return updatedState;
    },

    deleteAnlTableValue: (state, action) => {
      const { name } = action.payload;
      const tableHeading = state[name] && state[name].tableType;
      let newState = { ...state };
      delete newState[name];

      const updatedState = updateTotalForTable(newState, tableHeading);
      return updatedState;
    },

    addNewAnLValue: (state, action) => {
      const newAnLData = action.payload;
      if (newAnLData) return { ...state, [newAnLData.code]: newAnLData };

      return state;
    },

    resetUnSavedFlag: (state, action) => {
      const anLDataArr = action.payload;
      anLDataArr.forEach((anLData) => {
        if (state[anLData.code] && state[anLData.code].unSaved !== undefined)
          state[anLData.code].unSaved = false;
      });
    },

    suffleAfterDelete: (state, action) => {
      const anLDataArr = action.payload;
      const metaData = anLDataArr[0];
      const tableHeading =
        state[metaData.attributeName] &&
        state[metaData.attributeName].tableType;

      let newState = { ...state };
      anLDataArr.forEach((elem) => {
        if (elem.attributeValue !== null) {
          const parsedJson = jsonParser(elem.attributeValue);
          newState[elem.attributeName] = {
            ...newState[elem.attributeName],
            cdiName: elem.attributeName,
            label: parsedJson.label,
            cdiValue:
              parsedJson.value !== null && parsedJson.value !== undefined
                ? +parsedJson.value
                : null,
            code: elem.attributeName,
            unSaved: elem.unSaved,
          };
        } else {
          if (newState[elem.attributeName]) {
            delete newState[elem.attributeName];
          }
        }
      });

      const updatedState = updateTotalForTable(newState, tableHeading);
      return updatedState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(resetStore.fulfilled, () => {
      return initialState;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetAnlTable,
  loadAnlTableData,
  setAnlTableValue,
  deleteAnlTableValue,
  addNewAnLValue,
  resetUnSavedFlag,
  suffleAfterDelete,
} = assetsAndLiabilitiesSlice.actions;

export default assetsAndLiabilitiesSlice.reducer;
