import { gql } from "@apollo/client";

export const GET_OCCUPATIONS_QUERY = gql`
  query OccupationsQuery($occupationsInput: getOccupationsInput!) {
    occupations(getOccupationsInput: $occupationsInput) {
      name
    }
  }
`;

export default GET_OCCUPATIONS_QUERY;
