import { useMutation } from "@apollo/client";
import { ANSWER_DISCLOSURE_MUTATION } from "../graphql/mutations/answerDisclosure";
import { cache } from "../graphql/config/GraphQLAPIProvider";
import GET_DISCLOSURE_QUERY from "../graphql/queries/getDisclosure";
import { useCaseId } from "../globalStore/hooks";
import {
  getBaseQuestionDataFromCache,
  updateReflexiveQAnswersInCache,
  getCachedDisclosuresByPage,
} from "../utils/commonFunctions";
import {
  DISCLOSURE_SOURCES,
  URE_PAGE_CODES,
  CONFIGURABLES,
} from "../utils/CONSTANTS";

export function useAnswerDisclosure(answerReflexiveQuestCallback) {
  const caseId = useCaseId();
  const [answerDisclosureMutation, { data, ...rest }] = useMutation(
    ANSWER_DISCLOSURE_MUTATION,
    {
      onCompleted(data) {
        if (answerReflexiveQuestCallback) answerReflexiveQuestCallback(data);
      },
    }
  );

  async function answerDisclosure({
    baseQuestionCode,
    aliasName,
    answers,
    isDisclosureUnknown,
  }) {
    try {
      const answerDisclosureInput = {
        caseId,
        baseQuestionCode,
        aliasName,
        answers: answers.map(({ answer }) => ({
          // delete the answer in AIS if user input is empty
          answer: answer.trim() === "" ? null : answer,
        })),
        version: CONFIGURABLES.REACT_APP_FACADE_VERSION,
      };
      const result = await answerDisclosureMutation({
        variables: {
          answerDisclosureInput,
        },
      });
      cache.writeQuery({
        query: GET_DISCLOSURE_QUERY,
        data: {
          disclosure: result.data.answerDisclosure,
        },
        variables: {
          disclosureQueryInput: answerDisclosureInput,
        },
      });

      const baseQuestion = getBaseQuestionDataFromCache({ baseQuestionCode });
      if (
        baseQuestion &&
        baseQuestion.disclosureSource === DISCLOSURE_SOURCES.SEARCH &&
        !isDisclosureUnknown
      ) {
        const allDisclosures = getCachedDisclosuresByPage(
          URE_PAGE_CODES.App_HLstyle
        );
        const answerDisclosure = result.data.answerDisclosure[0];
        allDisclosures.forEach((disclosure) => {
          if (
            disclosure.aliasName === answerDisclosure.aliasName &&
            disclosure.baseQuestionCode !== answerDisclosure.baseQuestionCode
          ) {
            updateReflexiveQAnswersInCache(
              answerDisclosure,
              disclosure.baseQuestionCode
            );
          }
        });
      }
      return {
        data: { answerDisclosureInput: result.data.answerDisclosure[0] },
      };
    } catch (err) {
      console.log(err);
    }
  }
  let result;
  if (data) {
    const [disclosure] = data.answerDisclosure;
    result = { disclosure };
  }
  return { answerDisclosure, data: result, ...rest };
}
