import PropTypes from "prop-types";
import { GelNumberInput } from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";

function NumericQuestionReadOnly({
  questionCode,
  answerValue,
  redBorder = false,
  prefix,
  suffix,
}) {
  const style = {
    backgroundColor: getGelTokens().global.themeColorBackgroundLight,
    maxWidth: 200,
    ...((redBorder && {
      borderColor: getGelTokens().global.themeColorDanger,
    }) ||
      {}),
  };
  return (
    <GelNumberInput
      style={style}
      id={questionCode}
      name={questionCode}
      value={answerValue}
      prefix={prefix}
      suffix={suffix}
      readOnly
    />
  );
}

NumericQuestionReadOnly.propTypes = {
  questionCode: PropTypes.string.isRequired,
  answerValue: PropTypes.string,
  redBorder: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
};

export default NumericQuestionReadOnly;
