import propTypes from "prop-types";
import TalStepperContainer from "./Stepper/TalStepper";
import { UREPage } from "./UREPage";
import { useQueryAllQuestions } from "../utils/useQueryAllQuestions";
import {
  getBaseQuestionDataFromCache,
  showHLPage,
} from "../utils/commonFunctions";
import { TI_HANDOVER_BQ_CODES, URE_PAGE_CODES } from "../utils/CONSTANTS";
import { TeleInterviewServicePage } from "./TeleInterviewServicePage";
import { useApplicationStatus } from "../globalStore/hooks";

export function UREPagesContainer({ sendBackToAdviserSite }) {
  const { data, error, loading } = useQueryAllQuestions();

  if (loading) return <p>Loading...</p>;
  if (error)
    return <p>{error.message || "some error in fetching questions"}</p>;
  if (
    data &&
    data.questions &&
    data.questions.pages &&
    data.questions.pages.length
  ) {
    return (
      <UREPages
        pages={data.questions.pages}
        sendBackToAdviserSite={sendBackToAdviserSite}
      />
    );
  }
  return <p>No questions</p>;
}

UREPagesContainer.propTypes = {
  sendBackToAdviserSite: propTypes.func,
};

function UREPages({ pages, sendBackToAdviserSite }) {
  const applicationStatus = useApplicationStatus();

  function getPages(pages) {
    let isShowHLPage = showHLPage(applicationStatus);
    console.log("showHLPage: " + isShowHLPage);
    return pages
      .filter(
        ({ code, visible }) =>
          (code === URE_PAGE_CODES.App_HLstyle && isShowHLPage && visible) ||
          (code !== URE_PAGE_CODES.App_HLstyle && visible)
      )
      .map(({ text, code }, index) => ({
        label: text,
        code,
      }));
  }

  function getActivePageContent({ activeStep }) {
    const pageData = pages.find(({ code }) => code === activeStep.code);
    if (pageData.code === URE_PAGE_CODES.App_TacTI) {
      return <TeleInterviewServicePage pageInfo={pageData} />;
    }
    return <UREPage pageInfo={pageData} />;
  }

  function handleHandOverData(action) {
    const { answerValue: handOverToTI } =
      getBaseQuestionDataFromCache({
        baseQuestionCode: TI_HANDOVER_BQ_CODES.App_TacTI_IND0,
      }) ||
      getBaseQuestionDataFromCache({
        baseQuestionCode: TI_HANDOVER_BQ_CODES.App_TacTI_CCI_IND0,
      });
    sendBackToAdviserSite({
      action: action,
      showHLPage: showHLPage(applicationStatus),
      handOverToTI: handOverToTI,
    });
  }

  function handleContinueOnLast() {
    handleHandOverData("NavigateToNextNode");
  }
  function handleBackOnFirst() {
    handleHandOverData("NavigateToPreviousNode");
  }

  return (
    <TalStepperContainer
      steps={getPages(pages)}
      getStepContent={getActivePageContent}
      handleBackOnFirst={handleBackOnFirst}
      handleContinueOnLast={handleContinueOnLast}
    />
  );
}
UREPages.propTypes = {
  pages: propTypes.arrayOf(propTypes.object.isRequired).isRequired,
  sendBackToAdviserSite: propTypes.func,
};
