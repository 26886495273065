import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useBmiData } from "../../globalStore/hooks";
import { setHeight } from "../../globalStore/bmiData-slice";

import {
  GelNumberInput,
  GelContainerLite,
  GelRowLayout,
  GelBoxLayout,
  GelSelect,
  GelAutoCompleteInput,
} from "@tal-gel/components";
import { useAnswerBaseQuestion } from "../../utils/useAnswerBaseQuestion";
import { WarningMessage } from "../WarningMessage";
import { ERROR_MESSAGES, SECTION_STATUSES } from "../../utils/CONSTANTS";
import { FormField } from "../QuestionInput/FormField";
import { useSectionContext } from "../../contexts/SectionContext";

const ftOptions = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
];
const unit = {
  cm: "cm",
  ft: "ft",
  in: "in",
};

export default function Height({ question, measureUnit }) {
  const { answerValue, code, caseDataQuestionMeta, questionText } = question;
  const { answerBaseQuestion, error } = useAnswerBaseQuestion(answerValue, 700);
  const {
    validationMessage,
    minimum = 0,
    maximum,
  } = caseDataQuestionMeta || {};
  const [heightCm, setHeightCm] = useState(
    parseInt(answerValue) > 0 ? answerValue : ""
  );
  const [heigthFt, setHeigthFt] = useState("");
  const [heightIn, setHeightIn] = useState("");
  const [convertedImperialValue, setConvertedImperialValue] = useState("");
  const [compareError, setCompareError] = useState(false);
  const [requiredError, setRequiredError] = useState(false);
  const [invalidError, setInvalidError] = useState(false);
  const bmiData = useBmiData();
  const dispatch = useDispatch();
  const sectionContext = useSectionContext();
  const [autoCompleteData, setAutoCompleteData] = useState();

  useEffect(() => {
    let arrFtIn = [];
    for (var i = 1; i < 9; i++) {
      for (var j = 0; j < 12; j++) {
        let ftInLabel = i + " ft " + j + " in";
        let ftInValue = i + "." + j;
        arrFtIn.push({ label: ftInLabel, value: ftInValue });
      }
    }
    setAutoCompleteData(arrFtIn);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("sectionContextsectionContext::: ,", sectionContext);
    const hasRequiredError =
      (typeof bmiData !== "undefined" && bmiData.height && !heightCm) ||
      (!heightCm && sectionContext === SECTION_STATUSES.Incomplete);
    const hasComparisonError =
      typeof bmiData !== "undefined" &&
      (typeof bmiData.height !== "undefined" || heightCm.trim() !== "") &&
      ((typeof minimum !== "undefined" && +heightCm < minimum) ||
        (typeof maximum !== "undefined" && +heightCm > maximum));
    if (
      !hasComparisonError &&
      !hasRequiredError &&
      parseInt(heightCm) > 0 &&
      ((bmiData && bmiData.height !== heightCm) || !bmiData)
    ) {
      answerBaseQuestion({ baseQuestionCode: code, answer: heightCm });
      dispatch(
        setHeight({
          height: heightCm,
        })
      );
    }
    if (hasRequiredError !== requiredError) {
      setRequiredError(hasRequiredError);
    }
    if (hasComparisonError !== compareError) {
      setCompareError(hasComparisonError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, heightCm, maximum, minimum, sectionContext, measureUnit]);

  useEffect(() => {
    if (answerValue && parseInt(answerValue) > 0) {
      dispatch(setHeight({ height: answerValue }));
      const { ft, inch } = convertToImperial({ cms: answerValue });
      setHeigthFt(ft);
      setHeightIn(inch);
      convertIntoImperialFormat(ft, inch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (measureUnit) setInvalidError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measureUnit]);

  function convertIntoImperialFormat(ft, inch) {
    const imperialValue = ft + " ft " + inch + " in";
    setConvertedImperialValue(imperialValue);
  }

  function convertToImperial({ cms }) {
    let ft = "";
    let inch = "";
    if (cms && !isNaN(cms)) {
      const allInches = Math.round(parseInt(cms) / 2.54);
      ft = parseInt(allInches / 12).toString();
      inch = (allInches % 12).toString();
    }
    return {
      ft,
      inch,
    };
  }

  function convertToMetric({ ft, inch }) {
    let cms = "";
    if (ft && !isNaN(ft) && inch && !isNaN(inch)) {
      const totalInches = parseInt(ft) * 12 + parseInt(inch);
      cms = Math.round(totalInches * 2.54).toString();
    }
    return {
      cms,
    };
  }

  function splitValues(value) {
    return String(value).split(".");
  }

  function trimSpaces(value) {
    return value.replace(/\s/g, "");
  }

  function findSearchedValueInData(value) {
    const trimmedValue = trimSpaces(value);
    return autoCompleteData.find((x) => trimSpaces(x.label) === trimmedValue);
  }

  function heightChangeEvent(value) {
    const { ft, inch } = convertToImperial({ cms: value });
    setHeigthFt(ft);
    setHeightIn(inch);
    setHeightCm(value);
    convertIntoImperialFormat(ft, inch);
  }

  function handleHeightCMChange(e) {
    const { value } = e.target;
    heightChangeEvent(value);
  }

  function handleHeightImperialValueChange({ ft, inch }) {
    const { cms } = convertToMetric({ ft, inch });
    setHeightCm(cms);
  }

  function handleHeightFtInChange(heightImperial) {
    const { value } = heightImperial;
    const splitedValues = splitValues(value);

    setHeigthFt(splitedValues[0]);
    setHeightIn(splitedValues[1]);
    convertIntoImperialFormat(splitedValues[0], splitedValues[1]);
    handleHeightImperialValueChange({
      ft: splitedValues[0],
      inch: splitedValues[1],
    });
  }

  function handleBlur(e) {
    const { value } = e.target;
    if ((!heightCm || compareError) && answerValue) {
      heightChangeEvent(answerValue);
    }
    setRequiredError(!heightCm && !answerValue ? true : false);
    setInvalidError((!heightCm || compareError) && answerValue ? true : false);

    if (measureUnit !== "metric") {
      const valueFromAutoCompleteData = findSearchedValueInData(value);
      if (valueFromAutoCompleteData) {
        handleHeightFtInChange(valueFromAutoCompleteData);
      } else if (!valueFromAutoCompleteData && answerValue) {
        setInvalidError(true);
        setCompareError(false);
        setRequiredError(false);
        heightChangeEvent(answerValue);
      }
    }
  }

  return (
    <FormField questionText={questionText}>
      <GelContainerLite gutter="none">
        <GelRowLayout gutter="small">
          <GelBoxLayout space="auto" distribution="start">
            {measureUnit === "metric" ? (
              <HeightCM
                name={code}
                value={heightCm}
                onChange={handleHeightCMChange}
                onBlur={handleBlur}
                hasError={compareError || requiredError}
                suffix={unit.cm}
              />
            ) : (
              <HeightAutoComplete
                name={code}
                value={convertedImperialValue}
                options={ftOptions}
                onChange={handleHeightFtInChange}
                onBlur={handleBlur}
                hasError={compareError || requiredError}
                unit={unit.ft}
                autoCompleteData={autoCompleteData}
              />
            )}
          </GelBoxLayout>
          <GelBoxLayout space="auto" distribution="start" width={250}>
            {(compareError || requiredError || error) && (
              <WarningMessage
                message={
                  (requiredError && ERROR_MESSAGES.REACT_APP_ERR_MSG_REQ) ||
                  (compareError &&
                    (measureUnit === "metric"
                      ? validationMessage
                      : ERROR_MESSAGES.HEIGHT_RANGE_ERROR)) ||
                  (error && ERROR_MESSAGES.SERVER_FAILURE)
                }
                errorType
              />
            )}
            {invalidError && (
              <WarningMessage
                message={invalidError && ERROR_MESSAGES.INVALID_INPUT}
                errorType
              />
            )}
          </GelBoxLayout>
        </GelRowLayout>
      </GelContainerLite>
    </FormField>
  );
}

Height.propTypes = {
  question: PropTypes.object,
  measureUnit: PropTypes.string,
};

function HeightCM({
  name,
  value,
  onChange,
  onBlur,
  hasError = false,
  errorMessage = "",
  suffix,
}) {
  return (
    <GelNumberInput
      id={`${name}-cm`}
      name={`${name}-cm`}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      width={250}
      error={hasError}
      caption={hasError && errorMessage}
      decimals={2}
      suffix={suffix}
    />
  );
}

HeightCM.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  suffix: PropTypes.string,
};

function HeightSelect({
  name,
  options,
  value,
  onChange,
  onBlur,
  hasError,
  unit,
  errorMessage,
  autoCompleteData,
}) {
  useEffect(() => {
    console.log("autoCompleteData:::", autoCompleteData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GelSelect
        id={`${name}-${unit}`}
        name={`${name}-${unit}`}
        options={options}
        value={value}
        placeholder=" "
        error={hasError}
        onChange={onChange}
        onBlur={onBlur}
        caption={hasError && errorMessage}
        suffix={unit}
        width={250}
      />
    </>
  );
}
HeightSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  unit: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};

function HeightAutoComplete({
  name,
  options,
  value,
  onChange,
  onBlur,
  hasError,
  unit,
  errorMessage,
  autoCompleteData,
}) {
  const [searchTextInput, setSearchTextInput] = useState(value);
  const [autoCompleteDataRefined, setAutoCompleteDataRefined] = useState();

  useEffect(() => {
    if (value) setSearchTextInput(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onOptionClick = (option) => {
    console.log("Option clicked", option);
    onChange(option);
  };

  const onSearch = (value) => {
    if (value) {
      const searched = autoCompleteData.filter((x) =>
        x.label.startsWith(value)
      );
      setAutoCompleteDataRefined(searched);
    } else {
      setAutoCompleteDataRefined(null);
    }
  };

  const onClear = () => {
    console.log("clear");
  };

  function handleChange(e) {
    setSearchTextInput(e.target.value);
  }

  return (
    <>
      <GelAutoCompleteInput
        id={`${name}-${unit}`}
        name={`${name}-${unit}`}
        value={searchTextInput}
        data={autoCompleteDataRefined}
        onChange={handleChange}
        onOptionClick={onOptionClick}
        onSearch={onSearch}
        onBlur={onBlur}
        onClear={onClear}
        error={hasError}
        caption={hasError && errorMessage}
        width={250}
        displayAllOptionsOnFocus={false}
        maxHeight={300}
      />
    </>
  );
}
HeightSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  unit: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};
