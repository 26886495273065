import { useLazyQuery, useQuery } from "@apollo/client";
import { useCaseId } from "../globalStore/hooks";
import { GET_PAGE_STATUS_QUERY } from "../graphql/queries/getPageStatus";
import { CONFIGURABLES } from "../utils/CONSTANTS";

export function useQueryPageStatus() {
  const caseId = useCaseId();
  const result = useQuery(GET_PAGE_STATUS_QUERY, {
    variables: {
      pageStatusInput: {
        caseId,
        version: CONFIGURABLES.REACT_APP_FACADE_VERSION,
      },
    },
    fetchPolicy: "no-cache",
  });
  return result;
}

export function useQueryLazyPageStatus() {
  const caseId = useCaseId();
  const [pageStatus, result] = useLazyQuery(GET_PAGE_STATUS_QUERY);
  function pageStatusQuery() {
    pageStatus({
      variables: {
        pageStatusInput: {
          caseId,
          version: CONFIGURABLES.REACT_APP_FACADE_VERSION,
        },
      },

      fetchPolicy: "no-cache",
    });
  }
  return { pageStatusQuery, ...result };
}
