import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  GelContainerLite,
  GelRowLayout,
  GelBoxLayout,
  GelButton,
} from "@tal-gel/components";
import HeadingRow from "../CustomTablesShared/Heading";
import SelfIncomeRow from "./IncomeRow";
import { useDispatch, useSelector } from "react-redux";
import { loadIncomeData } from "../../../globalStore/income-slice";
import { useComponentBlur } from "../../../utils/useComponentBlur";
import { CUSTOM_TABLE_TYPE, INFO_MESSAGES } from "../../../utils/CONSTANTS";
import { useUpdateCaseAttributes } from "../../../utils/useUpdateCaseAttributes";
import {
  convertIncomeDataToCdiArray,
  getBaseQuestionDataFromCache,
  isAnyCdiValueNull,
  getCdiNameForTable,
} from "../../../utils/commonFunctions";
import { resetUnSavedFlag } from "../../../globalStore/income-slice";
import { WarningMessage } from "../../WarningMessage";
import { useQueryAllQuestions } from "../../../utils/useQueryAllQuestions";
import { useAnswerBaseQuestion } from "../../../utils/useAnswerBaseQuestion";
import { EMPLOYMENT_INCOME_PAGE_BQ_IDS } from "../../../utils/CONSTANTS";
import { getGelTokens } from "@tal-gel/theming";
import { styled } from "@mui/material/styles";

const PREFIX = "SelfIncomeTable";

const classes = {
  mainRowStyle: `${PREFIX}-mainRowStyle`,
  headerRowStyle: `${PREFIX}-headerRowStyle`,
};

const Root = styled("div")(() => ({
  [`& .${classes.mainRowStyle}`]: {
    paddingTop: "10px",
    paddingBottom: "10px",
    border: "1px solid " + getGelTokens().global.themeColorGrayT20,
  },
  [`& .${classes.headerRowStyle}`]: {
    lineHeight: "3.5",
    border: "1px solid " + getGelTokens().global.themeColorGrayT20,
  },
}));

function SelfIncomeTable({ question }) {
  const dispatch = useDispatch();
  const { customProperties, childBaseQuestions, code } = question;
  const { answerBaseQuestion } = useAnswerBaseQuestion(question.answerValue);
  const { COLUMNS, ROWS, TABLE_TYPE, CDI_NAME } = customProperties;
  const columnCount = +COLUMNS;
  const rowCount = +ROWS;
  const [headingRow, setHeadingRow] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const { ref, isComponentBlur } = useComponentBlur(false);
  const incomeData = useSelector((state) => state.incomeData);
  const isRefetchRequired =
    incomeData[EMPLOYMENT_INCOME_PAGE_BQ_IDS.Is_Refetch_Required];

  const { updateCaseAttributes, error } = useUpdateCaseAttributes(
    updateCaseAttributesCallback
  );

  useEffect(() => {
    if (customProperties) {
      const headingRowTemp = [
        {
          label: customProperties.DESC_COLUMN_HEADING || "",
        },
      ];

      for (let i = 1; i <= columnCount; i++) {
        if (customProperties[`COLUMN_HEADING_${i}`]) {
          headingRowTemp.push({
            label: customProperties[`COLUMN_HEADING_${i}`],
          });
        }
      }

      if (headingRowTemp.length === columnCount + 1) {
        setHeadingRow(headingRowTemp);
      }

      const tableRowsTemp = [];
      let rowIndex = 1;

      for (let i = 0; i < childBaseQuestions.length; i += columnCount) {
        const start = i;
        const end = i + columnCount;
        tableRowsTemp.push({
          start,
          end,
          rowLabel: {
            label: customProperties[`ROW_HEADING_${rowIndex}`],
            tooltipCode: customProperties[`ROW_HEADING_CMS_${rowIndex}`],
          },
        });
        rowIndex++;
      }
      if (tableRowsTemp.length === rowCount) {
        setTableRows(tableRowsTemp);
      }

      dispatch(
        loadIncomeData(
          childBaseQuestions.map(({ code, answerValue, customProperties }) => ({
            code,
            cdiValue:
              incomeData[code] && incomeData[code].cdiValue !== null
                ? incomeData[code].cdiValue.toString()
                : answerValue,
            cdiName: customProperties && customProperties.CDI_NAME,
            unSaved: (incomeData[code] && incomeData[code].unSaved) || false,
            tableType: TABLE_TYPE,
          }))
        )
      );
      !CDI_NAME &&
        answerBaseQuestion({
          baseQuestionCode: question.code,
          answer: "true",
        });
    }
  }, []);

  useEffect(() => {
    return () => {
      const { visible } = getBaseQuestionDataFromCache({
        baseQuestionCode: code,
      });

      !visible &&
        dispatch(
          loadIncomeData(
            childBaseQuestions.map(({ code, customProperties }) => ({
              code,
              cdiValue: null,
              cdiName: customProperties && customProperties.CDI_NAME,
              unSaved: false,
              tableType: TABLE_TYPE,
            }))
          )
        );
    };
  }, []);

  function updateCaseAttributesCallback() {
    const incomeDataArr = Object.keys(incomeData).map((key) => {
      return { ...incomeData[key], code: key };
    });
    if (!error) dispatch(resetUnSavedFlag(incomeDataArr));
  }

  function handleSaveIncomeTables() {
    if (incomeData) {
      let incomeTableCdiAttributes = convertIncomeDataToCdiArray(
        incomeData,
        TABLE_TYPE
      );
      if (incomeTableCdiAttributes.length > 0) {
        if (!isAnyCdiValueNull(incomeData, TABLE_TYPE)) {
          const incomeTableAnsweredCdis = getCdiNameForTable(TABLE_TYPE);
          incomeTableCdiAttributes = [
            ...incomeTableCdiAttributes,
            ...incomeTableAnsweredCdis,
          ];
        }
        updateCaseAttributes({
          updateAttributes: incomeTableCdiAttributes,
          refetchAllQuestions: true,
        });
      }
    }
  }

  if (headingRow.length && tableRows.length) {
    return (
      <Root ref={ref}>
        <GelContainerLite
          style={{
            width: "83%",
          }}
        >
          <GelRowLayout
            className={classes.headerRowStyle}
            style={{
              backgroundColor: getGelTokens().global.themeColorGrayT05,
            }}
          >
            <HeadingRow headings={headingRow} />
          </GelRowLayout>
          {tableRows.map((row, index) => (
            <GelRowLayout
              key={`${index}`}
              className={classes.mainRowStyle}
              style={{
                backgroundColor: getGelTokens().global.themeColorWhite,
              }}
            >
              <SelfIncomeRow
                key={`${index}`}
                childBaseQuestions={childBaseQuestions.slice(
                  row.start,
                  row.end
                )}
                labelData={row.rowLabel}
                parentQuestion={question}
              />
            </GelRowLayout>
          ))}

          {CDI_NAME &&
            TABLE_TYPE &&
            (TABLE_TYPE === CUSTOM_TABLE_TYPE.SELF_INCOME_ADDBACK_ITEMS ||
              TABLE_TYPE === CUSTOM_TABLE_TYPE.EMP_INCOME) && (
              <GelRowLayout
                className={classes.mainRowStyle}
                style={{
                  padding: "20px",
                }}
              >
                <GelBoxLayout gap="large" space="auto" distribution="end">
                  <WarningMessage
                    infoType
                    message={
                      TABLE_TYPE === CUSTOM_TABLE_TYPE.EMP_INCOME
                        ? INFO_MESSAGES.SAVE_INCOME_DETAILS
                        : INFO_MESSAGES.SAVE_INCOME_AB_DETAILS
                    }
                  ></WarningMessage>
                  <GelButton
                    id="saveIncomeTables"
                    name="saveIncomeTables"
                    secondary
                    medium
                    onClick={handleSaveIncomeTables}
                  >
                    Save
                  </GelButton>
                </GelBoxLayout>
              </GelRowLayout>
            )}
        </GelContainerLite>
        {isComponentBlur && isRefetchRequired && !CDI_NAME && (
          <RefetchAllBaseQuestions></RefetchAllBaseQuestions>
        )}
      </Root>
    );
  }

  return null;
}

SelfIncomeTable.propTypes = {
  question: PropTypes.object.isRequired,
};

export default SelfIncomeTable;

function RefetchAllBaseQuestions() {
  const { refetch, variables } = useQueryAllQuestions();
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof refetch === "function") {
      refetch(variables);
    }
  }, [refetch, dispatch, variables]);

  return null;
}
