import { createSlice } from "@reduxjs/toolkit";
import { resetStore } from "./shared-actions";

const initialState = {
  height: undefined,
  weight: undefined,
};

export const bmiDataSlice = createSlice({
  name: "bmiData",
  initialState,

  reducers: {
    setHeight: (state, action) => {
      return { ...state, height: action.payload.height };
    },
    setWeight: (state, action) => {
      return { ...state, weight: action.payload.weight };
    },
    resetBmiData: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(resetStore.fulfilled, () => {
      return initialState;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setHeight, setWeight, resetBmiData } = bmiDataSlice.actions;

export default bmiDataSlice.reducer;
