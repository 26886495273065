import propTypes from "prop-types";
import { Disclosure } from "./Disclosure";
import { useCreateDisclosure } from "../../utils/useCreateDisclosure";

import { STANDARD_QUE_ANS_TYPE } from "../../utils/CONSTANTS";
import { useState, useEffect } from "react";
export function DiclosureSourceSpecified({ question }) {
  const [implicitDisclosure, setImplicitDisclosure] = useState(null);
  const {
    disclosures,
    code,
    linkedAlias,
    customProperties,
    answerType,
    answerValue,
  } = question;
  const { createDisclosure } = useCreateDisclosure();

  function CreateImplicitDisclosure() {
    if (
      !implicitDisclosure &&
      disclosures &&
      !disclosures.length &&
      linkedAlias &&
      customProperties.DO_NOT_DISPLAY === "true" &&
      answerType === STANDARD_QUE_ANS_TYPE.ENTER_DETAILS &&
      answerValue === "true"
    ) {
      createDisclosure({
        baseQuestionCode: code,
        aliasName: linkedAlias,
      }).then(({ data: { createDisclosure } }) =>
        setImplicitDisclosure(createDisclosure)
      );
    }
  }
  useEffect(() => {
    CreateImplicitDisclosure();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Implicit implicitDisclosure must be added only first time when component is mounted.

  if ((disclosures && Array.isArray(disclosures)) || implicitDisclosure)
    return disclosures.map(({ aliasName }) => (
      <Disclosure
        key={`${code}-${aliasName}`}
        baseQuestionCode={code}
        aliasName={aliasName}
        disclosure={implicitDisclosure}
      />
    ));
}

DiclosureSourceSpecified.propTypes = {
  question: propTypes.shape({
    code: propTypes.string.isRequired,
    answerType: propTypes.string,
  }),
};
