import propTypes from "prop-types";
// import { styled } from "@mui/material/styles";
import { GelContainerLite, GelRowLayout, GelForm } from "@tal-gel/components";
import { useApplicationStatus } from "../globalStore/hooks";
import {
  TI_APP_READONLY_PAGES,
  APPLICATION_STATUS,
  CUSTOM_QUES_TYPE,
} from "../utils/CONSTANTS";
import { Section } from "./Section";
import { useEffect, useRef } from "react";
import { useIsInViewport } from "../utils/useIsInViewport";
import { styled } from "@mui/material/styles";
// const PREFIX = "UREPage";

// const classes = {
//   overlay: `${PREFIX}-overlay`,
//   default: `${PREFIX}-default`,
// };

const GelFormStyled = styled(GelForm)({
  label: {
    marginBottom: "0px !important",
  },
});

export function UREPage({ pageInfo }) {
  const { sections, code } = pageInfo;
  const applicationStatus = useApplicationStatus();
  const mainPageRef = useRef(null);
  const updatedSections =
    sections &&
    sections.length &&
    sections
      .filter(({ visible }) => visible)
      .map((section, index) => {
        return {
          ...section,
          isSectionReadOnly: isPageReadOnly(code),
          pageCode: code,
          baseQuestions: updatedBaseQuestions(section.baseQuestions),
        };
      });

  const isMainPageRefInViewport = useIsInViewport(mainPageRef);

  useEffect(() => {
    if (isMainPageRefInViewport) {
      setTimeout(() => {
        const elementOpenSection = document.querySelectorAll([
          ".expanded-section",
        ]);
        const topElementOpenSection =
          elementOpenSection[0].getBoundingClientRect().top;

        const elementHeader = document.querySelectorAll([
          ".section-header-container",
        ]);

        const headerHeight =
          elementHeader && elementHeader.length > 0
            ? elementHeader[0].getBoundingClientRect().height
            : 0;

        if (
          topElementOpenSection > 0 &&
          topElementOpenSection > window.innerHeight - headerHeight
        ) {
          window.scrollTo({
            top: topElementOpenSection,
            left: 0,
            behavior: "smooth",
          });
        } else {
          console.log("open section in view port");
        }
      });
    }
  }, [pageInfo, isMainPageRefInViewport]);

  function updatedBaseQuestions(baseQuestions) {
    return baseQuestions.map((baseQuestion) => {
      const { customProperties } = baseQuestion;
      const linkedQuestions =
        (customProperties &&
          customProperties.HASLINKEDQUESTION &&
          getAllLinkedQuestions(baseQuestion, baseQuestions)) ||
        null;
      return {
        ...baseQuestion,
        linkedQuestions,
      };
    });
  }

  function getAllLinkedQuestions(baseQuestion, baseQuestions) {
    const linkedQuestions = baseQuestions.filter(
      ({ visible, customProperties }) =>
        visible &&
        customProperties &&
        customProperties.LINKEDQUESTION === baseQuestion.code
    );
    if (linkedQuestions && linkedQuestions.length > 0) {
      let childQuestion = 0;
      const updatedLinkedQuestions =
        linkedQuestions &&
        linkedQuestions.length !== 0 &&
        linkedQuestions.flatMap(
          (linkedQuestion, index, filteredBaseQuestions) => {
            if (childQuestion > 0) {
              childQuestion--;
              return [];
            }

            if (linkedQuestion.customProperties) {
              switch (linkedQuestion.customProperties.CUSTOM_TYPE) {
                case CUSTOM_QUES_TYPE.PICKLIST_HEADING:
                  childQuestion =
                    +linkedQuestion.customProperties.PICKLIST_ITEM;
                  break;
                default:
                  break;
              }
            }
            return {
              ...linkedQuestion,
              childBaseQuestions:
                childQuestion > 0
                  ? filteredBaseQuestions.slice(
                      index + 1,
                      index + 1 + childQuestion
                    )
                  : null,
            };
          }
        );

      return updatedLinkedQuestions;
    }

    return linkedQuestions;
  }

  function isPageReadOnly(pageCode) {
    return (
      applicationStatus &&
      ((applicationStatus === APPLICATION_STATUS.TELE_INTERVIEW &&
        TI_APP_READONLY_PAGES.includes(pageCode)) ||
        applicationStatus === APPLICATION_STATUS.SUBMITTED)
    );
  }

  return (
    <GelFormStyled labelAtTop id={code} data-testid={code} ref={mainPageRef}>
      <div className="section-content">
        <GelContainerLite gutter="small" style={{ padding: "0px" }}>
          <GelRowLayout gutter="small">
            {updatedSections &&
              updatedSections.length &&
              updatedSections.map((section, index) => (
                <Section
                  key={section.code}
                  sectionInfo={section}
                  isExpanded={index === 0}
                />
              ))}
          </GelRowLayout>
        </GelContainerLite>
      </div>
    </GelFormStyled>
  );
}

UREPage.propTypes = {
  pageInfo: propTypes.shape({
    text: propTypes.string.isRequired,
    sections: propTypes.array.isRequired,
    code: propTypes.string,
  }),
};
