import propTypes from "prop-types";

import { GelContainerLite, GelRowLayout } from "@tal-gel/components";

import { QuestionContainer } from "./QuestionContainer";
import { BooleanQuestion } from "./QuestionInput/BooleanQuestion";
import { SelectionQuestionDropdown } from "./QuestionInput/SelectionQuestionDropdown";
import { NumericQuestion } from "./QuestionInput/NumericQuestion";
import { TextQuestion } from "./QuestionInput/TextQuestion";
import { QUES_INPUT_TYPES, ERROR_MESSAGES } from "../utils/CONSTANTS";
import { WarningMessage } from "./WarningMessage";
import { useAnswerDisclosure } from "../utils/useAnswerDisclosure";
import { useEffect } from "react";
import { addErrorMessage } from "../globalStore/errorData-slice";
import { useDispatch } from "react-redux";
import { useErrorData, useCaseId } from "../globalStore/hooks";
import { RecordNetworkError } from "./RecordNetworkError";
import { stringToBoolean } from "../utils/commonFunctions";

function ReflexiveQuestionInput({ reflexiveQuestion, reflexiveAnswer }) {
  const {
    answerType,
    code,
    listItems,
    baseQuestionCode,
    aliasName,
    prevAnswers,
    allAnswers,
    answerValue,
    questionText,
    customProperties,
  } = reflexiveQuestion;

  if (!answerType) {
    console.error("Invalid answer type value", reflexiveQuestion);
    return <p name={code}>Answer type type not received.</p>;
  }

  const handleUserResponse = (e) => {
    const answer = e.target.value;
    if ((answerValue === null && answer === "") || answerValue === answer) {
      return "update not required";
    }
    let answers = [
      ...prevAnswers,
      { code: e.target.id, answer: e.target.value },
    ];

    if (answerType.toUpperCase() === QUES_INPUT_TYPES.TEXT && answer !== "") {
      if (allAnswers && allAnswers.length) {
        var currentReflexiveQuestion = allAnswers.find((x) => x.code === code);
        if (currentReflexiveQuestion) {
          currentReflexiveQuestion.answer = answer;
          answers = [...allAnswers];
        }
      }
    }
    reflexiveAnswer(baseQuestionCode, aliasName, answers);
  };

  const questionInput = {
    ...reflexiveQuestion,
    callbackFunctions: [
      // eslint-disable-next-line react/prop-types
      ...reflexiveQuestion.callbackFunctions,
      handleUserResponse,
    ],
  };

  switch (answerType.toUpperCase()) {
    case QUES_INPUT_TYPES.BOOLEAN:
      // return <p>Reflexive Question {answerType} </p>;
      return <BooleanQuestion question={questionInput} withLabel />;
    case QUES_INPUT_TYPES.NUMBER:
      return <NumericQuestion question={questionInput} withLabel />;
    case QUES_INPUT_TYPES.SELECTION:
      return (
        <SelectionQuestionDropdown
          code={code}
          listItems={listItems}
          answerValue={reflexiveQuestion.answerValue}
          callbackFunctions={questionInput.callbackFunctions}
          questionText={questionText}
          customProperties={customProperties}
          withLabel
        />
      );
    case QUES_INPUT_TYPES.TEXT:
      return (
        <TextQuestion
          question={questionInput}
          maxCharacters={500}
          noteField
          withLabel
        />
      );
    default:
      return (
        <p name={code}>
          Reflexive question type &quote;{answerType}&quote; not implemented in
          this version.
        </p>
      );
  }
}
ReflexiveQuestionInput.propTypes = {
  reflexiveQuestion: propTypes.shape({
    code: propTypes.string.isRequired,
    answerType: propTypes.string.isRequired,
    answerValue: propTypes.string,
    // options: propTypes.arrayOf(propTypes.string.isRequired),
    listItems: propTypes.arrayOf(
      propTypes.shape({
        label: propTypes.string.isRequired,
        value: propTypes.string.isRequired,
      })
    ),
    baseQuestionCode: propTypes.string.isRequired,
    aliasName: propTypes.string.isRequired,
    prevAnswers: propTypes.arrayOf(
      propTypes.shape({
        answer: propTypes.string.isRequired,
      })
    ),
    allAnswers: propTypes.arrayOf(
      propTypes.shape({
        answer: propTypes.string.isRequired,
        questionText: propTypes.string,
      })
    ),
    questionText: propTypes.string,
    customProperties: propTypes.shape({
      CMS: propTypes.string,
    }),
  }).isRequired,
  reflexiveAnswer: propTypes.func,
  onError: propTypes.func,
};

export function ReflexiveQuestionContainer({ reflexiveQuestion }) {
  const { customProperties, code } = reflexiveQuestion;
  const { CMS_WARN, Unknown_Disclosure } = customProperties || {};
  const dispatch = useDispatch();
  const caseId = useCaseId();

  const { answerDisclosure, error, called } = useAnswerDisclosure(
    answerReflexiveQuestCallback
  );

  const errorData = useErrorData();
  const reflexiveError = errorData.find(
    (refQuestionError) =>
      refQuestionError.baseQuestionCode === reflexiveQuestion.code &&
      refQuestionError.caseId === caseId &&
      refQuestionError.serverError === errorData[0].serverError
  );

  useEffect(() => {
    if (
      !error &&
      reflexiveError &&
      reflexiveError.serverError &&
      !reflexiveQuestion.answerValue
    )
      answerReflexiveQuestCallback();
  }, [reflexiveError, answerReflexiveQuestCallback]);
  function answerReflexiveQuestCallback(data) {
    dispatch(
      addErrorMessage({
        baseQuestionCode: reflexiveQuestion.code,
        serverError: false,
        caseId: caseId,
        errorType: "serverError",
      })
    );
  }

  function reflexiveAnswer(baseQuestionCode, aliasName, answers) {
    answerDisclosure({
      baseQuestionCode,
      aliasName,
      answers,
      isDisclosureUnknown: stringToBoolean(Unknown_Disclosure),
    });
    return null;
  }

  return (
    <QuestionContainer
      question={reflexiveQuestion}
      key={`reflexive-question-${code}`}
    >
      <GelContainerLite gutter={{ left: 40 }}>
        <GelRowLayout gutter="none">
          <ReflexiveQuestionInput
            name={`reflexive-question-${code}`}
            reflexiveQuestion={reflexiveQuestion}
            reflexiveAnswer={reflexiveAnswer}
          />
          {reflexiveError && (
            <WarningMessage message={ERROR_MESSAGES.SERVER_FAILURE} errorType />
          )}
          {CMS_WARN && <WarningMessage messageCode={CMS_WARN} warningType />}

          {error && (
            <RecordNetworkError
              baseQuestionCode={code}
              serverError={error ? true : false}
              caseId={caseId}
              iscalled={called}
            />
          )}
        </GelRowLayout>
      </GelContainerLite>
    </QuestionContainer>
  );
}

ReflexiveQuestionContainer.propTypes = {
  reflexiveQuestion: propTypes.shape({
    questionText: propTypes.string.isRequired,
    code: propTypes.string.isRequired,
    customProperties: propTypes.shape({
      CMS: propTypes.string,
      CMS_WARN: propTypes.string,
      Unknown_Disclosure: propTypes.string,
    }),
    baseQuestionCode: propTypes.string.isRequired,
    aliasName: propTypes.string.isRequired,
    prevAnswers: propTypes.arrayOf(
      propTypes.shape({
        answer: propTypes.string.isRequired,
      })
    ),
    callbackFunctions: propTypes.arrayOf(propTypes.func),
    answerValue: propTypes.string,
  }).isRequired,
  reflexiveAnswer: propTypes.func,
};
