import { gql } from "@apollo/client";
import { DISCLOSURE_FIELDS } from "../fragments/disclosureFields";

export const ANSWER_DISCLOSURE_MUTATION = gql`
  ${DISCLOSURE_FIELDS}
  mutation AnswerDisclosure($answerDisclosureInput: AnswerDisclosureInput!) {
    answerDisclosure(answerDisclosureInput: $answerDisclosureInput) {
      ...DisclosureFields
    }
  }
`;

export default ANSWER_DISCLOSURE_MUTATION;
