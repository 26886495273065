import { createSlice } from "@reduxjs/toolkit";
import { resetStore } from "./shared-actions";

const initialState = [];

export const empTableRowValuesSlice = createSlice({
  name: "empTableRowValues",
  initialState,

  reducers: {
    setEmpTableRowValues: (state, action) => {
      if (action.payload.Type === "UpdateState") {
        let newState = action.payload.updateAttributes;
        return [...newState];
      } else {
        const index = state.findIndex(
          (emp) =>
            action.payload.attributeName === emp.attributeName &&
            emp.caseId === action.payload.caseId
        );
        if (index !== -1) {
          let newState = state.filter(
            (item) =>
              item.attributeName !== action.payload.attributeName &&
              item.caseId === action.payload.caseId
          );
          return [...newState, action.payload];
        } else {
          return [...state, action.payload];
        }
      }
    },

    loadEmpHistoryTableData: (state, action) => {
      const emplDataArr = action.payload.updateAttributes;
      let newState = [];
      for (let i = 0; i < emplDataArr.length; i++) {
        newState.push({
          attributeName: emplDataArr[i].attributeName,
          attributeValue: emplDataArr[i].attributeValue,
          caseId: emplDataArr[i].caseId,
        });
      }
      return [...state, ...newState];
    },
    resetEmpHistoryTableData: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(resetStore.fulfilled, () => {
      return initialState;
    });
  },
});

export const {
  setEmpTableRowValues,
  loadEmpHistoryTableData,
  resetEmpHistoryTableData,
} = empTableRowValuesSlice.actions;

export default empTableRowValuesSlice.reducer;
