import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import { getBaseQuestionDataFromCache } from "./commonFunctions";
import DISCLOSURE_QUERY from "../graphql/queries/getDisclosure";
import { useCaseId } from "../globalStore/hooks";
import { CONFIGURABLES } from "../utils/CONSTANTS";

export function useQueryDisclosure({ baseQuestionCode, aliasName }) {
  const caseId = useCaseId();

  const [queryDisclosure, { data, ...rest }] = useLazyQuery(DISCLOSURE_QUERY);
  const { disclosures } =
    getBaseQuestionDataFromCache({ baseQuestionCode }) || {};

  const isDisclosureAttached =
    disclosures.findIndex(
      ({ aliasName: attachedAliasName }) => aliasName === attachedAliasName
    ) > -1;

  useEffect(() => {
    // skip query if disclosure is not attached to the question
    if (isDisclosureAttached) {
      const getDisclosureInput = {
        caseId,
        baseQuestionCode,
        aliasName,
        version: CONFIGURABLES.REACT_APP_FACADE_VERSION,
      };
      queryDisclosure({
        variables: { getDisclosureInput },
        fetchPolicy: "cache-first",
        partialRefetch: true,
      });
    }
  }, [
    aliasName,
    baseQuestionCode,
    caseId,
    isDisclosureAttached,
    queryDisclosure,
  ]);

  let result;
  if (data) {
    const [disclosure] = data.disclosure;
    result = { disclosure };
  }
  return { data: result, ...rest };
}
