import propTypes from "prop-types";
import { GelFormField, GelLabel, GelRowLayout } from "@tal-gel/components";
import { htmlParse } from "../../utils/commonFunctions";
import { useQueryHintTextList } from "../../utils/useQueryHintTextList";

export function FormField({ questionText, customProperties = {}, children }) {
  const label = htmlParse(questionText || "question text not received");
  const { hintTextList } = useQueryHintTextList();
  const tooltipCode = customProperties && customProperties.CMS;
  const Subquestion = customProperties && customProperties.SUBQUESTION;
  const tooltip =
    hintTextList &&
    typeof hintTextList[tooltipCode] === "string" &&
    htmlParse(hintTextList[tooltipCode] || "");

  return Subquestion ? (
    <GelFormField tooltip={tooltip} ure tooltipFixedAtTopLevel>
      <GelRowLayout gutter="small">
        <GelLabel
          font="500"
          style={{
            color: "#666666",
            fontWeight: "500",
          }}
        >
          {label}
        </GelLabel>

        {children}
      </GelRowLayout>
    </GelFormField>
  ) : (
    <GelFormField label={label} tooltip={tooltip} ure tooltipFixedAtTopLevel>
      {children}
    </GelFormField>
  );
}

FormField.propTypes = {
  questionText: propTypes.string.isRequired,
  customProperties: propTypes.shape({
    CMS: propTypes.string,
    SUBQUESTION: propTypes.string,
  }),
  children: propTypes.node,
};
