import propTypes from "prop-types";
import { useState, useEffect } from "react";
import {
  GelCheckboxGroup,
  GelCheckbox,
  GelContainerLite,
  GelRowLayout,
} from "@tal-gel/components";
import { Disclosure } from "./Disclosure";
import { useCreateDisclosure } from "../../utils/useCreateDisclosure";
import { useDeleteDisclosure } from "../../utils/useDeleteDisclosure";
import { WarningMessage } from "../WarningMessage";
import { ERROR_MESSAGES, SECTION_STATUSES } from "../../utils/CONSTANTS";
import { addErrorMessage } from "../../globalStore/errorData-slice";
import { useDispatch } from "react-redux";
import { useErrorData, useCaseId } from "../../globalStore/hooks";
import { RecordNetworkError } from "../RecordNetworkError";
import { useSectionContext } from "../../contexts/SectionContext";

export function DiclosureSourcePicklist({ question }) {
  const {
    code,
    picklistQuestionMeta: { picklistItems },
  } = question;
  const sectionContext = useSectionContext();

  function isSelected() {
    return question.disclosures && question.disclosures.length > 0;
  }

  return (
    <GelContainerLite gutter="small">
      <GelRowLayout gutter="none">
        <GelCheckboxGroup
          vertical
          gap={"small"}
          required
          requiredErrorMsg={ERROR_MESSAGES.REACT_APP_ERR_MSG_REQ}
          error={
            !isSelected() && sectionContext === SECTION_STATUSES.Incomplete
          }
        >
          {picklistItems.map((picklistItem) => (
            <PicklistOption
              key={`${code}-${picklistItem}`}
              picklistItem={picklistItem}
              question={question}
            />
          ))}
        </GelCheckboxGroup>
      </GelRowLayout>
    </GelContainerLite>
  );
}

DiclosureSourcePicklist.propTypes = {
  question: propTypes.shape({
    code: propTypes.string.isRequired,
    answerType: propTypes.string,
    picklistQuestionMeta: propTypes.shape({
      picklistItems: propTypes.arrayOf(propTypes.string.isRequired).isRequired,
    }).isRequired,
    disclosures: propTypes.arrayOf(
      propTypes.shape({
        aliasName: propTypes.string.isRequired,
      })
    ),
  }),
};

function PicklistOption({ question, picklistItem }) {
  const [disclosure, setDisclosure] = useState(null);
  const { code, disclosures } = question;
  const checked =
    disclosures &&
    Array.isArray(disclosures) &&
    disclosures.some(({ aliasName }) => aliasName === picklistItem);
  const caseId = useCaseId();
  const {
    createDisclosure,
    error: errorCreate,
    called: createCalled,
  } = useCreateDisclosure();
  const {
    deleteDisclosure,
    error: errorDelete,
    called: deleteCalled,
  } = useDeleteDisclosure();
  const dispatch = useDispatch();
  const errorData = useErrorData();
  const networkError = errorData.find(
    (picklistError) =>
      picklistError.baseQuestionCode === code + "-" + picklistItem &&
      picklistError.caseId === caseId &&
      picklistError.serverError === errorData[0].serverError
  );
  useEffect(() => {
    if ((createCalled || deleteCalled) && question.disclosures.length === 0) {
      dispatch(
        addErrorMessage({
          baseQuestionCode: question.code + "-" + picklistItem,
          serverError: false,
          caseId: caseId,
          errorType: "serverError",
        })
      );
    }
  }, [createCalled, deleteCalled, dispatch]);
  function handleCheck() {
    createDisclosure({
      baseQuestionCode: code,
      aliasName: picklistItem,
    })
      .then(({ data: { createDisclosure } }) => setDisclosure(createDisclosure))
      .catch((error) => console.log("Creating Disclosure :" + error));
    dispatch(
      addErrorMessage({
        baseQuestionCode: code + "-" + picklistItem, //`${code}-${picklistItem}`,
        serverError: errorCreate ? true : false,
        caseId: caseId,
        errorType: "serverError",
      })
    );
  }
  function handleUnchek() {
    deleteDisclosure({
      baseQuestionCode: code,
      aliasName: picklistItem,
    });
    if (!errorDelete) {
      dispatch(
        addErrorMessage({
          baseQuestionCode: code + "-" + picklistItem, //`${code}-${picklistItem}`,
          serverError: false,
          caseId: caseId,
          errorType: "serverError",
        })
      );
    }
  }

  return (
    <>
      <GelContainerLite gutter={{ left: 20, right: 0, top: 0, bottom: 15 }}>
        <GelRowLayout>
          <GelCheckbox
            id={`${code}-${picklistItem}`}
            key={`${code}-${picklistItem}`}
            name={picklistItem}
            defaultChecked={checked}
            onChangeCheck={handleCheck}
            onChangeUncheck={handleUnchek}
          >
            {picklistItem}
          </GelCheckbox>
          {networkError && (errorCreate || errorDelete) && (
            <WarningMessage message={ERROR_MESSAGES.SERVER_FAILURE} errorType />
          )}
          {(errorCreate || errorDelete) && (
            <RecordNetworkError
              baseQuestionCode={`${code}-${picklistItem}`}
              serverError={true}
              caseId={caseId}
              iscalled={createCalled || deleteCalled}
            />
          )}
          {(checked || disclosure) && (
            <div style={{ paddingTop: "10px" }}>
              <Disclosure
                aliasName={picklistItem}
                baseQuestionCode={code}
                disclosure={disclosure}
              />
            </div>
          )}
        </GelRowLayout>
      </GelContainerLite>
    </>
  );
}
PicklistOption.propTypes = {
  question: propTypes.shape({
    code: propTypes.string.isRequired,
    answerType: propTypes.string,
    picklistQuestionMeta: propTypes.shape({
      picklistItems: propTypes.arrayOf(propTypes.string.isRequired).isRequired,
    }).isRequired,
    disclosures: propTypes.arrayOf(
      propTypes.shape({
        aliasName: propTypes.string.isRequired,
      })
    ),
  }),
  picklistItem: propTypes.string,
};
