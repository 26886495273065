import propTypes from "prop-types";
import { GelContainerLite, GelRowLayout } from "@tal-gel/components";
import { SearchSuggestions } from "../QuestionInput/SearchSuggestions";
import { useQueryOccupationsLazy } from "../../utils/useQueryOccupations";
import { useAnswerBaseQuestion } from "../../utils/useAnswerBaseQuestion";
import { WarningMessage } from "../WarningMessage";
import { ERROR_MESSAGES } from "../../utils/CONSTANTS";

export function OccupationSearch({ question }) {
  const {
    code,
    answerValue,
    callbackFunctions,
    questionText,
    customProperties,
  } = question;
  const { answerBaseQuestion, error } = useAnswerBaseQuestion(answerValue);

  function sendAnswerToBaseQuestion(occupation) {
    answerBaseQuestion({ baseQuestionCode: code, answer: occupation });
    if (callbackFunctions && Array.isArray(callbackFunctions) && occupation) {
      callbackFunctions.forEach((callback) => {
        if (typeof callback === "function") {
          callback({
            target: {
              value: occupation,
              name: code,
            },
          });
        }
      });
    }
  }

  function handleConfirm(occupation) {
    sendAnswerToBaseQuestion(occupation);
  }

  function mapSuggestions(suggestions) {
    return (
      (Array.isArray(suggestions) &&
        suggestions.map(({ name }) => ({ label: name, value: name }))) ||
      []
    );
  }

  return (
    <GelContainerLite gutter="none">
      <GelRowLayout gutter="none">
        {/* <WarningMessage labelType messageCode={CMS_SEARCH} /> */}
        <SearchSuggestions
          code={code}
          inputValue={answerValue}
          requiredErrorMsgInput={ERROR_MESSAGES.REACT_APP_ERR_MSG_REQ}
          placeholder="Search occupation"
          onConfirm={handleConfirm}
          useSearch={useQueryOccupationsLazy}
          useSearchInput={{ limit: 20 }}
          suggestionsElementName="occupations"
          suggestionMapper={mapSuggestions}
          multipleSelect={false}
          resetSearchOnConfirm
          questionText={questionText}
          customProperties={customProperties}
          withLabel
        />
        {error && (
          <WarningMessage message={ERROR_MESSAGES.SERVER_FAILURE} errorType />
        )}
      </GelRowLayout>
    </GelContainerLite>
  );
}

OccupationSearch.propTypes = {
  question: propTypes.shape({
    code: propTypes.string.isRequired,
    callbackFunctions: propTypes.arrayOf(propTypes.func),
    answerValue: propTypes.string,
    questionText: propTypes.string,
    customProperties: propTypes.shape({
      CMS: propTypes.string,
      CMS_SEARCH: propTypes.string,
    }),
  }),
};
