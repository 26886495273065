import { makeVar, useReactiveVar } from "@apollo/client";

const setHintTextList = makeVar(undefined);

export function useHintTextList() {
  const hintTextList = useReactiveVar(setHintTextList);
  function transformHintTextList({ data }) {
    if (
      !hintTextList &&
      data &&
      data.lists &&
      Array.isArray(data.lists) &&
      data.lists.length
    ) {
      const [
        {
          list: { listItems },
        },
      ] = data.lists;

      const hintTextListList = listItems.reduce(
        (p, c) => ({ ...p, [c.code]: c.description }),
        {}
      );
      setHintTextList(hintTextListList);
    }
  }
  return { hintTextList, setHintTextList: transformHintTextList };
}
