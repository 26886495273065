import propTypes from "prop-types";
import { useState, useEffect } from "react";
import { GelAccordion, GelBoxLayout } from "@tal-gel/components";
import { getSectionStatus } from "../utils/commonFunctions";
import { BaseQuestion } from "./BaseQuestion";
import { CUSTOM_QUES_TYPE, SECTION_STATUSES } from "../utils/CONSTANTS";
import { useDispatch } from "react-redux";
import { addSectionData } from "../globalStore/sectionData-slice";
import {
  useSectionData,
  useCaseId,
  useSectionExpandData,
} from "../globalStore/hooks";
import CheckCircle from "@mui/icons-material/CheckCircle";
import ErrorOutlineRounded from "@mui/icons-material/ErrorOutlineRounded";
import CircleOutline from "@mui/icons-material/RadioButtonUncheckedOutlined";
import { getGelTokens } from "@tal-gel/theming";
import { styled } from "@mui/material/styles";
// import { sectionReadOnly } from "../components/Styles/CommonComponentStyles";
import { useApolloNetworkStatus } from "../graphql/config/graphQLClient";
import { SectionContextProvider } from "../contexts/SectionContext";
import {
  addIncompleteSection,
  addNotStartedSection,
  addCompletedSection,
  resetAllSectionTypes,
} from "../globalStore/sectionExpandData-slice";

const PREFIX = "Section";

const classes = {
  completed: `${PREFIX}-completed`,
  error: `${PREFIX}-error`,
  circle: `${PREFIX}-circle`,
  readonly: `${PREFIX}-readonly`,
};

const StyledGelAccordion = styled(GelAccordion)(() => {
  return {
    [`& .${classes.completed}`]: {
      color: getGelTokens().global.themeColorInteractive1,
      fontSize: 20,
    },
    [`& .${classes.error}`]: {
      color: getGelTokens().global.themeColorDanger,
      fontSize: 20,
    },
    [`& .${classes.circle}`]: {
      color: getGelTokens().global.themeColorGrayT30,
      fontSize: 20,
    },
    [`& .${classes.readonly}`]: {
      opacity: "0.5",
      pointerEvents: "none",
    },
  };
});

function SectionContainer({
  sectionTitle,
  children,
  isImplicit,
  code,
  sectionBaseQuestions,
  isSectionReadOnly,
  isExpanded,
  pageCode,
}) {
  const dispatch = useDispatch();
  const sectionData = useSectionData();
  const sectionExpandData = useSectionExpandData();
  // const classes = useTalStepIconStyles();
  const caseId = useCaseId();
  const currentSection = sectionData.find(
    (section) => section.code === code && section.caseId === caseId
  );
  // const sectionStyleClasses = sectionReadOnly();
  const [expanded, setExpanded] = useState(false);
  const [initialSectionStatus, setInitialSectionStatus] = useState("");
  const status = useApolloNetworkStatus();
  useEffect(() => {
    if (
      (status.numPendingMutations === 0 || status.numPendingQueries === 0) &&
      caseId
    ) {
      dispatch(
        addSectionData({
          code,
          status: getSectionStatus({
            sectionCode: code,
            sectionBaseQuestions: sectionBaseQuestions,
          }),
          caseId: caseId,
        })
      );
    }
  }, [
    caseId,
    code,
    dispatch,
    sectionBaseQuestions,
    status.numPendingMutations,
    status.numPendingQueries,
  ]);

  useEffect(() => {
    const status = getSectionStatus({
      sectionCode: code,
      sectionBaseQuestions: sectionBaseQuestions,
    });
    setInitialSectionStatus(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const status = getSectionStatus({
      sectionCode: code,
      sectionBaseQuestions: sectionBaseQuestions,
    });

    if (!expanded) {
      setInitialSectionStatus(status);
    }

    if (status === SECTION_STATUSES.Incomplete)
      dispatch(addIncompleteSection({ code, pageCode }));

    if (status === SECTION_STATUSES.Not_Started)
      dispatch(addNotStartedSection({ code, pageCode }));

    if (status === SECTION_STATUSES.Completed)
      dispatch(addCompletedSection({ code, pageCode }));

    return () => {
      dispatch(resetAllSectionTypes());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection && currentSection.status]);

  useEffect(() => {
    // console.log("sectionExpandData::", )
    const openSection =
      sectionExpandData &&
      (sectionExpandData.incompleteSection ||
        sectionExpandData.notStartedSection ||
        sectionExpandData.completedSection);

    if (!expanded) setExpanded(openSection === code);
  }, [sectionExpandData]);

  if (isImplicit) {
    return <>{children}</>;
  }

  function handleCollapse(value) {
    setExpanded(false);
  }

  function handleExpand(params) {
    setExpanded(true);
  }

  function contains(parent, child) {
    if (!child || !child.parentElement) return false;
    if (child.parentElement === parent) return true;

    return contains(parent, child.parentElement);
  }

  function handleBlur(e) {
    const target = e.relatedTarget;
    const parent = e.currentTarget;
    if (!contains(parent, target)) {
      if (status.numPendingMutations === 0 || status.numPendingQueries === 0) {
        dispatch(
          addSectionData({
            code,
            status: getSectionStatus({
              sectionCode: code,
              sectionBaseQuestions: sectionBaseQuestions,
            }),
            caseId: caseId,
            pageCode: pageCode,
          })
        );
      }
    }
  }

  let title = sectionTitle || "No Section Title";
  const childCount = (children && children[0] && children[0].length) || 1;

  const statusComponent = (status) => {
    switch (status) {
      case SECTION_STATUSES.Not_Started:
        return <CircleOutline className={classes.circle} />;
      case SECTION_STATUSES.Incomplete:
        return <ErrorOutlineRounded className={classes.error} />;
      case SECTION_STATUSES.Completed:
        return <CheckCircle className={classes.completed} />;
      default:
        return "Section status not received";
    }
  };

  return (
    <StyledGelAccordion
      id={code}
      title={
        <GelBoxLayout space="auto" distribution="start">
          {title}
          {currentSection && statusComponent(currentSection.status)}
        </GelBoxLayout>
      }
      expand={expanded}
      onCollapse={handleCollapse}
      onExpand={handleExpand}
      minContentHeight={childCount * 50}
      data-testid={code}
      className={expanded ? "expanded-section" : "not-expanded-section"}
    >
      <SectionContextProvider value={initialSectionStatus}>
        <div
          onBlur={handleBlur}
          tabIndex="1"
          className={isSectionReadOnly ? classes.readonly : ""}
        >
          {children}
        </div>
      </SectionContextProvider>
    </StyledGelAccordion>
  );
}

SectionContainer.propTypes = {
  sectionTitle: propTypes.string,
  children: propTypes.node.isRequired,
  isImplicit: propTypes.bool,
  code: propTypes.string,
  sectionBaseQuestions: propTypes.array,
  isSectionReadOnly: propTypes.bool,
  isExpanded: propTypes.bool,
  pageCode: propTypes.string,
};

export function Section({ sectionInfo, isExpanded = false }) {
  const {
    text: sectionTitle,
    baseQuestions,
    isImplicit,
    code,
    isSectionReadOnly,
    pageCode,
  } = sectionInfo;
  let childQuestion = 0;
  let sectionBaseQuestions = [];
  let relatedQuestion = null;

  return (
    <SectionContainer
      sectionTitle={sectionTitle}
      isImplicit={isImplicit}
      code={code}
      sectionBaseQuestions={sectionBaseQuestions}
      isSectionReadOnly={isSectionReadOnly}
      isExpanded={isExpanded}
      pageCode={pageCode}
    >
      {baseQuestions &&
        baseQuestions.length !== 0 &&
        baseQuestions.flatMap((baseQuestion, index, filteredBaseQuestions) => {
          if (childQuestion > 0) {
            childQuestion--;
            return [];
          }
          if (
            !baseQuestion.visible ||
            (baseQuestion.customProperties &&
              baseQuestion.customProperties.LINKEDQUESTION &&
              parentHasLinkedCustomProp()) ||
            (baseQuestion.customProperties &&
              baseQuestion.customProperties.CUSTOM_TYPE ===
                CUSTOM_QUES_TYPE.WEIGHT)
          ) {
            return [];
          }
          if (baseQuestion.customProperties) {
            switch (baseQuestion.customProperties.CUSTOM_TYPE) {
              case CUSTOM_QUES_TYPE.PICKLIST_HEADING:
                childQuestion = +baseQuestion.customProperties.PICKLIST_ITEM;
                break;
              case CUSTOM_QUES_TYPE.TABLE:
                childQuestion =
                  +baseQuestion.customProperties.COLUMNS *
                  +baseQuestion.customProperties.ROWS;
                break;
              case CUSTOM_QUES_TYPE.HEIGHT:
                relatedQuestion = filteredBaseQuestions[index + 1];
                break;
              default:
                break;
            }
          }

          sectionBaseQuestions.push({
            ...baseQuestion,
            childBaseQuestions:
              childQuestion > 0 &&
              filteredBaseQuestions.slice(index + 1, index + 1 + childQuestion),
          });
          return (
            <BaseQuestion
              key={baseQuestion.code}
              baseQuestion={{
                ...baseQuestion,
                childBaseQuestions:
                  childQuestion > 0 &&
                  filteredBaseQuestions.slice(
                    index + 1,
                    index + 1 + childQuestion
                  ),
                relatedQuestion: relatedQuestion,
              }}
            />
          );

          function parentHasLinkedCustomProp() {
            return baseQuestions.some(({ code, linkedQuestions }) => {
              return (
                code === baseQuestion.customProperties.LINKEDQUESTION &&
                linkedQuestions &&
                linkedQuestions.length > 0
              );
            });
          }
        })}
      {((baseQuestions && baseQuestions.length === 0) || !baseQuestions) && (
        <p>No question receieved.</p>
      )}
    </SectionContainer>
  );
}

Section.propTypes = {
  sectionInfo: propTypes.shape({
    code: propTypes.string.isRequired,
    text: propTypes.string,
    baseQuestions: propTypes.array.isRequired,
    isImplicit: propTypes.bool,
    isSectionReadOnly: propTypes.bool,
    pageCode: propTypes.string,
  }),
  isExpanded: propTypes.bool,
};
