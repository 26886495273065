import propTypes from "prop-types";

import { GelParagraph } from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";

import { useHintTextList } from "../utils/useHintTextList";
import { LabelText } from "./UIUtilities/LabelText/LabelText";

function colorClasses() {
  return {
    warningMessage: {
      color: getGelTokens().global.themeColorTextWarning,
    },
    infoMessage: {
      color: getGelTokens().brand.brandColorPrimary1,
    },
    errorMessage: {
      color: getGelTokens().global.themeColorDanger,
    },
    default: {},
  };
}
export function WarningMessage({
  messageCode,
  message,
  infoType,
  warningType,
  errorType,
  labelType,
  id = "warningMessage",
}) {
  const { hintTextList } = useHintTextList();

  if ((!messageCode || !hintTextList) && !message) return null;
  const warningMessage = message || hintTextList[messageCode];

  if (!warningMessage) return null;

  if (labelType) {
    return <LabelText labelText={warningMessage} labelId={id}></LabelText>;
  }
  const classes = colorClasses();
  return (
    <GelParagraph
      style={
        (warningType && classes.warningMessage) ||
        (infoType && classes.infoMessage) ||
        (errorType && classes.errorMessage) ||
        classes.default
      }
      condensed={errorType}
    >
      {warningMessage}
    </GelParagraph>
  );
}

WarningMessage.propTypes = {
  messageCode: propTypes.string,
  message: propTypes.string,
  infoType: propTypes.bool,
  warningType: propTypes.bool,
  errorType: propTypes.bool,
  labelType: propTypes.bool,
  id: propTypes.string,
};
