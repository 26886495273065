import { createSlice } from "@reduxjs/toolkit";
import { resetStore } from "./shared-actions";

const initialState = {
  incompleteSection: undefined,
  notStartedSection: undefined,
  completedSection: undefined,
  pageCode: undefined,
};

export const sectionExpandDataSlice = createSlice({
  name: "sectionExpandData",
  initialState,

  reducers: {
    addIncompleteSection: (state, action) => {
      if (
        !state.incompleteSection ||
        (state.pageCode !== action.payload.pageCode && state.incompleteSection)
      ) {
        state.incompleteSection = action.payload.code;
        state.pageCode = action.payload.pageCode;
      }

      return state;
    },

    addNotStartedSection: (state, action) => {
      if (
        !state.notStartedSection ||
        (state.pageCode !== action.payload.pageCode && state.notStartedSection)
      ) {
        state.notStartedSection = action.payload.code;
        state.pageCode = action.payload.pageCode;
      }
      return state;
    },

    addCompletedSection: (state, action) => {
      if (
        !state.completedSection ||
        (state.pageCode !== action.payload.pageCode && state.completedSection)
      ) {
        state.completedSection = action.payload.code;
        state.pageCode = action.payload.pageCode;
      }

      return state;
    },
    resetAllSectionTypes: (state, action) => {
      if (state.pageCode) return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(resetStore.fulfilled, () => {
      return initialState;
    });
  },
});

export const {
  addIncompleteSection,
  addNotStartedSection,
  addCompletedSection,
  resetAllSectionTypes,
} = sectionExpandDataSlice.actions;

export default sectionExpandDataSlice.reducer;
