import { gql } from "@apollo/client";

export const UPDATE_CASE_ATTR_MUTATION = gql`
  mutation UpdateCaseAttributes(
    $updateCaseAttributesInput: UpdateCaseAttributesInput!
  ) {
    updateAttributes(updateAttributesInput: $updateCaseAttributesInput) {
      caseId
      attributes {
        attributeName
        attributeValue
      }
    }
  }
`;

export default UPDATE_CASE_ATTR_MUTATION;
