import { gql } from "@apollo/client";
import { BASE_QUESTION_FIELDS } from "../fragments/baseQuestionFields";
import { CONFIGURABLES } from "../../utils/CONSTANTS";

export const GET_ALL_QUESTIONS_QUERY = gql`
  ${BASE_QUESTION_FIELDS}
  query AllQuestions($caseId: ID!) {
    questions: baseQuestions(id: $caseId, version: "${CONFIGURABLES.REACT_APP_FACADE_VERSION}") {
      caseId
      pages {
        code
        text
        visible @client
        sections {
          code
          text
          visible @client
          baseQuestions {
            ...BaseQuestionFields
          }
          isImplicit
        }
      }
      lists {
        listName
        listItems {
          code
          description
        }
      }
    }
  }
`;

export default GET_ALL_QUESTIONS_QUERY;
