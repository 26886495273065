import propTypes from "prop-types";
import { UREPagesContainer } from "./components/UREPages";
import { CaseSearchGel } from "./components/temp/CaseSearchGel";

import { useCaseId } from "./globalStore/hooks";
import { useQueryConfig } from "./utils/useQueryConfig";
import { useDispatch } from "react-redux";
import { useRef } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { resetStore, setHandoverData } from "./globalStore/shared-actions";

const App = () => {
  const dispatch = useDispatch();
  const localCopyHandoverData = useRef({ handoverFunction: null });
  const [configLoading] = useQueryConfig();

  //This window function needs to be created for angular adviser site to access it
  window.sendHandoverDataToSpa = sendHandoverDataToSpa;
  function sendHandoverDataToSpa(handoverData) {
    if (handoverData && handoverData.caseId && handoverData.currentUserRole) {
      localCopyHandoverData.current.handoverFunction =
        handoverData.sendBackToAdviserSite;
      setHandoverDataInStore(handoverData);
    }
  }

  function sendBackToAdviserSite(strMessage) {
    if (
      localCopyHandoverData &&
      localCopyHandoverData.current &&
      localCopyHandoverData.current.handoverFunction
    )
      localCopyHandoverData.current.handoverFunction(strMessage);
  }

  async function setHandoverDataInStore(handoverData) {
    dispatch(resetStore())
      .then(unwrapResult)
      .then(() =>
        dispatch(
          setHandoverData({
            caseId: handoverData.caseId,
            currentUserRole: handoverData.currentUserRole,
            applicationStatus: handoverData.applicationStatus,
          })
        )
      )
      .catch((e) => console.error(e));
  }

  const caseId = useCaseId();

  //window.location.href (/dashboard/ure-apply and http://localhost:3000/) is temp code, for accessing the SPA via old URL and locally
  return (
    <div id="app" data-testid="app">
      {(window.location.href.includes("/dashboard/ure-apply") ||
        window.location.href.includes("http://localhost:3000/")) && (
        <CaseSearchGel />
      )}
      {caseId && !configLoading && (
        <>
          <UREPagesContainer sendBackToAdviserSite={sendBackToAdviserSite} />
        </>
      )}
    </div>
  );
};

function UREApp({ children }) {
  const caseId = useCaseId();
  return <>{caseId && children}</>;
}
UREApp.propTypes = {
  children: propTypes.node.isRequired,
};

export default App;
