import { useMutation } from "@apollo/client";
import { CREATE_DISCLOSURE_MUTATION } from "../graphql/mutations/createDisclosure";
import { cache } from "../graphql/config/GraphQLAPIProvider";
import GET_DISCLOSURE_QUERY from "../graphql/queries/getDisclosure";
import { addDisclosureToBaseQuestion } from "./commonFunctions";
import { useCaseId } from "../globalStore/hooks";
import { CONFIGURABLES } from "../utils/CONSTANTS";

export function useCreateDisclosure() {
  const caseId = useCaseId();
  const [createDisclosureMutation, { data, loading, error, called }] =
    useMutation(CREATE_DISCLOSURE_MUTATION);

  async function createDisclosure({ baseQuestionCode, aliasName }) {
    try {
      const createDisclosureInput = {
        caseId,
        baseQuestionCode,
        aliasName,
        version: CONFIGURABLES.REACT_APP_FACADE_VERSION,
      };
      const result = await createDisclosureMutation({
        variables: {
          createDisclosureInput,
        },
      });
      cache.writeQuery({
        query: GET_DISCLOSURE_QUERY,
        data: {
          disclosure: result.data.createDisclosure[0],
        },
        variables: {
          disclosureQueryInput: createDisclosureInput,
        },
      });
      addDisclosureToBaseQuestion({ baseQuestionCode, aliasName });
      return {
        data: { disclosure: result.data.createDisclosure[0] },
      };
    } catch (err) {
      console.log(err);
    }
  }
  let result;
  if (data) {
    const [disclosure] = data.createDisclosure;
    result = { disclosure };
  }
  return { createDisclosure, data: result, loading, error, called };
}
