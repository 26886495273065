import propTypes from "prop-types";
import { GelRadio, GelRadioButtonGroup } from "@tal-gel/components";
import {
  CUSTOM_QUES_TYPE,
  ERROR_MESSAGES,
  SECTION_STATUSES,
} from "../../utils/CONSTANTS";
import { stringToBoolean } from "../../utils/commonFunctions";
import { FormField } from "./FormField";
import { useState } from "react";
import { getGelTokens } from "@tal-gel/theming";
import { LabelText } from "../UIUtilities/LabelText/LabelText";
import { useSectionContext } from "../../contexts/SectionContext";
import { CaptionText } from "../CaptionText";
import styled from "styled-components";

const Root = styled.div`
  left: 10px;
  width: 22px;
  height: 22px;
  position: relative;
  label {
    padding-left: 40px;
    cursor: pointer;
    display: unset;
    font-weight: normal;
  }
  &::before {
    content: "";
    border-radius: 50%;
    border: 1px solid
      ${(props) =>
        props.hasError
          ? getGelTokens().global.themeColorBackgroundDanger
          : getGelTokens().global.themeColorGrayT40};
    background: ${(props) =>
      props.hasError
        ? getGelTokens().global.themeColorBackgroundDangerLight
        : getGelTokens().global.themeColorBackgroundDefault};
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

const Fill = styled.div`
  background: ${(props) =>
    props.default
      ? getGelTokens().global.themeColorBackgroundDefault
      : getGelTokens().global.themeColorSuccess};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  &::before {
    content: "";
    opacity: 0;
    width: calc(20px - 4px);
    position: absolute;
    height: calc(20px - 4px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid
      ${(props) =>
        props.default
          ? getGelTokens().global.themeColorBackgroundDefault
          : getGelTokens().global.themeColorGrayT40};
    border-radius: 100%;
  }
`;

const Input = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  margin: 0;
  width: calc(22px - 0px);
  height: calc(22px - 0px);
  cursor: pointer;
  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${Fill} {
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      &::before {
        opacity: 10s;
      }
    }
  }
`;

export function BooleanQuestion({
  question,
  withLabel = false,
  normalRadioButton = false,
}) {
  const {
    code,
    answerValue,
    customProperties,
    callbackFunctions,
    uiCustomization,
    questionText,
  } = question;
  let displayValues = [
    { displayText: "Yes", value: true },
    { displayText: "No", value: false },
  ];

  const [booleanValue, setBooleanValue] = useState(answerValue);
  const label = questionText || "question text not received";
  const sectionContext = useSectionContext();

  if (
    customProperties &&
    customProperties.CUSTOM_TYPE === CUSTOM_QUES_TYPE.BTN &&
    customProperties.CUSTOM_VALUES
  ) {
    const newDisplayText = customProperties.CUSTOM_VALUES.split(",");
    if (Array.isArray(newDisplayText)) {
      displayValues = displayValues.map((dv, i) => ({
        ...dv,
        displayText: newDisplayText[i],
      }));
    }
  }

  const handleChange = (e) => {
    const { value } = e.target;
    setBooleanValue(value);
    if (
      callbackFunctions &&
      Array.isArray(callbackFunctions) &&
      callbackFunctions.length
    ) {
      callbackFunctions.forEach((callback) => {
        if (typeof callback === "function") {
          callback(e);
        }
      });
    }
  };

  const radioDefaultValue =
    booleanValue ||
    (answerValue &&
      stringToBoolean(answerValue) &&
      (!customProperties.DEFAULT_VALUE ||
        stringToBoolean(customProperties.DEFAULT_VALUE)));

  const gelRadioGroupElement = (
    <GelRadioButtonGroup
      id={code}
      name={code}
      width={200 * 2}
      gap="xsmall"
      required
      value={radioDefaultValue}
      errorMsg={{
        required: ERROR_MESSAGES.REACT_APP_ERR_MSG_REQ,
      }}
      onChange={handleChange}
      disabled={uiCustomization && uiCustomization.readOnly}
      buttonsPerRow={2}
      error={!booleanValue && sectionContext === SECTION_STATUSES.Incomplete}
    >
      {displayValues.map(({ displayText, value }) => (
        <GelRadio key={`${code}-${displayText}`} value={value}>
          {displayText}
        </GelRadio>
      ))}
    </GelRadioButtonGroup>
  );

  if (withLabel) {
    if (!normalRadioButton) {
      return (
        <FormField
          questionText={questionText}
          customProperties={customProperties}
        >
          {gelRadioGroupElement}
        </FormField>
      );
    }

    return (
      <>
        <table cellSpacing="0">
          <tbody>
            <tr>
              <td
                style={{
                  width: "70%",
                  paddingTop: "13px",
                  paddingBottom: "13px",
                  paddingLeft: "15px",
                  background: "unset",
                }}
              >
                <LabelText labelText={label}></LabelText>
              </td>
              {displayValues.map(({ displayText, value }) => (
                <td
                  key={`${code}-${displayText}`}
                  style={{
                    width: "400px",
                    minWidth: "80px",
                    background: !value
                      ? getGelTokens().global.themeColorGrayT10
                      : "unset",
                    verticalAlign: "top",
                    paddingTop: "13px",
                  }}
                >
                  <Root
                    hasError={
                      !booleanValue &&
                      sectionContext === SECTION_STATUSES.Incomplete
                    }
                  >
                    <label htmlFor={`${code}-${displayText}`}>
                      {displayText}
                      <Input
                        type="radio"
                        id={`${code}-${displayText}`}
                        name={`${code}`}
                        value={value}
                        checked={radioDefaultValue === value?.toString()}
                        onChange={handleChange}
                        disabled={uiCustomization && uiCustomization.readOnly}
                      />
                      <Fill />
                    </label>
                  </Root>
                  <br />
                  {!booleanValue &&
                    sectionContext === SECTION_STATUSES.Incomplete &&
                    value && (
                      <CaptionText
                        errorType
                        textMessage={ERROR_MESSAGES.REACT_APP_ERR_MSG_REQ}
                        marginTop="none"
                        marginBottom="none"
                      ></CaptionText>
                    )}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </>
    );
  }

  return gelRadioGroupElement;
}

BooleanQuestion.propTypes = {
  question: propTypes.shape({
    code: propTypes.string.isRequired,
    answerValue: propTypes.string,
    customProperties: propTypes.shape({
      CUSTOM_TYPE: propTypes.string,
      CUSTOM_VALUES: propTypes.string,
      DEFAULT_VALUE: propTypes.string,
    }),
    callbackFunctions: propTypes.arrayOf(propTypes.func),
    uiCustomization: propTypes.shape({
      readOnly: propTypes.bool,
    }),
    questionText: propTypes.string,
  }).isRequired,
  withLabel: propTypes.bool,
  normalRadioButton: propTypes.bool,
};
