import { useMutation } from "@apollo/client";
import { cache } from "../graphql/config/GraphQLAPIProvider";

import { UPDATE_CASE_ATTR_MUTATION } from "../graphql/mutations/updateCaseAttributes";
import { GET_ATTRIBUTES_QUERY } from "../graphql/queries/getAttributes";
import { GET_ALL_QUESTIONS_QUERY } from "../graphql/queries/getAllQuestions";
import {
  AttributesQuery_ATTRIBUTE_TYPES,
  AttributesQuery_TYPE,
  CONFIGURABLES,
} from "../utils/CONSTANTS";
import { useCaseId } from "../globalStore/hooks";
export function useUpdateCaseAttributes(employementupdateCallback) {
  const caseId = useCaseId();
  const [updateCaseAttributesMutation, { data, loading, error, called }] =
    useMutation(UPDATE_CASE_ATTR_MUTATION, {
      onCompleted(data) {
        if (employementupdateCallback) employementupdateCallback(data);
      },
    });

  async function updateCaseAttributes({
    type = AttributesQuery_TYPE.LIFE,
    updateAttributes,
    refreshAttributesQuery = false,
    refetchAllQuestions = false,
  }) {
    try {
      const updateCaseAttributesInput = {
        caseId,
        type,
        updateAttributes,
        version: CONFIGURABLES.REACT_APP_FACADE_VERSION,
      };
      const result = await updateCaseAttributesMutation({
        variables: {
          updateCaseAttributesInput,
        },
        refetchQueries: [
          refetchAllQuestions
            ? { query: GET_ALL_QUESTIONS_QUERY, variables: { caseId } }
            : {},
        ],
      });
      // if (refreshAttributesQuery)
      cache.writeQuery({
        query: GET_ATTRIBUTES_QUERY,
        data: {
          attributes: result.data.updateAttributes,
        },
        variables: {
          getAttributesInput: {
            caseId,
            type,
            version: CONFIGURABLES.REACT_APP_FACADE_VERSION,
            attributeTypes: AttributesQuery_ATTRIBUTE_TYPES.WRITABLE,
          },
        },
        broadcast: refreshAttributesQuery,
      });
      return {
        attributes: result.data.updateAttributes[0],
      };
    } catch (error) {
      console.log(error);
      //throw err;
    }
  }
  let result;
  if (data) {
    const [attributes] = data.updateAttributes;
    result = { attributes };
  }
  return { updateCaseAttributes, data: result, loading, error, called };
}
