import { createSlice } from "@reduxjs/toolkit";
import { resetStore } from "./shared-actions";

const initialState = {};

function calculateIncomeExcludingSuper({
  currentAnnualIncome,
  employerSuperContribution,
}) {
  return Math.trunc(
    (currentAnnualIncome * 100) / (100 + employerSuperContribution)
  );
}

export const incomeExcludingSuperSlice = createSlice({
  name: "incomeExcludingSuper",
  initialState,

  reducers: {
    setIncomeExcludingSuper: (state, action) => {
      const { questionCode, answerValue } = action.payload;
      const numberAnswerValue = +answerValue;

      if (state[questionCode] !== numberAnswerValue) {
        if (typeof numberAnswerValue !== "number") {
          return state;
        }

        state[questionCode] = numberAnswerValue;
        state.App_Inc_DAI_IND0 = calculateIncomeExcludingSuper({
          currentAnnualIncome: +state.App_Inc_INC_EMP_IND0,
          employerSuperContribution: +state.App_Inc_SGP_IND0,
        });
      }
    },
    resetIncomeExcludingSuper: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(resetStore.fulfilled, () => {
      return initialState;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setIncomeExcludingSuper, resetIncomeExcludingSuper } =
  incomeExcludingSuperSlice.actions;

export default incomeExcludingSuperSlice.reducer;
