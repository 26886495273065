import React from "react";
import propTypes from "prop-types";

const LinkedQuestionContext = React.createContext({});

export function LinkedQuestionContextProvider({ value, children }) {
  return (
    <LinkedQuestionContext.Provider value={value}>
      {children}
    </LinkedQuestionContext.Provider>
  );
}

LinkedQuestionContextProvider.propTypes = {
  value: propTypes.object,
  children: propTypes.node,
};

export const useLinkedQuestionContext = () =>
  React.useContext(LinkedQuestionContext);
