import { gql } from "@apollo/client";
export const PAGE_FIELDS = gql`
  fragment PageFields on UrepagesType {
    code
    sections {
      code
      text
      baseQuestions {
        code
        questionText
        questionType
        answerType
        answerText
        answerValue
        visible
        customProperties @client
        disclosures {
          aliasName
        }
      }
    }
  }
`;
