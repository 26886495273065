import propTypes from "prop-types";
import { SelectionQuestionDropdown } from "./SelectionQuestionDropdown";
import { getListByName } from "../../utils/commonFunctions";

export function SelectionQuestion({ question, nullable = true }) {
  const {
    caseDataQuestionMeta: { listName },
    answerValue,
    code,
    callbackFunctions,
    questionText,
    customProperties,
  } = question;
  const { listItems } = getListByName(listName);

  if (!listName) {
    return <p>List name not provided.</p>;
  }

  if (listItems.length) {
    return (
      <SelectionQuestionDropdown
        answerValue={answerValue}
        code={code}
        listItems={listItems}
        callbackFunctions={callbackFunctions}
        questionText={questionText}
        customProperties={customProperties}
        withLabel
        nullable={nullable}
      />
    );
  }

  return <p>Problem rendering Selection Question</p>;
}
SelectionQuestion.propTypes = {
  question: propTypes.shape({
    caseDataQuestionMeta: propTypes.shape({
      dataType: propTypes.string.isRequired,
      listName: propTypes.string.isRequired,
    }),
    code: propTypes.string.isRequired,
    answerValue: propTypes.string,
    callbackFunctions: propTypes.arrayOf(propTypes.func),
    questionText: propTypes.string,
    customProperties: propTypes.shape({
      CMS: propTypes.string,
    }),
  }).isRequired,
  nullable: propTypes.bool,
};
