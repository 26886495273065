import { ApolloProvider } from "@apollo/client";
import graphQLClient from "./graphQLClient";
import propTypes from "prop-types";

export default function GraphQLAPIProvider({ children, ...props }) {
  return (
    <ApolloProvider {...props} client={graphQLClient}>
      {children}
    </ApolloProvider>
  );
}

GraphQLAPIProvider.propTypes = {
  children: propTypes.node,
};

export const { cache } = graphQLClient;

export function resetCache() {
  return graphQLClient.clearStore();
}
