import propTypes from "prop-types";
import {
  GelBoxLayout,
  GelButton,
  GelRowLayout,
  GelContainerLite,
} from "@tal-gel/components";
import { NumericQuestion } from "../../QuestionInput/NumericQuestion";
import { QuestionContainer } from "../../QuestionContainer";
import { CaptionText } from "../../CaptionText";
import { INFO_MESSAGES } from "../../../utils/CONSTANTS";
import { useSelector } from "react-redux";

export function AdditionalCustomItemRows({
  columnHeading,
  caseDataQuestionMeta,
  customItems,
  addCustomValue,
  removeCustomItem,
}) {
  const assetsAndLiabilitiesData = useSelector(
    (state) => state.assetsAndLiabilitiesData
  );
  const { customProperties } = caseDataQuestionMeta;
  const { CDI_NAME } = customProperties;

  function getCustomItemsFromReduxState() {
    let customItems = [];

    Object.keys(assetsAndLiabilitiesData).forEach((key) => {
      const assetsOrLiability = assetsAndLiabilitiesData[key];
      if (
        assetsOrLiability.isCustom &&
        assetsOrLiability.tableType === columnHeading
      )
        customItems.push({
          label: assetsOrLiability.label,
          value: assetsOrLiability.cdiValue,
          cdiName: assetsOrLiability.cdiName,
        });
    });
    const sortedArr = customItems.sort((a, b) =>
      a.cdiName > b.cdiName ? 1 : -1
    );
    return sortedArr;
  }

  function removeItem(itemIndex, name, cdiName) {
    if (removeCustomItem) removeCustomItem(itemIndex, name);
  }

  const tempCustomItems = CDI_NAME
    ? getCustomItemsFromReduxState()
    : customItems;

  return tempCustomItems.map(({ label, value, cdiName }, index) => {
    function getAnswerValue(question) {
      return (
        assetsAndLiabilitiesData[question.cdiName]?.cdiValue?.toString() || ""
      );
    }

    function checkAnswerValue(question) {
      return (
        assetsAndLiabilitiesData[question.cdiName] &&
        assetsAndLiabilitiesData[question.cdiName].unSaved
      );
    }

    const question = {
      code: label,
      questionText: label,
      answerValue:
        value || (!isNaN(value) && value !== null) ? value.toString() : "",
      caseDataQuestionMeta,
      cdiName,
      callbackFunctions: [addCustomValue],
      customProperties,
    };

    return (
      <QuestionContainer
        key={`${columnHeading}-additional-custom-item-${label}`}
        question={question}
      >
        <GelContainerLite gutter="none">
          <GelRowLayout gutter="small">
            <GelBoxLayout alignment="start">
              <NumericQuestion
                name={question.code}
                question={{
                  ...question,
                  answerValue:
                    (CDI_NAME && getAnswerValue(question)) ||
                    question.answerValue,
                }}
                nullable={false}
                withLabel
              />
              <GelBoxLayout style={{ paddingTop: "26px" }}>
                <GelButton
                  secondary
                  icon="Bin"
                  title="Remove Item"
                  medium
                  onClick={() => removeItem(index, label, cdiName)}
                />
              </GelBoxLayout>
            </GelBoxLayout>
            {CDI_NAME && checkAnswerValue(question) && (
              <CaptionText
                errorType
                textMessage={INFO_MESSAGES.UNSAVED_WARNING_MESSAGE}
                marginTop="none"
                marginBottom="none"
              ></CaptionText>
            )}
          </GelRowLayout>
        </GelContainerLite>
      </QuestionContainer>
    );
  });
}
AdditionalCustomItemRows.propTypes = {
  columnHeading: propTypes.string,
  caseDataQuestionMeta: propTypes.object,
  customItems: propTypes.arrayOf(propTypes.object),
  addCustomValue: propTypes.func,
  removeCustomItem: propTypes.func,
};
