import { GelIcon, GelBoxLayout } from "@tal-gel/components";
import propTypes from "prop-types";
import { getGelTokens } from "@tal-gel/theming";
import { LabelText } from "../LabelText/LabelText";

function baseStyle() {
  return {
    alertBox: {
      border: "1px solid",
      borderRadius: "2px",
      padding: "8px 0px",
    },
    default: {},
  };
}

function colorClasses() {
  return {
    warning: {
      backgroundColor: getGelTokens().global.themeColorTextWarning,
    },
    infoMessage: {
      backgroundColor: getGelTokens().global.themeColorBackgroundAccentLight,
      borderColor: getGelTokens().global.themeColorBorderAccent,
    },
    errorMessage: {
      backgroundColor: getGelTokens().global.themeColorDanger,
    },
    default: {},
  };
}

export function AlertBar({ alertText, infoType, warningType, errorType }) {
  const baseClass = baseStyle();
  const classes = colorClasses();

  const getStyleByAlertType = () => {
    return (
      (infoType && classes.infoMessage) ||
      (warningType && classes.warning) ||
      (errorType && classes.errorMessage)
    );
  };

  return (
    <GelBoxLayout
      space={["auto"]}
      gap="medium"
      style={{ ...baseClass.alertBox, ...getStyleByAlertType() }}
    >
      <GelIcon
        name="AlertCircle"
        width={getGelTokens().global.sizeBaseUnit * 6}
        color={getGelTokens().global.themeColorBorderAccent}
      />
      <LabelText labelText={alertText}></LabelText>
    </GelBoxLayout>
  );
}

AlertBar.propTypes = {
  alertText: propTypes.string,
  infoType: propTypes.bool,
  warningType: propTypes.bool,
  errorType: propTypes.bool,
};
