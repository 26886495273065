import { useSelector } from "react-redux";
import { useUpdateCaseAttributes } from "../../utils/useUpdateCaseAttributes";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { ReadOnlyQuestion } from "../QuestionInput/ReadOnlyQuestion";

function IncomeExcludingSuper({ question }) {
  const incomeExcludingSuperData = useSelector(
    (state) => state.incomeExcludingSuperData
  );
  const { code, questionText, customProperties = {} } = question;
  const answerValue = incomeExcludingSuperData[code];
  const { PREFIX, SUFFIX } = customProperties || {};

  return (
    <>
      <ReadOnlyQuestion
        questionText={questionText}
        readonlyValue={answerValue}
        prefix={PREFIX}
        suffix={SUFFIX}
      />
      <StoreIncomeExcludingSuper attributeValue={answerValue} />
    </>
  );
}

IncomeExcludingSuper.propTypes = {
  question: PropTypes.object.isRequired,
};
export default IncomeExcludingSuper;

function StoreIncomeExcludingSuper({ attributeValue }) {
  const { updateCaseAttributes } = useUpdateCaseAttributes();
  useEffect(() => {
    if (typeof attributeValue === "number") {
      updateCaseAttributes({
        updateAttributes: [
          {
            attributeName: "DERIVED_ANNUAL_INCOME_INPUT",
            attributeValue: attributeValue.toString(),
          },
        ],
        refetchAllQuestions: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeValue]);
  return null;
}

StoreIncomeExcludingSuper.propTypes = {
  attributeValue: PropTypes.any,
};
