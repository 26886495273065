import { gql } from "@apollo/client";
export const SECTION_FIELDS = gql`
  fragment SectionFields on SectionsType {
    code
    text
    baseQuestions {
      code
      category
      questionText
      questionType
      answerType
      answerText
      answerValue
      disclosureSource
      linkedAlias
      visible
      picklistQuestionMeta {
        picklistItems
      }
      disclosures {
        aliasName
      }
      conditionalQuestionMeta {
        childQuestionCodes {
          caseId
          lifeInstance
          baseQuestionCode
          interviewCode
          __typename
        }
        requiresLinkedDisclosure
        requiredDisclosureMade
        __typename
      }
      parentConditionalQuestions {
        baseQuestionCode
      }
      customProperties @client
    }
  }
`;
