import propTypes from "prop-types";
import {
  GelContainerLite,
  GelRowLayout,
  GelBoxLayout,
  GelButton,
  GelLabel,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { Disclosure } from "./Disclosure";
import { useCreateDisclosure } from "../../utils/useCreateDisclosure";
import { useDeleteDisclosure } from "../../utils/useDeleteDisclosure";
import { useQueryConditionsLazy } from "../../utils/useQueryConditions";
import { SearchSuggestions } from "../QuestionInput/SearchSuggestions";
import { WarningMessage } from "../WarningMessage";
import { ERROR_MESSAGES } from "../../utils/CONSTANTS";
import { Tag } from "../Tag/Tag";
import { useRef } from "react";

export function DiclosureSourceSearch({ question }) {
  const { code, disclosures, category, customProperties } = question;
  const { CMS_SEARCH } = customProperties || {};
  const currentDisclosures = disclosures.map(({ aliasName }) =>
    aliasName.toLowerCase()
  );
  const searchRef = useRef(null);

  const navigateToSpecificElement = (id) => {
    const elem = searchRef?.current?.querySelector('[id="' + id + '"]');
    elem?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div ref={searchRef}>
      <GelContainerLite gutter={{ left: "large" }}>
        <GelRowLayout gutter="small">
          <WarningMessage
            id={`${code}_searchInput`}
            labelType
            messageCode={CMS_SEARCH}
          />
        </GelRowLayout>
        <GelRowLayout gutter="xsmall">
          <SearchBoxWrapper
            code={code}
            category={category}
            exclusions={currentDisclosures}
            showRequireError={disclosures.length < 1}
          />
        </GelRowLayout>
        <GelRowLayout gutter="medium">
          <GelBoxLayout gap="xsmall" space="auto" distribution="start">
            {disclosures &&
              disclosures.length > 0 &&
              disclosures.map(({ aliasName }) => (
                <DisclosureTagWrapper
                  key={`${code}-${aliasName}`}
                  aliasName={aliasName}
                  baseQuestionCode={code}
                  onClick={navigateToSpecificElement}
                />
              ))}
          </GelBoxLayout>
        </GelRowLayout>
        {disclosures &&
          disclosures.length > 0 &&
          disclosures.map(({ aliasName }) => (
            <GelRowLayout gutter="none" key={`${code}-${aliasName}`}>
              <RenderDisclosure
                key={`${code}-${aliasName}`}
                aliasName={aliasName}
                baseQuestionCode={code}
              />
            </GelRowLayout>
          ))}
      </GelContainerLite>
    </div>
  );
}

DiclosureSourceSearch.propTypes = {
  question: propTypes.shape({
    code: propTypes.string.isRequired,
    answerType: propTypes.string,
    disclosures: propTypes.arrayOf(
      propTypes.shape({
        aliasName: propTypes.string.isRequired,
      })
    ),
    category: propTypes.string.isRequired,
    customProperties: propTypes.shape({
      CMS_SEARCH: propTypes.string,
    }),
  }),
};

function RenderDisclosure({ aliasName, baseQuestionCode }) {
  const { deleteDisclosure, error } = useDeleteDisclosure();
  function handleRemoveCondition() {
    deleteDisclosure({
      baseQuestionCode,
      aliasName,
    });
  }

  return (
    <>
      <GelBoxLayout space="auto" distribution="spaceBetween" alignment="end">
        <GelLabel
          style={{ marginBottom: "0px" }}
          id={`${baseQuestionCode}-${aliasName}`}
        >
          {aliasName}
        </GelLabel>
        <GelButton
          id={"Remove_" + baseQuestionCode}
          secondary
          medium
          onClick={handleRemoveCondition}
        >
          REMOVE
        </GelButton>
      </GelBoxLayout>
      <hr
        style={{
          backgroundColor: getGelTokens().global.themeColorGrayT20,
          height: "1px",
          marginTop: "15px",
          marginBottom: "10px",
        }}
      ></hr>
      <Disclosure
        key={`${baseQuestionCode}-${aliasName}`}
        baseQuestionCode={baseQuestionCode}
        aliasName={aliasName}
      />
      {error && (
        <WarningMessage message={ERROR_MESSAGES.SERVER_FAILURE} errorType />
      )}
    </>
  );
}
RenderDisclosure.propTypes = {
  aliasName: propTypes.string.isRequired,
  baseQuestionCode: propTypes.string.isRequired,
};

function SearchBoxWrapper({ code, category, exclusions, showRequireError }) {
  const { createDisclosure, error } = useCreateDisclosure();

  function handleConfirm(searchValue) {
    createDisclosure({
      baseQuestionCode: code,
      aliasName: searchValue,
    });
  }
  function validateSearchTextInput(searchValue) {
    if (exclusions.includes(searchValue.toLowerCase())) {
      return "Can not add condition more than once";
    }
    return "";
  }
  function mapSuggestions(suggestions) {
    return (
      (Array.isArray(suggestions) &&
        suggestions.map((conditionName) => ({
          label: conditionName,
          value: conditionName,
        }))) ||
      []
    );
  }

  return (
    <>
      <SearchSuggestions
        code={code}
        onConfirm={handleConfirm}
        requiredErrorMsgInput={
          (showRequireError &&
            ERROR_MESSAGES.REACT_APP_DISCLOSURE_SEARCH_REQ_ERR_MSG) ||
          ""
        }
        useSearch={useQueryConditionsLazy}
        useSearchInput={{ category, limit: 20 }}
        suggestionsElementName="conditions"
        placeholder="Search condition"
        validateInput={validateSearchTextInput}
        suggestionMapper={mapSuggestions}
        resetSearchOnConfirm
      />
      {error && (
        <WarningMessage message={ERROR_MESSAGES.SERVER_FAILURE} errorType />
      )}
    </>
  );
}

SearchBoxWrapper.propTypes = {
  code: propTypes.string.isRequired,
  category: propTypes.string.isRequired,
  onConfim: propTypes.func,
  onQuerySearchText: propTypes.func,
  exclusions: propTypes.arrayOf(propTypes.string),
  showRequireError: propTypes.bool,
};

function DisclosureTagWrapper({ aliasName, baseQuestionCode, onClick }) {
  const { deleteDisclosure } = useDeleteDisclosure();

  function handleRemoveCondition() {
    deleteDisclosure({
      baseQuestionCode,
      aliasName,
    });
  }

  return (
    <Tag
      code={`${baseQuestionCode}-${aliasName}`}
      text={aliasName}
      onDelete={handleRemoveCondition}
      onClick={onClick}
    />
  );
}

DisclosureTagWrapper.propTypes = {
  aliasName: propTypes.string.isRequired,
  baseQuestionCode: propTypes.string.isRequired,
  onClick: propTypes.func,
};
