import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GelParagraph, GelLabel, GelBoxLayout } from "@tal-gel/components";
import { useDispatch } from "react-redux";
import { useAnswerBaseQuestionNoRefresh } from "../../utils/useAnswerBaseQuestionNoRefresh";
import { useBmiData } from "../../globalStore/hooks";
import { resetBmiData } from "../../globalStore/bmiData-slice";

function Bmi({ question }) {
  const bmiData = useBmiData();
  const dispatch = useDispatch();
  const { answerValue, code, questionText } = question;
  const [bmiValue, setBmiValue] = useState("");
  const { answerBaseQuestionNoRefresh } = useAnswerBaseQuestionNoRefresh({
    wait: 700,
  });

  const calculateBmiValue = useCallback(function ({ height, weight } = {}) {
    const heightInNumber = parseFloat(height);
    const weightInNumber = parseFloat(weight);
    let bmi;
    if (heightInNumber && weightInNumber) {
      const heightMt = heightInNumber / 100;
      const r = weight / (heightMt * heightMt);
      bmi = (Math.round(r * 100) / 100).toFixed(0).toString();
    }
    return {
      bmi,
    };
  }, []);
  useEffect(() => {
    return () => {
      dispatch(resetBmiData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bmiData) {
      const { bmi } = calculateBmiValue(bmiData);
      if (bmi && bmi !== bmiValue) {
        setBmiValue(bmi);
        answerBaseQuestionNoRefresh({
          baseQuestionCode: code,
          answer: bmi,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bmiData]);

  useEffect(() => {
    if (answerValue) {
      setBmiValue(answerValue);
    }
  }, [answerValue]);
  return (
    <GelBoxLayout space="none" distribution="start" gutter="small">
      <GelLabel>{questionText + ":"}</GelLabel>
      <GelParagraph name="calculated-bmi">
        {bmiValue
          ? bmiValue
          : "Please provide valid Height and Weight value to calculate BMI"}
      </GelParagraph>
    </GelBoxLayout>
  );
}

Bmi.propTypes = {
  question: PropTypes.shape({
    answerValue: PropTypes.string,
    code: PropTypes.string.isRequired,
    questionText: PropTypes.string,
  }),
};

export default Bmi;
