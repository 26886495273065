import PropTypes from "prop-types";
import dayjs from "dayjs";
import { GelBoxLayout, GelButton, GelRowLayout } from "@tal-gel/components";
import { TextQuestion } from "../../QuestionInput/TextQuestion";
import {
  ERROR_MESSAGES,
  CONFIGURABLES,
  INFO_MESSAGES,
  SECTION_STATUSES,
} from "../../../utils/CONSTANTS";
import DateQuestion from "../../QuestionInput/DateQuestion";
import { WarningMessage } from "../../WarningMessage";
import { useErrorData, useCaseId } from "../../../globalStore/hooks";
import { CaptionText } from "../../CaptionText";
import { useSectionContext } from "../../../contexts/SectionContext";

function EmploymentHistoryRow({
  cdiNames,
  cdiValues,
  rowIndex,
  onRowDataUpdate,
  spacingNode,
  onDeleteRow,
  onAddOccupation,
  rowCount,
  isAddOccEnable,
  lifeAttributeValues,
}) {
  const caseId = useCaseId();
  const errorData = useErrorData();
  const errorTable = errorData.find(
    (tblError) =>
      tblError.baseQuestionCode === "employmentTableError" &&
      tblError.caseId === caseId
  );
  const sectionContext = useSectionContext();

  function handleInput(e) {
    const { name, value } = e.target;
    const colIndex = cdiNames.indexOf(name);
    if (rowIndex === 0) {
      if (cdiValues[colIndex] !== value && value) {
        onRowDataUpdate({ rowIndex, colIndex, value, name });
      }
    } else {
      if (cdiValues[colIndex] !== value) {
        onRowDataUpdate({ rowIndex, colIndex, value, name });
      }
    }
  }
  const maxDate = dayjs().format("YYYY-MM-DD");
  return (
    <GelRowLayout>
      <GelBoxLayout
        alignment="center"
        space={[...Array(cdiNames.length).fill(6), "auto"]}
      >
        {cdiNames.map((code, index) => {
          const question = {
            code,
            answerValue: cdiValues[index],
            callbackFunctions: [handleInput],
          };

          return index < 2 ? (
            <div>
              <TextQuestion
                key={code}
                question={question}
                maxCharacters={35}
                validateControl={
                  !question.answerValue &&
                  rowIndex === 0 &&
                  sectionContext === SECTION_STATUSES.Incomplete
                }
                nullable={rowIndex !== 0}
                isTextRequired={rowIndex === 0}
              />
              {question.answerValue &&
                lifeAttributeValues[code] !== question.answerValue && (
                  <CaptionText
                    errorType
                    textMessage={INFO_MESSAGES.UNSAVED_WARNING_MESSAGE}
                    marginTop="none"
                    marginBottom="none"
                  ></CaptionText>
                )}
            </div>
          ) : (
            <div>
              <DateQuestion
                key={code}
                question={question}
                minDate={
                  (index === 3 && !!cdiValues[2] && cdiValues[2]) ||
                  CONFIGURABLES.REACT_APP_EMP_HISTORY_MIN_DATE
                }
                maxDate={
                  (index === 2 && !!cdiValues[3] && cdiValues[3]) || maxDate
                }
                validationErrorMessage={ERROR_MESSAGES.REACT_APP_INVALID_DATE}
                validateControl={
                  question.answerValue === "" &&
                  rowIndex === 0 &&
                  sectionContext === SECTION_STATUSES.Incomplete
                }
                nullable={rowIndex !== 0}
                isDateRequired={rowIndex === 0}
              />
              {question.answerValue &&
                lifeAttributeValues[code] !== question.answerValue && (
                  <CaptionText
                    errorType
                    textMessage={INFO_MESSAGES.UNSAVED_WARNING_MESSAGE}
                    marginTop="none"
                    marginBottom="none"
                  ></CaptionText>
                )}
            </div>
          );
        })}
        {rowIndex > 0 ? (
          <GelButton
            id="Employment_History_Remove_Item"
            secondary
            icon="Bin"
            title="Remove Item"
            medium
            onClick={() => {
              onDeleteRow(rowIndex);
            }}
          />
        ) : (
          <>{spacingNode}</>
        )}
        {rowIndex === rowCount - 1 && rowIndex < 3 ? (
          <GelButton
            id="Employment_History_Add_Item"
            secondary
            icon="Plus"
            title="Add Occupation"
            medium
            onClick={() => {
              onAddOccupation();
            }}
            disabled={!isAddOccEnable}
          />
        ) : (
          <>{spacingNode}</>
        )}
      </GelBoxLayout>
      {errorTable && (
        <WarningMessage message={ERROR_MESSAGES.SERVER_FAILURE} errorType />
      )}
    </GelRowLayout>
  );
}

EmploymentHistoryRow.propTypes = {
  cdiNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  cdiValues: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  rowIndex: PropTypes.number.isRequired,
  onRowDataUpdate: PropTypes.func.isRequired,
  spacingNode: PropTypes.node,
  onDeleteRow: PropTypes.func,
  onAddOccupation: PropTypes.func,
  rowCount: PropTypes.number,
  isAddOccEnable: PropTypes.bool,
  lifeAttributeValues: PropTypes.object.isRequired,
};

export default EmploymentHistoryRow;
