import { createSlice } from "@reduxjs/toolkit";
import { resetStore, setHandoverData } from "./shared-actions";
const initialState = "";

export const applicationStatusSlice = createSlice({
  name: "applicationStatus",
  initialState,

  reducers: {
    setApplicationStatus: (_, action) => {
      return action.payload.applicationStatus;
    },
    resetApplicationStatus: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(resetStore.fulfilled, () => {
      return initialState;
    });

    builder.addCase(setHandoverData.type, (_, action) => {
      return action.payload.applicationStatus;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setApplicationStatus, resetApplicationStatus } =
  applicationStatusSlice.actions;

export default applicationStatusSlice.reducer;
