import { useEffect, useState } from "react";
import { useApolloNetworkStatus } from "../../../graphql/config/graphQLClient";
import { GelProgressBar } from "@tal-gel/components";
import propTypes from "prop-types";

export function ProgressBar() {
  const status = useApolloNetworkStatus();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(status.numPendingMutations > 0 || status.numPendingQueries > 0);
  }, [status.numPendingMutations, status.numPendingQueries]);

  //Please dont remove class Name MuiSnackbarContent-message, its required for automation suite.

  return (
    <div
      className={
        "identify-div " + (loading ? "MuiSnackbarContent-message" : "")
      }
    >
      <GelProgressBar indeterminate={loading} />
    </div>
  );
}

ProgressBar.propTypes = {
  sticky: propTypes.bool,
};
