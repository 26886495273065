import { gql } from "@apollo/client";
import { BASE_QUESTION_FIELDS } from "../fragments/baseQuestionFields";

export const ANSWER_BASE_QUESTION_MUTATION = gql`
  ${BASE_QUESTION_FIELDS}
  mutation AnswerBQ($answerBaseQuestionInput: AnswerBaseQuestionInput!) {
    answerBasequestion(answerBaseQuestionInput: $answerBaseQuestionInput) {
      impactedBaseQuestions {
        ...BaseQuestionFields
      }
      answeredBaseQuestion {
        ...BaseQuestionFields
      }
    }
  }
`;

export const ANSWER_BASE_QUESTION_MUTATION_NO_REFRESH = gql`
  mutation AnswerBQNoRefresh(
    $answerBaseQuestionInput: AnswerBaseQuestionInput!
  ) {
    answerBasequestion(answerBaseQuestionInput: $answerBaseQuestionInput) {
      caseId
    }
  }
`;

export default ANSWER_BASE_QUESTION_MUTATION;
