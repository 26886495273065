import PropTypes from "prop-types";

import { GelTooltip } from "@tal-gel/components";
import { useQueryHintTextList } from "../utils/useQueryHintTextList";
import parse from "html-react-parser";

function HintText({ hintText, hintTextCode }) {
  const { hintTextList } = useQueryHintTextList();
  const tooltip =
    hintTextList &&
    typeof hintTextList[hintTextCode] === "string" &&
    parse(hintTextList[hintTextCode] || "");

  if (hintText || tooltip) {
    return <GelTooltip right>{hintText || tooltip}</GelTooltip>;
  }
  return null;
}

HintText.propTypes = {
  hintText: PropTypes.string,
  hintTextCode: PropTypes.string,
};

export default HintText;
