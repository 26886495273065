import propTypes from "prop-types";
import { useState, useEffect } from "react";
import {
  GelCheckboxGroup,
  GelCheckbox,
  GelContainerLite,
  GelRowLayout,
} from "@tal-gel/components";
import {
  CUSTOM_QUES_TYPE,
  ERROR_MESSAGES,
  SECTION_STATUSES,
} from "../../utils/CONSTANTS";
import { BaseQuestion } from "../BaseQuestion";
import { useAnswerBaseQuestion } from "../../utils/useAnswerBaseQuestion";
import { RenderByDisclosureSource } from "../Disclosures/RenderByDisclosureSource";
import { WarningMessage } from "../WarningMessage";
import { setInconsistentFlagOfBQ } from "../../globalStore/inconsistentAnswersToBQ-slice";
import { useDispatch } from "react-redux";
import { FormField } from "../QuestionInput/FormField";
import { useErrorData, useCaseId } from "../../globalStore/hooks";
import { addErrorMessage } from "../../globalStore/errorData-slice";
import { RecordNetworkError } from "../RecordNetworkError";
import { evictDisclosure } from "../../utils/commonFunctions";
import { useUpdateCaseAttributes } from "../../utils/useUpdateCaseAttributes";
import { getBaseQuestionDataFromCache } from "../../utils/commonFunctions";
import { useSectionContext } from "../../contexts/SectionContext";

export function PseudoPicklist({ question }) {
  const sectionContext = useSectionContext();
  const picklistItems = question.childBaseQuestions.reduce(
    (result, childBaseQ) => {
      if (childBaseQ.visible) {
        if (
          childBaseQ.customProperties &&
          childBaseQ.customProperties.CUSTOM_TYPE === CUSTOM_QUES_TYPE.PICKLIST
        )
          return [...result, { ...childBaseQ, childBaseQuestions: [] }];

        const lastItemIndex = result.length - 1;
        return result.map((picklistItem, index) => {
          if (index !== lastItemIndex) return picklistItem;

          // attach current base question as children of last picklist Item
          const { childBaseQuestions, ...rest } = picklistItem;
          return {
            ...rest,
            childBaseQuestions: [...childBaseQuestions, childBaseQ],
          };
        });
      }
      return result;
    },
    []
  );

  const [groupDisabled, setGroupDisabled] = useState(false);
  const { answerBaseQuestion } = useAnswerBaseQuestion();

  useEffect(() => {
    const pickListVisible =
      question &&
      question.childBaseQuestions &&
      question.childBaseQuestions[0].visible;
    if (question && question.code && !pickListVisible) {
      answerBaseQuestion({
        baseQuestionCode: question.code,
        answer: "true",
      });
    }
  }, []);

  function getPicklistItems() {
    return picklistItems;
  }

  function isSelected() {
    return picklistItems.some(function (picklistItem) {
      return picklistItem.answerValue || picklistItem.answerValue === "true";
    });
  }

  return (
    <GelContainerLite gutter={{ left: 30 }}>
      <GelRowLayout>
        <FormField
          questionText={question.questionText}
          customProperties={question.customProperties}
        >
          <GelCheckboxGroup
            name={`${question.code}-"checkBoxGroup"`}
            vertical
            gap={"small"}
            required
            requiredErrorMsg={ERROR_MESSAGES.REACT_APP_ERR_MSG_REQ}
            error={
              !isSelected() && sectionContext === SECTION_STATUSES.Incomplete
            }
            disabled={groupDisabled}
          >
            {picklistItems.map((picklistItem) => (
              <PicklistOption
                key={`${question.code}-${picklistItem.code}`}
                picklistItem={picklistItem}
                parentQuestion={question}
                getPicklistItems={getPicklistItems}
                setGroupDisabled={setGroupDisabled}
              />
            ))}
          </GelCheckboxGroup>
        </FormField>
      </GelRowLayout>
    </GelContainerLite>
  );
}

PseudoPicklist.propTypes = {
  question: propTypes.shape({
    code: propTypes.string.isRequired,
    answerType: propTypes.string,
    disclosures: propTypes.arrayOf(
      propTypes.shape({
        aliasName: propTypes.string.isRequired,
      })
    ),
    questionText: propTypes.string,
    customProperties: propTypes.shape({
      CMS: propTypes.string,
      CDI_Name: propTypes.string,
    }),
    childBaseQuestions: propTypes.arrayOf(propTypes.object),
  }),
};

function PicklistOption({
  parentQuestion,
  picklistItem,
  getPicklistItems,
  setGroupDisabled,
}) {
  const { answerBaseQuestion, error, called } = useAnswerBaseQuestion(
    picklistItem.answerValue || null
  );
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(
    (picklistItem.answerValue &&
      picklistItem.answerValue.toString() === "true") ||
      false
  );
  const { updateCaseAttributes } = useUpdateCaseAttributes();
  const caseId = useCaseId();
  const errorData = useErrorData();
  const isError = errorData.find(
    (serverError) =>
      serverError.baseQuestionCode === picklistItem.code &&
      serverError.caseId === caseId &&
      serverError.serverError === errorData[0].serverError
  );
  useEffect(() => {
    if (isError && isError.serverError && picklistItem.answerValue === null) {
      dispatch(
        addErrorMessage({
          baseQuestionCode: picklistItem.code,
          serverError: false,
          caseId: caseId,
          errorType: "serverError",
        })
      );
    }
  }, [isError, dispatch]);
  function answerBaseQuestionCallback() {
    setGroupDisabled(false);
    if (
      picklistItem.parentConditionalQuestions &&
      picklistItem.parentConditionalQuestions.length > 0
    ) {
      for (let parentConditionalQuestion of picklistItem.parentConditionalQuestions) {
        dispatch(
          setInconsistentFlagOfBQ({
            questionCode: parentConditionalQuestion.baseQuestionCode,
          })
        );
      }
    }
    // code added for delete fake picklist disclosure from cache

    const baseQ = getBaseQuestionDataFromCache({
      baseQuestionCode: picklistItem.code,
    });
    if (picklistItem.disclosures.length > 0) {
      const aliasName = picklistItem.disclosures[0].aliasName;
      if (
        baseQ &&
        baseQ.disclosures &&
        baseQ.disclosures.findIndex(
          ({ aliasName: attachedDisclosure }) =>
            aliasName === attachedDisclosure
        ) === -1
      ) {
        evictDisclosure({
          baseQuestionCode: picklistItem.code,
          aliasName: picklistItem.disclosures[0].aliasName,
        });
      }
    }
    dispatch(
      addErrorMessage({
        baseQuestionCode: picklistItem.code,
        serverError: false,
        caseId: caseId,
        errorType: "serverError",
      })
    );
  }

  function handleChange(e) {
    setChecked(!checked);
    answerBaseQuestion({
      baseQuestionCode: picklistItem.code,
      answer: !checked,
      answerBaseQuestionCallback: answerBaseQuestionCallback,
    });

    if (e.target.checked && !isSelected()) setGroupDisabled(true);

    const allpicklistItems = getPicklistItems();
    const pickListValue = allpicklistItems.filter(
      (prop) =>
        prop.code !== picklistItem.code &&
        (prop.answerValue === "true" || prop.answerValue === true)
    );
    if (pickListValue.length === 0 && checked) {
      let allPickListAttr = [];
      allpicklistItems.forEach(function (picklist) {
        allPickListAttr.push({
          attributeName: picklist.customProperties.CDI_NAME,
          attributeValue: "",
        });
      });
      if (
        allPickListAttr.filter((a) => a.attributeName !== undefined).length > 0
      ) {
        updateCaseAttributes({
          updateAttributes: allPickListAttr,
        });
      }
    }
    if (!error) {
      dispatch(
        addErrorMessage({
          baseQuestionCode: picklistItem.code,
          serverError: false,
          caseId: caseId,
          errorType: "serverError",
        })
      );
    }
  }

  function isSelected() {
    const picklistItems = getPicklistItems();
    return picklistItems.some(function (picklistItem) {
      return picklistItem.answerValue || picklistItem.answerValue === "true";
    });
  }

  return (
    <>
      <GelCheckbox
        key={`${parentQuestion.code}-${picklistItem.code}`}
        id={picklistItem.code}
        name={picklistItem.code}
        checked={checked}
        onChange={handleChange}
        disabled={picklistItem.answerValue === null && isSelected()}
      >
        {picklistItem.questionText}
      </GelCheckbox>
      {isError && (
        <WarningMessage message={ERROR_MESSAGES.SERVER_FAILURE} errorType />
      )}
      {error && (
        <RecordNetworkError
          baseQuestionCode={picklistItem.code}
          serverError={error ? true : false}
          caseId={caseId}
          iscalled={called}
        />
      )}
      {checked && RenderByDisclosureSource(picklistItem)}
      {checked &&
        picklistItem.childBaseQuestions &&
        picklistItem.childBaseQuestions.length > 0 && (
          <GelContainerLite gutter={{ left: 70, bottom: 15 }}>
            <GelRowLayout gutter="medium">
              {picklistItem.childBaseQuestions.map((childBaseQuestion) => (
                <BaseQuestion
                  key={`${parentQuestion.code}-${picklistItem.code}-${childBaseQuestion.code}`}
                  baseQuestion={childBaseQuestion}
                />
              ))}
            </GelRowLayout>
          </GelContainerLite>
        )}
      {checked &&
        getPicklistItems &&
        getPicklistItems().map(
          (item) =>
            item.code !== picklistItem.code &&
            item.answerValue === null && (
              <AnswerSiblingPicklist
                key={`${parentQuestion.code}-${item.code}-sibling`}
                picklistItem={item}
              ></AnswerSiblingPicklist>
            )
        )}
    </>
  );
}
PicklistOption.propTypes = {
  parentQuestion: propTypes.shape({
    code: propTypes.string.isRequired,
    answerType: propTypes.string,
    answerValue: propTypes.string,
    disclosures: propTypes.arrayOf(
      propTypes.shape({
        aliasName: propTypes.string.isRequired,
      })
    ),
    customProperties: propTypes.shape({
      CMS: propTypes.string,
      CDI_Name: propTypes.string,
    }),
  }),
  picklistItem: propTypes.object,
  childBaseQuestions: propTypes.arrayOf(propTypes.object),
  getPicklistItems: propTypes.func,
  setGroupDisabled: propTypes.func,
};

function AnswerSiblingPicklist({ picklistItem }) {
  const { answerBaseQuestion } = useAnswerBaseQuestion();
  useEffect(() => {
    answerBaseQuestion({
      baseQuestionCode: picklistItem.code,
      answer: "false",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
AnswerSiblingPicklist.propTypes = {
  picklistItem: propTypes.object,
};
