import propTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  GelContainerLite,
  GelRowLayout,
  GelColumnLayout,
  GelAutoCompleteInput,
  GelButton,
} from "@tal-gel/components";
import { FormField } from "./FormField";
import { WarningMessage } from "../WarningMessage";
import { ERROR_MESSAGES, SECTION_STATUSES } from "../../utils/CONSTANTS";
import { useSectionContext } from "../../contexts/SectionContext";

export function SearchSuggestions({
  inputValue = "",
  code,
  onConfirm,
  requiredErrorMsgInput,
  useSearch,
  useSearchInput,
  placeholder,
  validateInput,
  suggestionsElementName,
  suggestionMapper,
  multipleSelect = true,
  resetSearchOnConfirm = false,
  onCancel,
  withLabel = false,
  questionText,
  customProperties = {},
}) {
  const [searchSuggestionsLazy, { data, loading, error, variables }] =
    useSearch(useSearchInput);
  const [searchTextInput, setSearchTextInput] = useState("");
  const [autoCompleteData, setAutoCompleteData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [requiredError, setRequiredError] = useState("");
  const [isOptionsListOpen, setIsOptionsListOpen] = useState(false);
  const [executeSearch, setExecuteSearch] = useState(false);
  const { CMS_SEARCH } = customProperties || {};
  const [exactError, setExactError] = useState(false);
  const [serverError, setServerError] = useState(ERROR_MESSAGES.SERVER_FAILURE);
  const sectionContext = useSectionContext();

  useEffect(() => {
    if (
      executeSearch &&
      data &&
      variables &&
      variables.searchText === searchTextInput &&
      data[suggestionsElementName]
    ) {
      const suggestions =
        typeof suggestionMapper === "function"
          ? suggestionMapper(data[suggestionsElementName])
          : data[suggestionsElementName];
      if (suggestions.length === 0) {
        setRequiredError("");
        setExactError(`No results match '${searchTextInput}'`);
      } else {
        setExactError("");
      }
      setAutoCompleteData(suggestions);
      setExecuteSearch(false);
    }
  }, [
    data,
    executeSearch,
    searchTextInput,
    suggestionMapper,
    suggestionsElementName,
    variables,
  ]);

  useEffect(() => {
    if (!searchTextInput) {
      return setAutoCompleteData([]);
    }
    // do not validate the initial value
    if (inputValue !== searchTextInput && typeof validateInput === "function") {
      const validationError = validateInput(searchTextInput);
      if (validationError !== errorMessage) {
        setErrorMessage(validationError);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTextInput, validateInput]);
  useEffect(() => {
    setSearchTextInput(inputValue);
    if (!inputValue && sectionContext === SECTION_STATUSES.Incomplete) {
      setRequiredError(requiredErrorMsgInput);
    }
  }, [inputValue]);
  useEffect(() => {
    if (!requiredErrorMsgInput) {
      setRequiredError("");
    }
  }, [requiredErrorMsgInput]);

  function handleChange(e) {
    setSearchTextInput(e.target.value);
  }
  function onItemClick(item) {
    if (multipleSelect) {
      setAutoCompleteData([]);
    } else {
      if (typeof onConfirm === "function") {
        onConfirm(item.value);
      }
    }
    setRequiredError("");
  }
  function onSearch(searchText) {
    if (searchText.length > 2) {
      searchSuggestionsLazy({ searchText });
      setExecuteSearch(true);
    }
  }
  function handleConfirmButtonClick() {
    if (typeof onConfirm === "function") {
      setServerError("");
      onConfirm(searchTextInput.trim());
      if (resetSearchOnConfirm) {
        setSearchTextInput("");
      }
    }
    setRequiredError("");
    setAutoCompleteData([]);
  }

  function handleCancelButtonClick() {
    setSearchTextInput("");
    setErrorMessage("");
    setAutoCompleteData([]);
    if (!multipleSelect && requiredErrorMsgInput) {
      setRequiredError(requiredErrorMsgInput);
    }
    if (typeof onCancel === "function") {
      onCancel();
    }
  }

  function onBlur(e) {
    if (requiredErrorMsgInput && !searchTextInput && !isOptionsListOpen) {
      setRequiredError(requiredErrorMsgInput);
    } else {
      const textFromAutoCompleteData = autoCompleteData.find(
        (data) => data.value.toLowerCase() === searchTextInput.toLowerCase()
      );
      if (
        !multipleSelect &&
        typeof onConfirm === "function" &&
        textFromAutoCompleteData &&
        textFromAutoCompleteData.value
      ) {
        onConfirm(textFromAutoCompleteData.value);
        setRequiredError("");
      }
    }
  }

  function handleItemListOption(isOpen) {
    setIsOptionsListOpen(isOpen);
  }

  const gelAutoCompleteInputElement = (
    <GelAutoCompleteInput
      id={`${code}_searchInput`}
      name={code}
      value={searchTextInput}
      placeholder={placeholder}
      onChange={handleChange}
      onSearch={onSearch} // do live search from api
      onBlur={onBlur}
      data={autoCompleteData} // data to display in the list
      onOptionClick={onItemClick} // when an item in the data list is clicked
      onOptionListOpen={handleItemListOption}
      maxHeight={390}
      maxWidth={500}
      error={!!errorMessage || !!requiredError || !!error || !!exactError}
      caption={
        (error && serverError) || errorMessage || requiredError || exactError
      }
      loading={loading && !error}
      extraHeightToAvoid={250}
      onClear={handleCancelButtonClick}
    />
  );

  return (
    <GelContainerLite gutter="none">
      <GelRowLayout gutter="none" width="80%">
        <GelColumnLayout distribution="1/2 1/2">
          {withLabel ? (
            <FormField
              questionText={questionText}
              customProperties={customProperties}
            >
              <WarningMessage labelType messageCode={CMS_SEARCH} />
              {gelAutoCompleteInputElement}
            </FormField>
          ) : (
            gelAutoCompleteInputElement
          )}

          {multipleSelect && (
            <GelButton
              id={"Confirm_" + code}
              secondary
              medium
              onClick={handleConfirmButtonClick}
              disabled={
                !searchTextInput ||
                searchTextInput.length < 3 ||
                errorMessage ||
                loading
              }
            >
              CONFIRM
            </GelButton>
          )}
        </GelColumnLayout>
      </GelRowLayout>
    </GelContainerLite>
  );
}

SearchSuggestions.propTypes = {
  inputValue: propTypes.string,
  placeholder: propTypes.string,
  requiredErrorMsgInput: propTypes.string,
  code: propTypes.string.isRequired,
  onConfirm: propTypes.func,
  onQuerySearchText: propTypes.func,
  exclusions: propTypes.arrayOf(propTypes.string),
  useSearch: propTypes.func.isRequired,
  useSearchInput: propTypes.object.isRequired,
  suggestionsElementName: propTypes.string.isRequired,
  suggestionMapper: propTypes.func,
  validateInput: propTypes.func,
  multipleSelect: propTypes.bool,
  resetSearchOnConfirm: propTypes.bool,
  onCancel: propTypes.func,
  withLabel: propTypes.bool,
  questionText: propTypes.string,
  customProperties: propTypes.shape({
    CMS: propTypes.string,
    CMS_SEARCH: propTypes.string,
  }),
};
