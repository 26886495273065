import { useState, useEffect, useRef } from "react";

export function useComponentBlur(initialState) {
  const [isComponentVisited, setComponentVisited] = useState(initialState);
  const [isComponentBlur, setComponentLeft] = useState(false);
  const ref = useRef(null);

  function getCurrentStatus() {
    return isComponentBlur;
  }
  function handleClickOutside(event) {
    if (
      ref.current &&
      ref.current.contains(event.target) &&
      event.target instanceof HTMLInputElement &&
      !isComponentVisited
    ) {
      setComponentVisited(true);
      setComponentLeft(false);
    }

    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      isComponentVisited
    ) {
      setComponentVisited(false);
      setComponentLeft(true);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return {
    ref,
    isComponentVisited,
    isComponentBlur,
    setComponentVisited,
    getCurrentStatus,
  };
}
