import { gql } from "@apollo/client";
import { DISCLOSURE_FIELDS } from "../fragments/disclosureFields";

export const CREATE_DISCLOSURE_MUTATION = gql`
  ${DISCLOSURE_FIELDS}
  mutation CreateDisclosure($createDisclosureInput: CreateDisclosureInput!) {
    createDisclosure(createDisclosureInput: $createDisclosureInput) {
      ...DisclosureFields
    }
  }
`;

export default CREATE_DISCLOSURE_MUTATION;
