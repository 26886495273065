import propTypes from "prop-types";
import { useState, useEffect } from "react";
import { GelBoxLayout, GelButton, GelRowLayout } from "@tal-gel/components";
import { TableColumn } from "./TableColumn";
import { useQueryAtrributes } from "../../../utils/useQueryAttributes";
import { useAnswerBaseQuestionNoRefresh } from "../../../utils/useAnswerBaseQuestionNoRefresh";
import {
  AttributesQuery_TYPE,
  CUSTOM_QUES_TYPE,
  INFO_MESSAGES,
} from "../../../utils/CONSTANTS";
import {
  loadAnlTableData,
  resetAnlTable,
  resetUnSavedFlag,
} from "../../../globalStore/assetsAndLiabilities-slice";
import { useDispatch, useSelector } from "react-redux";
import {
  jsonParser,
  getBaseQuestionDataFromCache,
} from "../../../utils/commonFunctions";
import { useUpdateCaseAttributes } from "../../../utils/useUpdateCaseAttributes";
import { getGelTokens } from "@tal-gel/theming";
import { WarningMessage } from "../../WarningMessage";
import { styled } from "@mui/material/styles";

const PREFIX = "AnLTable";

const classes = {
  headingRowStyle: `${PREFIX}-headingRowStyle`,
};

const Root = styled("div")(() => ({
  [`& .${classes.headingRowStyle}`]: {
    border: "1px solid " + getGelTokens().global.themeColorGrayT20,
    width: "90%",
    padding: "0px 15px 15px",
    backgroundColor: "getGelTokens().global.themeColorWhite",
  },
}));

export function AssetsLiabilitiesTable({ question }) {
  const { customProperties, childBaseQuestions, code, answerValue } = question;
  const { COLUMNS, ROWS, CDI_NAME } = customProperties;
  const columnCount = +COLUMNS;
  const rowCount = +ROWS;

  const [tableColumns, setTableColumns] = useState([]);
  const { data } = useQueryAtrributes({
    type: AttributesQuery_TYPE.LIFE,
  });
  const { answerBaseQuestionNoRefresh } = useAnswerBaseQuestionNoRefresh();
  const assetsAndLiabilitiesData = useSelector(
    (state) => state.assetsAndLiabilitiesData
  );
  const { updateCaseAttributes, error } = useUpdateCaseAttributes(
    updateCaseAttributesCallback
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const columns = [];

    for (let i = 0; i < columnCount; i++) {
      let start = i * rowCount;
      let end = start + rowCount - 1;
      columns.push({
        linkedCaseDataAttributes: Object.keys(customProperties)
          .sort()
          .flatMap((customPropertyName) =>
            customPropertyName.includes(`COLUMN_CUSTOM_${i + 1}_CDI_`)
              ? [customProperties[customPropertyName]]
              : []
          ),
        columnHeading: customProperties[`COLUMN_HEADING_${i + 1}`],
        childBaseQuestions: childBaseQuestions.slice(start, end),
        totalBaseQuestion: childBaseQuestions[end],
      });
    }
    if (typeof answerValue !== "string" && !CDI_NAME)
      answerBaseQuestionNoRefresh({ baseQuestionCode: code, answer: "true" });
    setTableColumns(columns);

    return () => {
      // cleanUp(columns); // Note: commented because clear the values on multilifes change event
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childBaseQuestions]);

  useEffect(() => {
    return () => {
      const cachedBQ = getBaseQuestionDataFromCache({
        baseQuestionCode: code,
      });
      if (cachedBQ && cachedBQ.visible === false && CDI_NAME) {
        resetCustomItems(assetsAndLiabilitiesData);
        dispatch(resetAnlTable());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  function attributeValueForCustomItem(label, value) {
    const objJson = value !== null ? { label, value } : { label };
    return JSON.stringify(objJson);
  }

  function resetCustomItems(assetsAndLiabilitiesData) {
    let anLTableCdiAttributes = [];
    Object.keys(assetsAndLiabilitiesData).forEach((key) => {
      const assetsOrLiability = assetsAndLiabilitiesData[key];
      if (assetsOrLiability.isCustom)
        anLTableCdiAttributes.push({
          attributeName: assetsOrLiability.cdiName,
          attributeValue: null,
        });
    });

    if (anLTableCdiAttributes && anLTableCdiAttributes.length > 0)
      updateCaseAttributes({
        updateAttributes: anLTableCdiAttributes,
        refetchAllQuestions: true,
      });
  }

  function convertAnLDataToCdiArray() {
    let anLTableCdiAttributes = [];
    Object.keys(assetsAndLiabilitiesData).forEach((key) => {
      const assetsOrLiability = assetsAndLiabilitiesData[key];
      anLTableCdiAttributes.push({
        attributeName: assetsOrLiability.cdiName,
        attributeValue: assetsOrLiability.isCustom
          ? attributeValueForCustomItem(
              assetsOrLiability.label,
              assetsOrLiability.cdiValue
            )
          : assetsOrLiability.cdiValue,
      });
    });
    return anLTableCdiAttributes;
  }

  function isAnyCdiValueNull(anLTableCdiAttributes) {
    const checkNull = (element) => {
      return element.attributeValue === null;
    };

    return anLTableCdiAttributes.some(checkNull);
  }

  function getCdiNameForTable(isCdisNull) {
    return [
      {
        attributeName: CDI_NAME,
        attributeValue: !isCdisNull ? true : null,
      },
    ];
  }

  function handleSaveAnLTables() {
    let anLTableCdiAttributes = convertAnLDataToCdiArray();
    if (anLTableCdiAttributes.length > 0) {
      const isCdisNull = isAnyCdiValueNull(anLTableCdiAttributes);
      const anLTableAnsweredCdis = getCdiNameForTable(isCdisNull);
      anLTableCdiAttributes = [
        ...anLTableCdiAttributes,
        ...anLTableAnsweredCdis,
      ];
      updateCaseAttributes({
        updateAttributes: anLTableCdiAttributes,
        refetchAllQuestions: true,
      });
    }
  }

  function updateCaseAttributesCallback() {
    const anLDataArr = Object.keys(assetsAndLiabilitiesData).map((key) => {
      return { ...assetsAndLiabilitiesData[key], code: key };
    });
    if (!error) dispatch(resetUnSavedFlag(anLDataArr));
  }

  if (data && tableColumns)
    return (
      <Root>
        <div className={CDI_NAME && classes.headingRowStyle}>
          <GelBoxLayout alignment="start" wrap={false} space={[1, 1, 1]}>
            {tableColumns.map((tableColumn) => (
              <RenderTableColumn
                key={tableColumn.columnHeading}
                {...tableColumn}
                allCDIAttributeValues={data.attributes}
              />
            ))}
          </GelBoxLayout>
          {CDI_NAME && (
            <GelRowLayout distribution={"end"}>
              <GelBoxLayout gap="large" space="auto" distribution="end">
                <WarningMessage
                  infoType
                  message={INFO_MESSAGES.SAVE_ASSETS_LIABILITIES_DETAILS}
                ></WarningMessage>
                <GelButton
                  id="saveAnLTables"
                  name="saveAnLTables"
                  secondary
                  medium
                  onClick={handleSaveAnLTables}
                >
                  Save
                </GelButton>
              </GelBoxLayout>
            </GelRowLayout>
          )}
        </div>
      </Root>
    );

  return null;
}

AssetsLiabilitiesTable.propTypes = {
  question: propTypes.object.isRequired,
};

function RenderTableColumn({
  columnHeading,
  childBaseQuestions,
  totalBaseQuestion,
  linkedCaseDataAttributes,
  allCDIAttributeValues,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    const additionalCustomCdis = getAdditionalCustomCdis();
    const childBaseQuestionsCdis = mapBaseQuestionToCdiObj(childBaseQuestions);
    const { code, answerValue, customProperties, questionText } =
      totalBaseQuestion;
    const allCdis = [
      ...additionalCustomCdis,
      ...childBaseQuestionsCdis,
      ...[
        {
          cdiName: customProperties && customProperties.CDI_NAME,
          cdiValue: answerValue,
          unSaved: false,
          code,
          tableType: columnHeading,
          isReadOnly:
            customProperties &&
            customProperties.CUSTOM_TYPE === CUSTOM_QUES_TYPE.READONLY,
          isCustom: false,
          label: questionText,
        },
      ],
    ];
    dispatch(loadAnlTableData(allCdis));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function mapBaseQuestionToCdiObj(baseQuestions) {
    if (baseQuestions.length)
      return baseQuestions.map(
        ({ code, answerValue, customProperties, questionText }) => ({
          cdiName: customProperties && customProperties.CDI_NAME,
          cdiValue: answerValue,
          unSaved: false,
          code,
          tableType: columnHeading,
          isReadOnly: false,
          isCustom: false,
          label: questionText,
        })
      );

    return [];
  }

  function getAdditionalCustomCdis() {
    if (
      linkedCaseDataAttributes &&
      linkedCaseDataAttributes.length &&
      allCDIAttributeValues
    ) {
      return linkedCaseDataAttributes.flatMap((cdiName) => {
        try {
          const { attributeName, attributeValue } =
            allCDIAttributeValues.attributes.find(
              ({ attributeName }) => attributeName === cdiName
            );
          const parsedJson = jsonParser(attributeValue);
          return [
            {
              cdiName: attributeName,
              cdiValue:
                parsedJson.value !== null && parsedJson.value !== undefined
                  ? parsedJson.value
                  : null,
              unSaved: false,
              code: attributeName,
              tableType: columnHeading,
              isReadOnly: false,
              isCustom: true,
              label: parsedJson.label,
            },
          ];
        } catch {
          return [];
        }
      });
    }
    return [];
  }
  function calcAdditionalCustomItems() {
    if (
      linkedCaseDataAttributes &&
      linkedCaseDataAttributes.length &&
      allCDIAttributeValues
    ) {
      return linkedCaseDataAttributes.flatMap((cdiName) => {
        try {
          const { attributeValue } = allCDIAttributeValues.attributes.find(
            ({ attributeName }) => attributeName === cdiName
          );
          return [JSON.parse(attributeValue)];
        } catch {
          return [];
        }
      });
    }
    return [];
  }
  const additionalCustomItems = calcAdditionalCustomItems();

  return (
    <TableColumn
      columnHeading={columnHeading}
      childBaseQuestions={childBaseQuestions}
      maxAdditionalItemCount={5}
      totalBaseQuestion={totalBaseQuestion}
      linkedCaseDataAttributes={linkedCaseDataAttributes}
      initialAdditionalCustomItems={additionalCustomItems}
    />
  );
}
RenderTableColumn.propTypes = {
  columnHeading: propTypes.string.isRequired,
  childBaseQuestions: propTypes.arrayOf(propTypes.object).isRequired,
  totalBaseQuestion: propTypes.object.isRequired,
  linkedCaseDataAttributes: propTypes.arrayOf(propTypes.string),
  allCDIAttributeValues: propTypes.object,
};
