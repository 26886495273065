import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryAtrributes } from "../../../utils/useQueryAttributes";
import {
  AttributesQuery_ATTRIBUTE_TYPES,
  AttributesQuery_TYPE,
  CUSTOM_TABLE_TYPE,
} from "../../../utils/CONSTANTS";
import { setShareOfBusiness } from "../../../globalStore/income-slice";
import IncomeTable from "./IncomeTable";
import {
  convertIncomeDataToCdiArray,
  isAnyCdiValueNull,
  getCdiNameForTable,
} from "../../../utils/commonFunctions";
import { useUpdateCaseAttributes } from "../../../utils/useUpdateCaseAttributes";
import { useState } from "react";

function SelfIncomeExpensesTable({ question, time }) {
  const { data, variables, refetch } = useQueryAtrributes({
    type: AttributesQuery_TYPE.LIFE,
    attributeTypes: AttributesQuery_ATTRIBUTE_TYPES.CALCULATED,
  });
  const dispatch = useDispatch();
  const incomeData = useSelector((state) => state.incomeData);
  const shareOfBusiness = incomeData && incomeData["shareOfBusiness"];
  const [updateCdiRequired, setUpdateCdiRequired] = useState(false);
  const { customProperties } = question;
  const { CDI_NAME, TABLE_TYPE } = customProperties;

  useEffect(() => {
    // refetch calculated variables on every rerender to check if share of business value has changed.
    refetch(variables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  useEffect(() => {
    if (
      data &&
      data.attributes &&
      data.attributes.attributeValues &&
      data.attributes.attributeValues.SHARE_OF_BUSINESS
    ) {
      dispatch(
        setShareOfBusiness(+data.attributes.attributeValues.SHARE_OF_BUSINESS)
      );

      if (
        (CDI_NAME &&
          shareOfBusiness &&
          !data.attributes.attributeValues.SHARE_OF_BUSINESS) ||
        (shareOfBusiness &&
          +data.attributes.attributeValues.SHARE_OF_BUSINESS !==
            shareOfBusiness)
      ) {
        setUpdateCdiRequired(true);
      }
    }
  }, [data, dispatch]);

  if (typeof data !== "undefined") {
    return (
      <>
        <IncomeTable question={question} />

        {updateCdiRequired && shareOfBusiness && (
          <UpdateIncomeTableCdis tableType={TABLE_TYPE}></UpdateIncomeTableCdis>
        )}
      </>
    );
  }
  return null;
}

SelfIncomeExpensesTable.propTypes = {
  question: PropTypes.object.isRequired,
  time: PropTypes.number.isRequired,
};

export default SelfIncomeExpensesTable;

function UpdateIncomeTableCdis(tableType) {
  const incomeData = useSelector((state) => state.incomeData);
  const shareOfBusiness = incomeData && incomeData["shareOfBusiness"];
  const { updateCaseAttributes } = useUpdateCaseAttributes();

  useEffect(() => {
    //CUSTOM_TABLE_TYPE.SELF_INCOME_ADDBACK_ITEMS is used as combined name for employed and self employed tables
    if (incomeData && shareOfBusiness) {
      let incomeTableCdiAttributes = convertIncomeDataToCdiArray(
        incomeData,
        CUSTOM_TABLE_TYPE.SELF_INCOME_ADDBACK_ITEMS
      );
      if (incomeTableCdiAttributes.length > 0) {
        if (
          !isAnyCdiValueNull(
            incomeData,
            CUSTOM_TABLE_TYPE.SELF_INCOME_ADDBACK_ITEMS
          )
        ) {
          const incomeTableAnsweredCdis = getCdiNameForTable(
            CUSTOM_TABLE_TYPE.SELF_INCOME_ADDBACK_ITEMS
          );
          incomeTableCdiAttributes = [
            ...incomeTableCdiAttributes,
            ...incomeTableAnsweredCdis,
          ];
        }

        updateCaseAttributes({
          updateAttributes: incomeTableCdiAttributes,
          refetchAllQuestions: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareOfBusiness]);
  return null;
}
UpdateIncomeTableCdis.propTypes = {
  tableType: PropTypes.string,
};
