import { gql } from "@apollo/client";

export const GET_PAGE_STATUS_QUERY = gql`
  query PageStatusQuery($pageStatusInput: getPageStatusInput!) {
    pageStatus(getPageStatusInput: $pageStatusInput) {
      caseId
      pages {
        code
        text
        isPageComplete
      }
    }
  }
`;
export default GET_PAGE_STATUS_QUERY;
