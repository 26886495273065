import { gql } from "@apollo/client";
import { DISCLOSURE_FIELDS } from "../fragments/disclosureFields";

export const GET_DISCLOSURE_QUERY = gql`
  ${DISCLOSURE_FIELDS}
  query DisclosureQuery($getDisclosureInput: GetDisclosureInput!) {
    disclosure(getDisclosureInput: $getDisclosureInput) {
      ...DisclosureFields
    }
  }
`;

export default GET_DISCLOSURE_QUERY;
