import { useQuery } from "@apollo/client";
import { useCaseId } from "../globalStore/hooks";

import GET_ALL_QUESTIONS_QUERY from "../graphql/queries/getAllQuestions";

export function useQueryAllQuestions() {
  const caseId = useCaseId();

  const { data, error, loading, refetch, variables } = useQuery(
    GET_ALL_QUESTIONS_QUERY,
    {
      variables: {
        caseId,
      },
    }
  );
  let result;
  if (data) {
    result = { questions: data.questions[0] };
  }

  return { data: result, error, loading, refetch, variables };
}
