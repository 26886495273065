import propTypes from "prop-types";
import { GelSelect } from "@tal-gel/components";
import { FormField } from "./FormField";
import { ERROR_MESSAGES, SECTION_STATUSES } from "../../utils/CONSTANTS";
import { useState } from "react";
import { CaptionText } from "../CaptionText";
import { useSectionContext } from "../../contexts/SectionContext";

export function SelectionQuestionDropdown({
  code,
  answerValue,
  listItems,
  callbackFunctions,
  questionText,
  customProperties = {},
  withLabel = false,
  nullable = true,
}) {
  const [selectedValue, setSelectedValue] = useState(answerValue);
  const [invalidError, setInvalidError] = useState(false);
  const [keyValue, toggleKeyValue] = useState(false);
  const sectionContext = useSectionContext();

  function handleChange(e) {
    const { value } = e.target;
    setSelectedValue(value);
    if (
      callbackFunctions &&
      Array.isArray(callbackFunctions) &&
      callbackFunctions.length
    ) {
      callbackFunctions.forEach((callback) => {
        if (typeof callback === "function") {
          callback(e);
        }
      });
    }
  }

  function onBlur(e) {
    if (!nullable && !selectedValue && answerValue) {
      setInvalidError(true);
      setSelectedValue(answerValue);
      toggleKeyValue(!keyValue);
    } else {
      setInvalidError(false);
    }
  }
  const gelSelectElement = (
    <>
      <GelSelect
        width="fit-content"
        id={code}
        name={code}
        key={code + keyValue}
        options={listItems}
        value={selectedValue}
        placeholder="Please select"
        onChange={handleChange}
        onBlur={onBlur}
        required
        requiredErrorMsg={ERROR_MESSAGES.REACT_APP_ERR_MSG_REQ}
        error={!answerValue && sectionContext === SECTION_STATUSES.Incomplete}
      />
      {invalidError && (
        <CaptionText
          textMessage={ERROR_MESSAGES.INVALID_INPUT}
          errorType
        ></CaptionText>
      )}
    </>
  );

  if (withLabel)
    return (
      <FormField
        questionText={questionText}
        customProperties={customProperties}
      >
        {gelSelectElement}
      </FormField>
    );

  return gelSelectElement;
}

SelectionQuestionDropdown.propTypes = {
  code: propTypes.string.isRequired,
  answerValue: propTypes.string,
  listItems: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string.isRequired,
      value: propTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  callbackFunctions: propTypes.arrayOf(propTypes.func),
  questionText: propTypes.string,
  customProperties: propTypes.shape({
    CMS: propTypes.string,
  }),
  withLabel: propTypes.bool,
  nullable: propTypes.bool,
};
