import { gql } from "@apollo/client";
export const BASE_QUESTION_FIELDS = gql`
  fragment BaseQuestionFields on BaseQuestionsType {
    code
    category
    questionText
    questionType
    answerValue
    answerText
    answerType
    disclosureSource
    linkedAlias
    picklistQuestionMeta {
      picklistItems
    }
    caseDataQuestionMeta {
      dataType
      listName
      minimum
      maximum
      validationMessage
    }
    conditionalQuestionMeta {
      childQuestionCodes {
        caseId
        lifeInstance
        baseQuestionCode
        interviewCode
        __typename
      }
      requiresLinkedDisclosure
      requiredDisclosureMade
      __typename
    }
    parentConditionalQuestions {
      baseQuestionCode
    }
    visible
    properties {
      propertyName
      propertyValue
    }
    customProperties @client
    disclosures {
      aliasName
    }
    callbackFunctions @client
  }
`;
