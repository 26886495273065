import PropTypes from "prop-types";
import { GelParagraph } from "@tal-gel/components";
import { FormField } from "./FormField";

export function ReadOnlyQuestion({
  readonlyValue,
  questionText,
  prefix,
  suffix,
}) {
  const answerValue = readonlyValue && readonlyValue.toLocaleString();
  return (
    <FormField questionText={questionText}>
      <GelParagraph name={questionText}>
        {prefix} {answerValue} {suffix}
      </GelParagraph>
    </FormField>
  );
}

ReadOnlyQuestion.propTypes = {
  readonlyValue: PropTypes.any,
  questionText: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
};

// export default ReadOnlyQuestion;
