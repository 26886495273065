import { gql } from "@apollo/client";
import { CONFIGURABLES } from "../../utils/CONSTANTS";

export const GET_CONDITIONS_QUERY = gql`
  query ConditionsQuery(
    $caseId: ID!
    $category: ID!
    $searchText: ID!
    $limit: ID!
  ) {
    conditions(
      id: $caseId,
      category: $category
      searchText: $searchText
      limit: $limit
      version: "${CONFIGURABLES.REACT_APP_FACADE_VERSION}"
    ) {
      conditions
    }
  }
`;

export default GET_CONDITIONS_QUERY;
