import propTypes from "prop-types";
import { GelBoxLayout, GelLabel, GelHeading6 } from "@tal-gel/components";
import { useSelector } from "react-redux";

export function TotalRow({ totalBaseQuestion, value }) {
  const assetsAndLiabilitiesData = useSelector(
    (state) => state.assetsAndLiabilitiesData
  );
  const { customProperties, code, questionText } = totalBaseQuestion;
  const { CDI_NAME, PREFIX, SUFFIX } = customProperties;
  const answerValue = !CDI_NAME
    ? value
    : assetsAndLiabilitiesData[code] && assetsAndLiabilitiesData[code].cdiValue;

  return (
    <GelBoxLayout space={["auto", 1]}>
      <GelLabel>{questionText}</GelLabel>
      <GelHeading6>
        {PREFIX} {answerValue} {SUFFIX}
      </GelHeading6>
    </GelBoxLayout>
  );
}
TotalRow.propTypes = {
  totalBaseQuestion: propTypes.object,
  value: propTypes.string,
};
