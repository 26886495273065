import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const errorDataSlice = createSlice({
  name: "errorMessage",
  initialState,
  reducers: {
    addErrorMessage: (state, action) => {
      const index = state.findIndex(
        (data) => data.baseQuestionCode === action.payload.baseQuestionCode
      );

      if (index !== -1) {
        if (action.payload.serverError === false) {
          return [...state.slice(0, index), ...state.slice(index + 1)];
        } else {
          return [...state];
        }
      } else {
        if (action.payload.serverError) {
          return [...state, action.payload];
        } else {
          return [...state];
        }
      }
    },
  },
});

export const { addErrorMessage } = errorDataSlice.actions;

export default errorDataSlice.reducer;
