import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const sectionDataSlice = createSlice({
  name: "sectionData",
  initialState,
  reducers: {
    addSectionData: (state, action) => {
      const index = state.findIndex(
        (section) => action.payload.code === section.code
      );
      if (index !== -1) {
        return [
          ...state.slice(0, index),
          {
            ...state[index],
            status: action.payload.status,
            caseId: action.payload.caseId,
          },
          ...state.slice(index + 1),
        ];
      } else {
        return [...state, action.payload];
      }
    },
  },
});

export const { addSectionData } = sectionDataSlice.actions;

export default sectionDataSlice.reducer;
