import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  disableContinueButton: false,
  disableBackButton: false,
};

export const navigationButtonStatusSlice = createSlice({
  name: "navigationButtonStatus",
  initialState,
  reducers: {
    changeNavigationButtonStatus: (state, action) => {
      return {
        ...state,
        disableContinueButton: action.payload.disableContinueButton,
        disableBackButton: action.payload.disableBackButton,
      };
    },
  },
});

export const { changeNavigationButtonStatus } =
  navigationButtonStatusSlice.actions;

export default navigationButtonStatusSlice.reducer;
