import propTypes from "prop-types";
import { BaseQuestion } from "../BaseQuestion";
import {
  GelRowLayout,
  GelContainerLite,
  GelBoxLayout,
  GelScreenDetectorContext,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { QuestionContainer } from "../QuestionContainer";
import { LinkedQuestionContextProvider } from "../../contexts/LinkedQuestionContext";
import { useContext } from "react";

export function LinkedQuestions({ question }) {
  const { code, linkedQuestions, customProperties, answerValue } = question;

  const { HASLINKEDQUESTION } = customProperties;

  const contextValue = {
    hasLinkedQuestionCustomProp: HASLINKEDQUESTION,
    hasLinkedQuestions: linkedQuestions && linkedQuestions.length > 0,
  };
  const { screen, isXsScreen, isSmScreen, isMdScreen } = useContext(
    GelScreenDetectorContext
  );

  return (
    <LinkedQuestionContextProvider value={contextValue}>
      <GelContainerLite
        style={{
          width:
            isXsScreen(screen) || isSmScreen(screen) || isMdScreen(screen)
              ? "100%"
              : "85%",
        }}
        gutter={{ left: 30, top: 15, bottom: 15 }}
      >
        <QuestionContainer
          question={question}
          hasLinkedQuestions={true}
          key={`${code}-${answerValue}`}
        >
          {QuestionRow()}
        </QuestionContainer>
      </GelContainerLite>
    </LinkedQuestionContextProvider>
  );

  function QuestionRow() {
    return (
      <GelRowLayout>
        {!linkedQuestions ||
          (linkedQuestions && linkedQuestions.length === 0 && (
            <GelBoxLayout>
              <BaseQuestion baseQuestion={question} noChildQuestions={true} />
            </GelBoxLayout>
          ))}
        {linkedQuestions.map((linkedQuestion, index) => (
          <GelBoxLayout
            key={linkedQuestion.code}
            style={{
              border: "1px solid " + getGelTokens().global.themeColorGrayT20,
              borderLeft: "unset",
              borderRight: "unset",
              borderBottom:
                index + 1 === linkedQuestions.length
                  ? "1px solid " + getGelTokens().global.themeColorGrayT20
                  : "unset",
            }}
            gap="none"
          >
            <BaseQuestion baseQuestion={linkedQuestion} />
          </GelBoxLayout>
        ))}
      </GelRowLayout>
    );
  }
}

LinkedQuestions.propTypes = {
  question: propTypes.shape({
    code: propTypes.string.isRequired,
    answerValue: propTypes.string,
    answerType: propTypes.string,
    customProperties: propTypes.shape({
      CUSTOM_TYPE: propTypes.string,
      DEFAULT_VALUE: propTypes.string,
      CMS_WARN: propTypes.string,
      CMS_INFO: propTypes.string,
    }),
    caseDataQuestionMeta: propTypes.shape({
      dataType: propTypes.string.isRequired,
      listName: propTypes.string,
    }),
    linkedQuestions: propTypes.array,
  }).isRequired,
};
