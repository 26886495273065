import React from "react";
import PropTypes from "prop-types";
import { GelNumberInput } from "@tal-gel/components";
const WeightInput = React.memo(function WeightInputComponent({
  name,
  value,
  unit,
  onChange,
  onBlur,
  hasError = false,
  errorMessage = "",
  suffix,
}) {
  return (
    <GelNumberInput
      id={`${name}-${unit}`}
      name={`${name}-${unit}`}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      width={250}
      suffix={suffix}
      error={hasError}
      caption={hasError && errorMessage}
      decimals={2}
    />
  );
});

WeightInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  suffix: PropTypes.string,
};

export default WeightInput;
