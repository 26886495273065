import propTypes from "prop-types";

import { GelCaption } from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";

function colorClasses() {
  return {
    warningMessage: {
      color: getGelTokens().global.themeColorTextWarning,
    },
    infoMessage: {
      color: getGelTokens().brand.brandColorPrimary1,
    },
    errorMessage: {
      color: getGelTokens().global.themeColorDanger,
    },
    default: {},
  };
}
export function CaptionText({
  textMessage,
  infoType,
  warningType,
  errorType,
  marginTop = "small",
  marginBottom = "none",
}) {
  const classes = colorClasses();
  return (
    <GelCaption
      style={
        (classes.marginTop && warningType && classes.warningMessage) ||
        (infoType && classes.infoMessage) ||
        (errorType && classes.errorMessage) ||
        classes.default
      }
      condensed={errorType}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      {textMessage}
    </GelCaption>
  );
}

CaptionText.propTypes = {
  textMessage: propTypes.string,
  infoType: propTypes.bool,
  warningType: propTypes.bool,
  errorType: propTypes.bool,
  marginTop: propTypes.string,
  marginBottom: propTypes.string,
};
