export function getToken(id) {
  const tokenElement = document.getElementById(id);
  let token = null;
  if (tokenElement) {
    token = tokenElement.innerHTML;
  }
  return token;
}

export function getUreUiBlobUrl() {
  const ureUiBlobUrlElement = document.getElementById("ureUiBlobUrl");
  let url = null;
  if (ureUiBlobUrlElement) {
    url = ureUiBlobUrlElement.innerHTML;
  }
  return url;
}
