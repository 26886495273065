import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadConfigData } from "../globalStore/config-slice";
import { setGraphQlUri, setSubscriptionKey } from "../appConfig/configService";
import { getUreUiBlobUrl } from "../graphql/config/authService";

function useQueryConfig(url) {
  const [configLoading, setConfigLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchUrl() {
      const ureUiBlobUrl = getUreUiBlobUrl();
      let configUrl = ureUiBlobUrl + "/config.json";

      return fetch(configUrl)
        .then((result) => result.json())
        .then((configData) => {
          dispatch(loadConfigData(configData)); // loading the config data in state for use across application
          setGraphQlUri(configData.REACT_APP_GRAPHQL_API_URL); // using windows object as graphql client cannot access state variable via hooks
          setSubscriptionKey(configData.Ocp_Apim_Subscription_Key); // using windows object as graphql client cannot access state variable via hooks
          setConfigLoading(false);
        })
        .catch((e) => {
          console.log("Error reading config file: ", e);
        });
    }

    fetchUrl();
  }, [dispatch]);
  return [configLoading];
}
export { useQueryConfig };
