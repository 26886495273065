import { useMutation } from "@apollo/client";
import { useCaseId } from "../globalStore/hooks";
import { DELETE_DISCLOSURE_MUTATION } from "../graphql/mutations/deleteDisclosure";
import {
  evictDisclosure,
  getDisclosureByBQCodeFromCache,
} from "./commonFunctions";
import { CONFIGURABLES } from "../utils/CONSTANTS";

export function useDeleteDisclosure() {
  const caseId = useCaseId();
  const [deleteDisclosureMutation, { data, ...rest }] = useMutation(
    DELETE_DISCLOSURE_MUTATION
  );

  async function deleteDisclosure({ baseQuestionCode, aliasName }) {
    try {
      const disclosureFromCache = getDisclosureByBQCodeFromCache({
        baseQuestionCode,
        aliasName,
      });
      if (!disclosureFromCache || !disclosureFromCache.aliasName) {
        return "Disclosure does not exists";
      }
      const deleteDisclosureInput = {
        caseId,
        baseQuestionCode,
        aliasName,
        version: CONFIGURABLES.REACT_APP_FACADE_VERSION,
      };
      const result = await deleteDisclosureMutation({
        variables: {
          deleteDisclosureInput,
        },
      });

      const disclosure = result.data.deleteDisclosure[0];

      if (disclosure.succeeded) {
        evictDisclosure({ baseQuestionCode, aliasName });
      }
      return {
        data: { disclosure: result.data.deleteDisclosure[0] },
      };
    } catch (err) {
      console.log(err);
    }
  }
  let result;
  if (data) {
    const [disclosure] = data.deleteDisclosure;
    result = { disclosure };
  }
  return { deleteDisclosure, data: result, ...rest };
}
