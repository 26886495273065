import { useEffect, useState, useLayoutEffect, forwardRef } from "react";
import { useIsInViewport } from "../../../utils/useIsInViewport";
import propTypes from "prop-types";

const StickyComponent = forwardRef(function StickyComponent(props, ref) {
  const { children, onScrollCallback } = props;
  const [initialRefPosition, setInitialRefPosition] = useState(undefined);
  const isInViewport = useIsInViewport(ref);
  const [sticky, setSticky] = useState(false);
  const [maxWidthOfParent, setMaxWidthOfParent] = useState("unset");

  const componentStickyStyle = {
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 9999999,
    backgroundColor: "white",
    maxWidth: maxWidthOfParent,
  };
  const componentRelativeStyle = {
    position: "relative",
  };

  const onScroll = (e) => {
    const windowScrollTop = window.scrollY;
    setSticky(windowScrollTop > initialRefPosition);
    if (onScrollCallback) {
      onScrollCallback(initialRefPosition);
    }
  };

  const handleResize = (e) => {
    var parentWidth = ref.current?.parentNode?.clientWidth;
    setMaxWidthOfParent(parentWidth);
  };

  useEffect(() => {
    if (isInViewport) {
      var sectionHeaderContainerRefTop =
        ref.current.getBoundingClientRect().top;

      handleResize();
      setInitialRefPosition(sectionHeaderContainerRefTop);
    }
  }, [isInViewport]);

  useLayoutEffect(() => {
    if (!initialRefPosition) return;

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [initialRefPosition]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      ref={ref}
      style={sticky ? componentStickyStyle : componentRelativeStyle}
    >
      {children}
    </div>
  );
});

StickyComponent.propTypes = {
  children: propTypes.node.isRequired,
  onScrollCallback: propTypes.func,
};
export default StickyComponent;
