import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import CheckCircle from "@mui/icons-material/CheckCircle";
import ErrorOutlineRounded from "@mui/icons-material/ErrorOutlineRounded";
import CircleOutline from "@mui/icons-material/RadioButtonUncheckedOutlined";
import { getGelTokens } from "@tal-gel/theming";

const PREFIX = "TalStepIcon";

const classes = {
  root: `${PREFIX}-root`,
  active: `${PREFIX}-active`,
  circle: `${PREFIX}-circle`,
  completed: `${PREFIX}-completed`,
  error: `${PREFIX}-error`,
};

const Root = styled("div")(() => {
  return {
    [`&.${classes.root}`]: {
      color: getGelTokens().global.themeColorGrayT20,
      display: "flex",
      height: 22,
      alignItems: "center",
    },
    [`&.${classes.active}`]: {
      color: getGelTokens().global.themeColorInteractive1,
    },
    [`& .${classes.circle}`]: {
      color: "currentColor",
      fontSize: 31,
    },
    [`& .${classes.completed}`]: {
      color: getGelTokens().global.themeColorInteractive1,
      zIndex: 1,
      fontSize: 31,
    },
    [`& .${classes.error}`]: {
      color: getGelTokens().global.themeColorDanger,
      zIndex: 1,
      fontSize: 31,
    },
  };
});

// const useTalStepIconStyles = makeStyles(() => {
//   return {
//     [`&.${classes.root}`]: {
//       color: getGelTokens().global.themeColorGrayT20,
//       display: "flex",
//       height: 22,
//       alignItems: "center",
//     },
//     [`&.${classes.active}`]: {
//       color: getGelTokens().global.themeColorInteractive1,
//     },
//     [`& .${classes.circle}`]: {
//       color: "currentColor",
//       fontSize: 31,
//     },
//     [`& .${classes.completed}`]: {
//       color: getGelTokens().global.themeColorInteractive1,
//       zIndex: 1,
//       fontSize: 31,
//     },
//     [`& .${classes.error}`]: {
//       color: getGelTokens().global.themeColorDanger,
//       zIndex: 1,
//       fontSize: 31,
//     },
//   };
// });

export default function TalStepIcon(props) {
  // const classes = useTalStepIconStyles();
  const { active, completed, error } = props;

  return (
    <Root
      className={clsx(classes.root, {
        [classes.active]: active, // && !error,
        // [classes.activeError]: active && error,
      })}
    >
      {completed ? (
        <CheckCircle className={classes.completed} />
      ) : error ? (
        <ErrorOutlineRounded className={classes.error} />
      ) : (
        <CircleOutline className={classes.circle} />
      )}
    </Root>
  );
}

TalStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * Mark the step as with error. Is passed to child components.
   */
  error: PropTypes.bool,
};
