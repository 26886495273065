import propTypes from "prop-types";
import { useState } from "react";
import { GelTextInput, GelTextArea, GelCaption } from "@tal-gel/components";
import { FormField } from "./FormField";
// import { makeStyles } from "@mui/material/styles";
import { ERROR_MESSAGES, SECTION_STATUSES } from "../../utils/CONSTANTS";
import { CaptionText } from "../CaptionText";
import { useSectionContext } from "../../contexts/SectionContext";

const useStyles = {
  textAreaStyle: {
    resize: "none",
  },
};

export function TextQuestion({
  question,
  maxCharacters = 100,
  noteField,
  withLabel = false,
  validateControl = false,
  nullable = true,
  isTextRequired = true,
}) {
  const {
    code,
    answerValue,
    caseDataQuestionMeta,
    callbackFunctions,
    questionText,
    customProperties = {},
  } = question;

  const { minimum = 0, maximum } = caseDataQuestionMeta || {};

  const maxLength = maximum || maxCharacters;
  const [textValue, setTextValue] = useState(answerValue || "");
  const [charactersLeft, setCharactersLeft] = useState(
    maxLength - textValue.length
  );
  const [invalidError, setInvalidError] = useState(false);
  const [keyValue, toggleKeyValue] = useState(false);
  const sectionContext = useSectionContext();

  function onBlur(e) {
    if (!nullable && !textValue && answerValue) {
      setInvalidError(true);
      setTextValue(answerValue);
      toggleKeyValue(!keyValue);
    } else {
      setInvalidError(false);
      if (
        (textValue.length > minimum || textValue.length <= maxLength) &&
        callbackFunctions &&
        Array.isArray(callbackFunctions) &&
        callbackFunctions.length
      ) {
        callbackFunctions.forEach((callback) => {
          if (typeof callback === "function") {
            callback(e);
          }
        });
      }
    }
  }

  function handleChange(e) {
    const { value } = e.target;
    if (value.length <= maxLength) {
      setTextValue(value);
      setCharactersLeft(maxLength - value.length);
    }
  }

  function displayCaptionText() {
    return (
      <CaptionText
        textMessage={ERROR_MESSAGES.INVALID_INPUT}
        errorType
      ></CaptionText>
    );
  }
  const textBoxProps = {
    id: code,
    name: code,
    value: textValue,
    placeholder: "Text input",
    onBlur,
    required: isTextRequired,
    requiredErrorMsg: ERROR_MESSAGES.REACT_APP_ERR_MSG_REQ,
    onChange: handleChange,
    error:
      !textValue &&
      (sectionContext === SECTION_STATUSES.Incomplete || validateControl),
  };

  if (noteField) {
    if (withLabel)
      return (
        <FormField
          questionText={questionText}
          customProperties={customProperties}
        >
          <GelTextArea
            {...textBoxProps}
            rows={3}
            width="80%"
            style={useStyles.textAreaStyle}
            key={code + keyValue}
          />
          <GelCaption condensed marginTop="small">
            {charactersLeft} characters left.
          </GelCaption>
          {invalidError && displayCaptionText()}
        </FormField>
      );

    return (
      <>
        <GelTextArea
          {...textBoxProps}
          rows={3}
          widht="80%"
          style={useStyles.textAreaStyle}
          key={code + keyValue}
        />
        <GelCaption condensed marginTop="small">
          {charactersLeft} characters left.
        </GelCaption>
        {invalidError && displayCaptionText()}
      </>
    );
  }

  if (withLabel)
    return (
      <FormField
        questionText={questionText}
        customProperties={customProperties}
      >
        <>
          <GelTextInput
            {...textBoxProps}
            style={{ maxWidth: 340 }}
            key={code + keyValue}
          />
          {invalidError && displayCaptionText()}
        </>
      </FormField>
    );

  return (
    <>
      <GelTextInput
        {...textBoxProps}
        style={{ maxWidth: 340 }}
        key={code + keyValue}
      />
      {invalidError && displayCaptionText()}
    </>
  );
}

TextQuestion.propTypes = {
  question: propTypes.shape({
    code: propTypes.string.isRequired,
    answerValue: propTypes.string,
    caseDataQuestionMeta: propTypes.shape({
      minimum: propTypes.number,
      maximum: propTypes.number,
      validationMessage: propTypes.string,
    }),
    callbackFunctions: propTypes.arrayOf(propTypes.func),
    questionText: propTypes.string,
    customProperties: propTypes.shape({
      CMS: propTypes.string,
    }),
  }).isRequired,
  maxCharacters: propTypes.number.isRequired,
  noteField: propTypes.bool,
  withLabel: propTypes.bool,
  validateControl: propTypes.bool,
  nullable: propTypes.bool,
  isTextRequired: propTypes.bool,
};
