import React from "react";
import { createRoot } from "react-dom/client";
import { GelThemeProvider } from "@tal-gel/theming";
import { GelScreenDetector } from "@tal-gel/components";
import { Provider } from "react-redux";

import "./index.css";
import "./setupTalGel";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GraphQLAPIProvider from "./graphql/config/GraphQLAPIProvider";
import { store } from "./globalStore/store";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <GraphQLAPIProvider>
      <Provider store={store}>
        <GelThemeProvider>
          <GelScreenDetector>
            <App />
          </GelScreenDetector>
        </GelThemeProvider>
      </Provider>
    </GraphQLAPIProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
