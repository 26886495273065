import propTypes from "prop-types";
import { useEffect, useState } from "react";
import { useQueryDisclosure } from "../../utils/useQueryDisclosure";
import { ReflexiveQuestionContainer } from "../ReflexiveQuestionContainer";
import { getBaseQuestionDataFromCache } from "../../utils/commonFunctions";
import {
  addDisclosureToBaseQuestion,
  evictDisclosure,
} from "../../utils/commonFunctions";
import { useLinkedQuestionContext } from "../../contexts/LinkedQuestionContext";
import { DISCLOSURE_SOURCES } from "../../utils/CONSTANTS";
import { GelContainerLite } from "@tal-gel/components";
import { tabularClasses } from "../QuestionInput/tabularClasses";

export function Disclosure({ baseQuestionCode, aliasName, disclosure }) {
  const { data /*,loading, error*/ } = useQueryDisclosure({
    baseQuestionCode,
    aliasName,
  });
  const [prevTriggeredCondAlias, setPrevTriggeredCondAlias] = useState(null);

  useEffect(() => {
    const { triggeredCondAlias } = (data && data.disclosure) || {};

    if (prevTriggeredCondAlias && !triggeredCondAlias) {
      // remove disclosure
      evictDisclosure({
        baseQuestionCode,
        aliasName: prevTriggeredCondAlias,
      });
    }
    if (!prevTriggeredCondAlias && triggeredCondAlias) {
      // add alias
      addDisclosureToBaseQuestion({
        baseQuestionCode,
        aliasName: triggeredCondAlias,
      });
    }
    if (prevTriggeredCondAlias !== triggeredCondAlias) {
      setPrevTriggeredCondAlias(triggeredCondAlias);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    // delete disclosure it it was removed from base question
    return () => {
      const baseQ = getBaseQuestionDataFromCache({
        baseQuestionCode,
      });
      if (
        baseQ &&
        baseQ.disclosures &&
        baseQ.disclosures.findIndex(
          ({ aliasName: attachedDisclosure }) =>
            aliasName === attachedDisclosure
        ) === -1
      ) {
        evictDisclosure({
          baseQuestionCode,
          aliasName,
        });
      }
    };
  }, [aliasName, baseQuestionCode]);

  const { reflexiveQuestions } = disclosure || (data && data.disclosure) || {};

  function createReflexiveQuestions() {
    let answers = [];
    let allAnswers = [];
    reflexiveQuestions.forEach(function (reflexiveQuestion, index) {
      const reflexiveQuestionCode = `${baseQuestionCode}-${aliasName}-${index}`;
      if (reflexiveQuestion.answerValue) {
        allAnswers.push({
          code: reflexiveQuestionCode,
          answer: reflexiveQuestion.answerValue,
          questionText: reflexiveQuestion.questionText,
        });
      }
    });
    return reflexiveQuestions.map((reflexiveQuestion, index) => {
      const reflexiveQuestionCode = `${baseQuestionCode}-${aliasName}-${index}`;
      const prevAnswers = [...answers];
      if (reflexiveQuestion.answerValue) {
        answers.push({
          code: reflexiveQuestionCode,
          answer: reflexiveQuestion.answerValue,
        });
      }
      return (
        <ReflexiveQuestionContainer
          key={`${reflexiveQuestionCode}-${reflexiveQuestion.answerValue}`}
          reflexiveQuestion={{
            ...reflexiveQuestion,
            code: reflexiveQuestionCode,
            prevAnswers,
            baseQuestionCode,
            aliasName,
            allAnswers,
          }}
        />
      );
    });
  }

  function ReflexiveQuestionsWithTabularFormat() {
    const { disclosureSource } = getBaseQuestionDataFromCache({
      baseQuestionCode,
    });
    const linkedQuestionContext = useLinkedQuestionContext();
    const classes = tabularClasses();
    if (
      linkedQuestionContext &&
      linkedQuestionContext.hasLinkedQuestionCustomProp &&
      disclosureSource &&
      disclosureSource === DISCLOSURE_SOURCES.SPECIFIED
    ) {
      return (
        <GelContainerLite
          gutter={{ left: 0, right: 0, top: 20, bottom: 15 }}
          style={classes.tabularDisclosure}
        >
          {createReflexiveQuestions()}
        </GelContainerLite>
      );
    } else {
      return <>{createReflexiveQuestions()}</>;
    }
  }
  if (
    reflexiveQuestions &&
    Array.isArray(reflexiveQuestions) &&
    reflexiveQuestions.length > 0
  )
    return <ReflexiveQuestionsWithTabularFormat />;

  return null;
}

Disclosure.propTypes = {
  baseQuestionCode: propTypes.string.isRequired,
  aliasName: propTypes.string.isRequired,
  disclosure: propTypes.shape({
    reflexiveQuestions: propTypes.arrayOf(propTypes.any).isRequired,
  }),
};
