import propTypes from "prop-types";

import { GelModal, GelBoxLayout, GelButton } from "@tal-gel/components";

export function ConfirmDialog({ title, message, open, yesAction, noAction }) {
  function handleYes() {
    if (typeof yesAction === "function") {
      yesAction();
    }
  }
  function handleNo() {
    if (typeof noAction === "function") {
      noAction();
    }
  }
  return (
    <GelModal
      width={600}
      open={open}
      onClose={handleNo}
      title={title}
      action={
        <GelBoxLayout gap="small" space="auto">
          <GelButton
            id="confirmDialogYesBtn"
            secondary
            medium
            width={90}
            onClick={handleYes}
          >
            Yes
          </GelButton>
          <GelButton
            id="confirmDialogNoBtn"
            primary
            medium
            width={90}
            onClick={handleNo}
          >
            No
          </GelButton>
        </GelBoxLayout>
      }
    >
      {message}
    </GelModal>
  );
}
ConfirmDialog.propTypes = {
  title: propTypes.node,
  message: propTypes.node.isRequired,
  yesAction: propTypes.func.isRequired,
  noAction: propTypes.func.isRequired,
  open: propTypes.bool.isRequired,
};
