import propTypes from "prop-types";

import { QuestionContainer } from "./QuestionContainer";
import { QuestionInputContainer } from "./QuestionInput/QuestionInputContainer";
import { LinkedQuestions } from "./CustomTypes/LinkedQuestions";

export function BaseQuestion({
  baseQuestion,
  answerBaseQuestionRequired = true,
  noChildQuestions = false,
}) {
  const { customProperties = {}, code } = baseQuestion;

  const { HASLINKEDQUESTION } = customProperties;

  if (HASLINKEDQUESTION && !noChildQuestions) {
    return <LinkedQuestions question={baseQuestion} />;
  }

  return (
    <>
      <QuestionContainer question={baseQuestion}>
        <QuestionInputContainer
          question={baseQuestion}
          name={code}
          withLabel
          answerBaseQuestionRequired={answerBaseQuestionRequired}
        />
      </QuestionContainer>
    </>
  );
}
BaseQuestion.propTypes = {
  baseQuestion: propTypes.shape({
    code: propTypes.string.isRequired,
    questionText: propTypes.string.isRequired,
    questionType: propTypes.string.isRequired,
    caseDataQuestionMeta: propTypes.shape({
      dataType: propTypes.string.isRequired,
    }),
    customProperties: propTypes.shape({
      CMS: propTypes.string,
      DO_NOT_DISPLAY: propTypes.string,
    }),
    disclosures: propTypes.arrayOf(
      propTypes.shape({
        aliasName: propTypes.string.isRequired,
      })
    ),
    disclosureSource: propTypes.string,
  }),
  answerBaseQuestionRequired: propTypes.bool,
  noChildQuestions: propTypes.bool,
};
