import { useEffect } from "react";
import { useHintTextList } from "./useHintTextList";
import { useQueryListByName } from "./useQueryListByName";
import { LIST_NAMES } from "../utils/CONSTANTS";

export function useQueryHintTextList() {
  const { hintTextList, setHintTextList } = useHintTextList();
  const { data, loading, ...rest } = useQueryListByName(
    LIST_NAMES.REACT_APP_HINT_TEXT_LIST_NAME
  );
  useEffect(() => {
    if (!hintTextList && loading === false) {
      setHintTextList({ data });
    }
  }, [data, loading, hintTextList, setHintTextList]);
  return { hintTextList, loading, ...rest };
}
