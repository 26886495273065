import { useMutation } from "@apollo/client";
import { useDebouncedCallback } from "use-debounce";
import { useCaseId } from "../globalStore/hooks";
import { CONFIGURABLES } from "../utils/CONSTANTS";
import { ANSWER_BASE_QUESTION_MUTATION_NO_REFRESH } from "../graphql/mutations/answerBaseQuestion";

export function useAnswerBaseQuestionNoRefresh(wait = 0) {
  const caseId = useCaseId();
  const [updateAnswerForBaseQuestion, { data, loading, error }] = useMutation(
    ANSWER_BASE_QUESTION_MUTATION_NO_REFRESH
  );

  const answerBaseQuestionNoRefresh = useDebouncedCallback(
    ({ baseQuestionCode, answer, baseQuestionProperties = null }) => {
      if (baseQuestionCode && answer !== "") {
        return updateAnswerForBaseQuestion({
          variables: {
            answerBaseQuestionInput: {
              caseId,
              baseQuestionCode,
              answer: answer.toString(),
              version: CONFIGURABLES.REACT_APP_FACADE_VERSION,
              baseQuestionProperties: baseQuestionProperties,
            },
          },
        });
      }
    },
    wait
  );

  return { answerBaseQuestionNoRefresh, data, loading, error };
}
