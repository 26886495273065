import propTypes from "prop-types";
import { useState, useEffect } from "react";
import {
  GelRowLayout,
  GelBoxLayout,
  GelTextInput,
  GelFormField,
  GelButton,
  GelContainerLite,
} from "@tal-gel/components";

export function AddCustomItemForm({ id, maxLength = 50, addCustomItem }) {
  const [additionalItemName, setAdditionalItemName] = useState("");
  const [charsLeftCount, setCharsLeftCount] = useState(50);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setCharsLeftCount(maxLength - additionalItemName.length);
  }, [additionalItemName, maxLength]);

  function handleChange(e) {
    const { value } = e.target;

    if (value.length <= maxLength) {
      if (errorMessage) setErrorMessage("");
      setAdditionalItemName(value);
    }
  }

  function handleConfirm() {
    if (!addCustomItem(additionalItemName)) {
      return setErrorMessage(
        `Can not add duplicate item ${additionalItemName}`
      );
    }
    setAdditionalItemName("");
  }
  return (
    <GelFormField label="Additional custom item">
      <GelContainerLite gutter="none">
        <GelRowLayout gutter="small">
          <GelBoxLayout alignment="start">
            <GelTextInput
              id={id}
              name={id}
              value={additionalItemName}
              placeholder="item name"
              error={!!errorMessage}
              caption={errorMessage || `${charsLeftCount} characters left.`}
              onChange={handleChange}
              style={{ maxWidth: 200 }}
            />
            <GelButton
              tertiary
              medium
              onClick={handleConfirm}
              disabled={!additionalItemName || !!errorMessage}
            >
              Confirm
            </GelButton>
          </GelBoxLayout>
        </GelRowLayout>
      </GelContainerLite>
    </GelFormField>
  );
}

AddCustomItemForm.propTypes = {
  id: propTypes.string.isRequired,
  maxLength: propTypes.number.isRequired,
  addCustomItem: propTypes.func.isRequired,
};
