import propTypes from "prop-types";
import { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { resetStore, setHandoverData } from "../../globalStore/shared-actions";
import { setCurrentUserRole } from "../../globalStore/currentUserRole-slice";
import {
  GelHeading2,
  GelRowLayout,
  GelBoxLayout,
  GelFormField,
  GelTextInput,
  GelForm,
  GelButton,
  useGelFormData,
  GelRadioGroup,
  GelRadio,
  GelParagraph,
} from "@tal-gel/components";
import { useEffect } from "react";

export function CaseSearchGel({
  caseId = "",
  currentUserRole = "adviser",
  applicationStatus = "InProgress",
}) {
  const dispatch = useDispatch();
  const { formData, onFormDataChange } = useGelFormData({
    caseId,
    currentUserRole,
    applicationStatus,
  });
  const [lockUserRoleSelection, setLockUserRoleSelection] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    console.log("useEffect");
    dispatch(setCurrentUserRole({ currentUserRole: formData.currentUserRole }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.currentUserRole]);

  async function onSubmit() {
    console.log("submit");
    dispatch(resetStore()) // reset store
      .then(unwrapResult) // unwrap result of reset store
      .then(() => {
        dispatch(setHandoverData(formData));
      }) // if reset store was successful set new case id and current user role
      .catch((e) => {
        console.log("consoleSetError:");
        setError(true);
      });

    setLockUserRoleSelection(true);
  }

  return (
    <GelForm
      data-testid="case-search-gel-form"
      labelAtTop
      onSubmit={onSubmit}
      parseResponseBody={false}
      preventSubmitOnInvalid
    >
      <GelRowLayout gutter="small">
        <GelHeading2>Case Details Search</GelHeading2>
        <GelBoxLayout distribution="start" alignment="start" space="auto">
          <GelFormField
            valueField
            label="Case Id"
            tooltip="Tooltip for the Case Id field"
          >
            <GelTextInput
              name="caseId"
              data-testid="caseId-input"
              required
              requiredErrorMsg="caseId is required"
              focusOnMount
              placeholder="Input Case Id"
              caption="Case Id is a text value used to identify cases uniquely."
              value={formData.caseId}
              onChange={onFormDataChange}
              width={400}
            />
          </GelFormField>
          <GelFormField valueField label="Simulate User Role">
            <GelRadioGroup
              buttonGroup
              value={formData.currentUserRole}
              onChange={onFormDataChange}
              name="currentUserRole"
              width={75}
              disabled={lockUserRoleSelection}
              caption="To change User Role selection after submit, refresh the screen"
            >
              <GelRadio value="adviser">adviser</GelRadio>
              <GelRadio value="staff">staff</GelRadio>
            </GelRadioGroup>
          </GelFormField>
        </GelBoxLayout>
        <GelFormField>
          <GelButton
            name="submit-caseid"
            data-testid="submit"
            primary
            medium
            submit
          >
            Submit
          </GelButton>
        </GelFormField>
        {error && <GelParagraph>Unable to fetch case details.</GelParagraph>}
      </GelRowLayout>
    </GelForm>
  );
}

CaseSearchGel.propTypes = {
  caseId: propTypes.string,
  currentUserRole: propTypes.string,
  applicationStatus: propTypes.string,
};
