import { useQuery } from "@apollo/client";
import { useCaseId } from "../globalStore/hooks";

import LIST_BY_NAME_QUERY from "../graphql/queries/getListByName";

export function useQueryListByName(listName) {
  const caseId = useCaseId();
  return useQuery(LIST_BY_NAME_QUERY, {
    variables: {
      caseId,
      listName,
    },
  });
}
