import propTypes from "prop-types";
import { BaseQuestion } from "../../BaseQuestion";
import { useSelector } from "react-redux";
import { CaptionText } from "../../CaptionText";
import { INFO_MESSAGES } from "../../../utils/CONSTANTS";
import { GelRowLayout } from "@tal-gel/components";

export function BaseQuestionItemRows({ baseQuestions, addValue }) {
  const assetsAndLiabilitiesData = useSelector(
    (state) => state.assetsAndLiabilitiesData
  );

  function getAnswerValue(question) {
    return (
      assetsAndLiabilitiesData[question.code]?.cdiValue?.toString() || null
    );
  }

  if (baseQuestions && baseQuestions.length)
    return baseQuestions.map((question) => {
      const { customProperties } = question;
      const { CDI_NAME } = customProperties;
      return (
        <GelRowLayout key={`${question.code}`} gutter="small">
          <GelRowLayout gutter="small">
            <BaseQuestion
              baseQuestion={{
                ...question,
                answerValue:
                  (CDI_NAME && getAnswerValue(question)) ||
                  question.answerValue,
                callbackFunctions: [...question.callbackFunctions, addValue],
              }}
              answerBaseQuestionRequired={!CDI_NAME}
            />
            {CDI_NAME && getAnswerValue(question) !== question.answerValue && (
              <CaptionText
                errorType
                textMessage={INFO_MESSAGES.UNSAVED_WARNING_MESSAGE}
                marginTop="none"
                marginBottom="none"
              ></CaptionText>
            )}
          </GelRowLayout>
        </GelRowLayout>
      );
    });

  return null;
}
BaseQuestionItemRows.propTypes = {
  baseQuestions: propTypes.arrayOf(propTypes.object),
  addValue: propTypes.func,
};
