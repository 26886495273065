import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  GelContainerLite,
  GelRowLayout,
  GelBoxLayout,
  GelButton,
} from "@tal-gel/components";
import HeadingRow from "../CustomTablesShared/Heading";
import {
  AttributesQuery_ATTRIBUTE_TYPES,
  AttributesQuery_TYPE,
  BOOLEAN_STRING,
  ERROR_MESSAGES,
  CONFIGURABLES,
  INFO_MESSAGES,
  CDI_NAMES,
} from "../../../utils/CONSTANTS";
import { useUpdateCaseAttributes } from "../../../utils/useUpdateCaseAttributes";
import { useQueryAtrributes } from "../../../utils/useQueryAttributes";
import { useAnswerBaseQuestion } from "../../../utils/useAnswerBaseQuestion";
import EmploymentHistoryRow from "./EmploymentHistoryRow";
import { WarningMessage } from "../../WarningMessage";
import { useDispatch } from "react-redux";
import {
  setEmpTableRowValues,
  loadEmpHistoryTableData,
  resetEmpHistoryTableData,
} from "../../../globalStore/empTableRowValues-slice";
import { useEmpTableRow, useCaseId } from "../../../globalStore/hooks";
import { getGelTokens } from "@tal-gel/theming";
import { styled } from "@mui/material/styles";
import { getBaseQuestionDataFromCache } from "../../../utils/commonFunctions";

const PREFIX = "EmploymentHistoryTableContainer";

const classes = {
  mainRowStyle: `${PREFIX}-mainRowStyle`,
};

const Root = styled("div")(() => ({
  [`& .${classes.mainRowStyle}`]: {
    lineHeight: "3.5",
    border: "1px solid " + getGelTokens().global.themeColorGrayT20,
  },
}));

function EmploymentHistoryTableContainer({ question }) {
  const { data } = useQueryAtrributes({
    type: AttributesQuery_TYPE.LIFE,
    attributeTypes: AttributesQuery_ATTRIBUTE_TYPES.WRITABLE,
  });

  useEffect(() => {}, []);
  if (data && data.attributes && data.attributes.attributeValues) {
    return (
      <EmploymentHistoryTable
        question={question}
        lifeAttributeValues={data.attributes.attributeValues}
      />
    );
  }
  return null;
}

EmploymentHistoryTableContainer.propTypes = {
  question: PropTypes.shape({
    code: PropTypes.string.isRequired,
    answerValue: PropTypes.string,
  }),
};

export default EmploymentHistoryTableContainer;

function EmploymentHistoryTable({ question, lifeAttributeValues }) {
  const { customProperties, code, answerValue, properties } = question;
  const { COLUMNS, CDI_NAME } = customProperties;
  const columnCount = +COLUMNS;
  const rowCount = +CONFIGURABLES.REACT_APP_EMP_HISTORY_MAX_ROWS || 4;
  const [headingRow, setHeadingRow] = useState([]);
  const [cdiMapping, setCdiMapping] = useState([]);
  const [tableBody, setTableBody] = useState([]);
  const [visibleRowsCount, setVisibleRowsCount] = useState(0);
  const { updateCaseAttributes, error: updateCaseAttributesError } =
    useUpdateCaseAttributes(updateCaseAttributesCallback);
  const { answerBaseQuestion, error: updateBaseQuestionError } =
    useAnswerBaseQuestion(answerValue);
  const [isTableRowComplete, setIsTableRowComplete] = useState(false);
  const [isAddOccEnable, setIsAddOccEnable] = useState(false);

  const dispatch = useDispatch();
  const empTableRow = useEmpTableRow();
  const caseId = useCaseId();

  function updateCaseAttributesCallback() {
    const cachedBQ = getBaseQuestionDataFromCache({
      baseQuestionCode: code,
    });
    if (
      !updateCaseAttributesError &&
      cachedBQ &&
      cachedBQ.visible === false &&
      CDI_NAME
    ) {
      dispatch(resetEmpHistoryTableData());
    }
  }
  function cleanUp() {
    const cachedBQ = getBaseQuestionDataFromCache({
      baseQuestionCode: code,
    });

    if (cachedBQ && cachedBQ.visible === false && CDI_NAME) {
      const attributeValue = null;
      const updateAttributes = properties.flatMap(
        ({ propertyName, propertyValue }) => {
          const tempProperties = propertyName.match(/COLUMN_CUSTOM/);
          return tempProperties && tempProperties.length
            ? [{ attributeName: propertyValue, attributeValue }]
            : [];
        }
      );
      if (updateAttributes && updateAttributes.length > 0) {
        if (
          CDI_NAME ===
          CDI_NAMES.EMPLOYMENT_HISTORY_PAGE.EMPLOYMENT_HISTORY_TABLE
        ) {
          updateAttributes.push({
            attributeName:
              CDI_NAMES.EMPLOYMENT_HISTORY_PAGE.EMPLOYMENT_HISTORY_TABLE,
            attributeValue: null,
          });
        }
        updateCaseAttributes({
          type: AttributesQuery_TYPE.LIFE,
          updateAttributes: updateAttributes,
          refetchAllQuestions: true,
        });
      }
    }
  }

  function isFirstRowComplete(tableBody) {
    return isTableComplete(tableBody, 0);
  }

  useEffect(() => {
    if (customProperties) {
      // building heading row
      var tempEmpTableRow =
        empTableRow &&
        empTableRow.length &&
        empTableRow.filter((o) => o.caseId === caseId);
      dispatch(
        loadEmpHistoryTableData({
          updateAttributes: tempEmpTableRow,
        })
      );

      setIsTableRowComplete(
        tempEmpTableRow === 0 || tempEmpTableRow.length === 0
      );
      const headingRowTemp = [];
      for (let i = 1; i <= columnCount; i++) {
        if (customProperties[`COLUMN_HEADING_${i}`]) {
          headingRowTemp.push({
            label: customProperties[`COLUMN_HEADING_${i}`],
          });
        }
      }
      const cdiMappingTemp = [];
      const tableBodyTemp = [];
      let isTableBodyExist = false;
      let visibleRowsCountTemp = 0;
      let updatedEmpTableState = [];
      for (let rowIndex = 1; rowIndex <= rowCount; rowIndex++) {
        let visible = rowIndex === 1;
        let cdiRow = [];
        let rowData = [];
        let newCdivalue = "";
        for (let colIndex = 1; colIndex <= columnCount; colIndex++) {
          const cdiName =
            customProperties[`COLUMN_CUSTOM_${colIndex}_CDI_${rowIndex}`];
          cdiRow.push(cdiName);
          const cdiValue = lifeAttributeValues[cdiName];
          if (rowIndex === 1 && cdiValue) {
            isTableBodyExist = true;
          }
          for (let i = 0; i < tempEmpTableRow.length; i++) {
            if (tempEmpTableRow[i].attributeName === cdiName) {
              newCdivalue = tempEmpTableRow[i].attributeValue;
            }
          }
          if (newCdivalue || cdiValue) {
            updatedEmpTableState.push({
              attributeName: cdiName,
              attributeValue: newCdivalue || cdiValue,
              caseId: caseId,
            });
          }
          visible = visible || !!cdiValue || !!newCdivalue;
          rowData.push(newCdivalue || cdiValue || "");
          newCdivalue = "";
        }
        cdiMappingTemp.push(cdiRow);
        if (visible) {
          visibleRowsCountTemp++;
        }
        tableBodyTemp.push({ visible, rowData });
      }
      setHeadingRow(headingRowTemp);
      setCdiMapping(cdiMappingTemp);
      setTableBody(tableBodyTemp);
      setVisibleRowsCount(visibleRowsCountTemp);
      dispatch(
        setEmpTableRowValues({
          Type: "UpdateState",
          updateAttributes: updatedEmpTableState,
        })
      );
      if (isTableComplete(tableBodyTemp, visibleRowsCount - 1)) {
        setIsTableRowComplete(true);
        setIsAddOccEnable(true);
      }
      if (isTableBodyExist) {
        if (visibleRowsCount > 0) {
          const firstRowComplete = isFirstRowComplete(tableBodyTemp);
          if (!firstRowComplete) {
            setIsTableRowComplete(false);
          } else {
            setIsTableRowComplete(true);
            updateBaseQuestion(BOOLEAN_STRING.true);
          }
        }
      }
    }
    return () => {
      cleanUp();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lifeAttributeValues]);

  function handleAddOccupation() {
    if (visibleRowsCount < rowCount) {
      const tableBodyTemp = tableBody.map((tableRow, index) => ({
        ...tableRow,
        ...((index === visibleRowsCount && { visible: true }) || {}),
      }));
      setVisibleRowsCount(visibleRowsCount + 1);
      setTableBody(tableBodyTemp);
      setIsAddOccEnable(false);
    }
  }

  function handleTableDataUpdate({ rowIndex, colIndex, value, name }) {
    const newTableBody = tableBody.map((row, i) => {
      if (i !== rowIndex) {
        return { ...row, rowData: [...row.rowData] };
      }
      const { visible, rowData } = row;
      const newRowData = rowData.map((col, j) =>
        j !== colIndex ? col : value
      );
      return { visible, rowData: newRowData };
    });

    setTableBody(newTableBody);
    dispatch(
      setEmpTableRowValues({
        attributeName: name,
        attributeValue: value,
        caseId: caseId,
      })
    );
    if (isTableComplete(newTableBody, rowIndex)) {
      setIsTableRowComplete(true);
      setIsAddOccEnable(true);
    } else {
      setIsTableRowComplete(false);
    }
    if (rowIndex !== 0) {
      const tableEntered = isTableRowEntered(newTableBody, rowIndex);
      setIsAddOccEnable(tableEntered);
    }
  }
  function handleDeleteRow(rowIndex) {
    const newTableBody = tableBody.filter((_, index) => index !== rowIndex);
    newTableBody.push({ visible: false, rowData: Array(columnCount).fill("") });
    let updateAttributes = [];
    let updateEmplTable = [];
    newTableBody.forEach(({ _, rowData }, i) => {
      // create updates only for rows which will be replaced due to delete operation
      if (i >= rowIndex && i < visibleRowsCount) {
        updateAttributes = [
          ...updateAttributes,
          ...rowData.flatMap((attributeValue, j) =>
            // do not update attributes if it's value is same is current value in backend
            attributeValue !== tableBody[i].rowData[j]
              ? [
                  {
                    attributeName: cdiMapping[i][j],
                    attributeValue,
                  },
                ]
              : []
          ),
        ];
      }
      if (i < rowIndex && i < visibleRowsCount) {
        updateEmplTable = [
          ...updateEmplTable,
          ...rowData.map((attributeValue, j) =>
            // update existing attributes value in the redux state
            attributeValue === tableBody[i].rowData[j]
              ? {
                  attributeName: cdiMapping[i][j],
                  attributeValue,
                  caseId: caseId,
                }
              : []
          ),
        ];
      }
    });

    for (let i = 0; i < updateAttributes.length; i++) {
      updateEmplTable.push({
        attributeName: updateAttributes[i].attributeName,
        attributeValue: updateAttributes[i].attributeValue,
        caseId: caseId,
      });
    }
    if (
      updateEmplTable &&
      updateEmplTable.length &&
      lifeAttributeValues[updateEmplTable[0].attributeName] &&
      updateAttributes.length
    ) {
      updateCaseAttributes({
        type: AttributesQuery_TYPE.LIFE,
        updateAttributes,
        refetchAllQuestions: true,
      });
    }

    dispatch(
      setEmpTableRowValues({
        Type: "UpdateState",
        updateAttributes: updateEmplTable,
      })
    );
    setVisibleRowsCount(visibleRowsCount - 1);
    setTableBody(newTableBody);
    if (isTableComplete(newTableBody, rowIndex)) {
      setIsAddOccEnable(true);
    } else {
      setIsTableRowComplete(false);
    }
  }

  function updateBaseQuestion(answer) {
    answerBaseQuestion({
      baseQuestionCode: code,
      answer: answer,
    });
  }
  function isTableComplete(newTableBody, rowIndex) {
    var isComplete = true;
    for (let i = 0; i < newTableBody.length; i++) {
      if (newTableBody[i].visible && rowIndex === i) {
        if (!isRowComplete(newTableBody[i].rowData)) {
          isComplete = false;
          break;
        }
      }
    }
    return isComplete;
  }
  function isTableRowEntered(newTableBody, rowIndex) {
    for (let i = 0; i < newTableBody.length; i++) {
      if (newTableBody[i].visible && rowIndex === i && rowIndex > 0) {
        if (isRowEntered(newTableBody[i].rowData)) {
          return true;
        }
      }
    }
    return false;
  }

  function handleSaveEmpHistoryTable() {
    let tempUpdateAttributes = [];
    Object.keys(empTableRow).forEach((key) => {
      tempUpdateAttributes.push({
        attributeName: empTableRow[key].attributeName,
        attributeValue: empTableRow[key].attributeValue,
      });
    });

    if (tempUpdateAttributes && tempUpdateAttributes.length > 0) {
      var firstRowComplete = isFirstRowComplete(tableBody);
      if (
        isTableRowComplete &&
        CDI_NAME ===
          CDI_NAMES.EMPLOYMENT_HISTORY_PAGE.EMPLOYMENT_HISTORY_TABLE &&
        firstRowComplete
      ) {
        tempUpdateAttributes.push({
          attributeName:
            CDI_NAMES.EMPLOYMENT_HISTORY_PAGE.EMPLOYMENT_HISTORY_TABLE,
          attributeValue: true,
        });
        tempUpdateAttributes.push({
          attributeName: code,
          attributeValue: true,
        });
      }
      updateCaseAttributes({
        type: AttributesQuery_TYPE.LIFE,
        updateAttributes: tempUpdateAttributes,
        refetchAllQuestions: true,
      });
      if (isTableRowComplete && !CDI_NAME) {
        updateBaseQuestion(BOOLEAN_STRING.true);
      }
    }
  }

  function isRowComplete(rowData) {
    var isElemNullOrEmpty = rowData.some(function (rowValue) {
      return rowValue === null || rowValue === "";
    });
    return !isElemNullOrEmpty;
  }
  function isRowEntered(rowData) {
    var isElemNullOrEmpty = rowData.some(function (rowValue) {
      return rowValue !== null || rowValue !== "";
    });
    return isElemNullOrEmpty;
  }
  const spacingNode = <div style={{ width: 40 }}></div>;
  // get column headings see self employed income table - add additional property visible
  // build table using rows / columns property see anl table logic
  // on blur callback to write cdi value back to ais
  // on remove row call back to rewrite the table back to ais
  // on question invisible delete all saved cdi values

  if (headingRow.length && tableBody.length && cdiMapping.length) {
    return (
      <Root>
        <GelContainerLite
          style={{
            width: "90%",
          }}
        >
          <GelRowLayout
            className={classes.mainRowStyle}
            style={{
              backgroundColor: getGelTokens().global.themeColorGrayT05,
            }}
          >
            <HeadingRow headings={headingRow} spacingNode={spacingNode} />
          </GelRowLayout>
          {tableBody.flatMap(({ visible, rowData }, index) =>
            visible
              ? [
                  <GelRowLayout
                    key={`${index}`}
                    className={classes.mainRowStyle}
                    style={{
                      backgroundColor: getGelTokens().global.themeColorWhite,
                    }}
                  >
                    <EmploymentHistoryRow
                      key={`${index}-${rowData[0]}-${rowData[1]}`}
                      cdiNames={cdiMapping[index]}
                      cdiValues={rowData}
                      rowIndex={index}
                      rowCount={visibleRowsCount}
                      onRowDataUpdate={handleTableDataUpdate}
                      spacingNode={spacingNode}
                      onDeleteRow={handleDeleteRow}
                      onAddOccupation={handleAddOccupation}
                      isAddOccEnable={isAddOccEnable}
                      lifeAttributeValues={lifeAttributeValues}
                      style={{
                        backgroundColor: getGelTokens().global.themeColorWhite,
                      }}
                    />
                  </GelRowLayout>,
                ]
              : []
          )}
          <GelRowLayout
            className={classes.mainRowStyle}
            style={{
              backgroundColor: getGelTokens().global.themeColorWhite,
            }}
          >
            {(updateCaseAttributesError || updateBaseQuestionError) && (
              <WarningMessage
                message={ERROR_MESSAGES.SERVER_FAILURE}
                errorType
              />
            )}
            <GelBoxLayout distribution="end" space="auto">
              <WarningMessage
                infoType
                message={INFO_MESSAGES.SAVE_EMPLOYMENT_HISTORY_DETAILS}
              ></WarningMessage>
              <GelButton
                id="saveEmpHistoryTables"
                name="saveEmpHistoryTables"
                secondary
                medium
                onClick={handleSaveEmpHistoryTable}
              >
                Save
              </GelButton>
            </GelBoxLayout>
          </GelRowLayout>
          <WarningMessage
            message={ERROR_MESSAGES.EMP_TABLE_ROW_REQUIRED}
            warningType
          />
        </GelContainerLite>
      </Root>
    );
  }
  return null;
}

EmploymentHistoryTable.propTypes = {
  question: PropTypes.shape({
    code: PropTypes.string.isRequired,
    answerValue: PropTypes.string,
    customProperties: PropTypes.shape({
      COLUMNS: PropTypes.string.isRequired,
      CDI_NAME: PropTypes.string,
    }).isRequired,
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        propertyName: PropTypes.string.isRequired,
        propertyValue: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  lifeAttributeValues: PropTypes.object.isRequired,
};
