import { useSelector } from "react-redux";
import { useUpdateCaseAttributes } from "../../utils/useUpdateCaseAttributes";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { ReadOnlyQuestion } from "../QuestionInput/ReadOnlyQuestion";
import { CASE_ATTRIBUTES } from "../../utils/CONSTANTS";

function IncomeIncludingSuper({ question }) {
  const incomeIncludingSuperData = useSelector(
    (state) => state.incomeIncludingSuperData
  );
  const { code, questionText, customProperties = {} } = question;
  const answerValue = incomeIncludingSuperData[code];
  const { PREFIX, SUFFIX } = customProperties || {};
  return (
    <>
      <ReadOnlyQuestion
        questionText={questionText}
        readonlyValue={answerValue}
        prefix={PREFIX}
        suffix={SUFFIX}
      />
      <UpdateIncomeIncludingSuper
        attributeValue={answerValue}
        question={question}
      />
    </>
  );
}

IncomeIncludingSuper.propTypes = {
  question: PropTypes.object.isRequired,
};
export default IncomeIncludingSuper;

function UpdateIncomeIncludingSuper({ attributeValue, question }) {
  const { updateCaseAttributes } = useUpdateCaseAttributes();
  const { answerValue } = question;
  useEffect(() => {
    const answerValueToString = answerValue ? answerValue.toString() : "";
    if (
      typeof attributeValue === "number" &&
      attributeValue.toString() !== answerValueToString
    ) {
      updateCaseAttributes({
        updateAttributes: [
          {
            attributeName: CASE_ATTRIBUTES.TAXABLE_ANNUAL_INCOME_INPUT,
            attributeValue: attributeValue.toString(),
          },
        ],
        refetchAllQuestions: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeValue]);
  return null;
}

UpdateIncomeIncludingSuper.propTypes = {
  attributeValue: PropTypes.any,
  question: PropTypes.object.isRequired,
};
