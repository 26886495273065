import { configureStore } from "@reduxjs/toolkit";
import caseIdReducer from "./caseid-slice";
import bmiDataReducer from "./bmiData-slice";
import currentUserRoleReducer from "./currentUserRole-slice";
import applicationStatusReducer from "./applicationStatus-slice";
import incomeReducer from "./income-slice";
import sectionDataReducer from "./sectionData-slice";
import incomeExcludingSuperReducer from "./incomeExcludingSuper-slice";
import inconsistentAnswersToBQReducer from "./inconsistentAnswersToBQ-slice";
import configReducer from "./config-slice";
import pagesReducer from "./pages-slice";
import navigationButtonStatusReducer from "./navigationButtonStatus-slice";
import errorDataReducer from "./errorData-slice";
import incomeIncludingSuperReducer from "./incomeIncludingSuper-slice";
import empTableRowValuesReducer from "./empTableRowValues-slice";
import assetsAndLiabilitiesReducer from "./assetsAndLiabilities-slice";
import sectionExpandDataReducer from "./sectionExpandData-slice";

export const store = configureStore({
  reducer: {
    caseId: caseIdReducer,
    currentUserRole: currentUserRoleReducer,
    applicationStatus: applicationStatusReducer,
    bmiData: bmiDataReducer,
    incomeData: incomeReducer,
    sectionData: sectionDataReducer,
    incomeExcludingSuperData: incomeExcludingSuperReducer,
    inconsistentAnswersToBQData: inconsistentAnswersToBQReducer,
    configData: configReducer,
    pages: pagesReducer,
    navigationButtonStatus: navigationButtonStatusReducer,
    errorData: errorDataReducer,
    incomeIncludingSuperData: incomeIncludingSuperReducer,
    empTableRowValues: empTableRowValuesReducer,
    assetsAndLiabilitiesData: assetsAndLiabilitiesReducer,
    sectionExpandData: sectionExpandDataReducer,
  },
});
