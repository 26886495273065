import React from "react";
import propTypes from "prop-types";

const SectionContext = React.createContext({});

export function SectionContextProvider({ value, children }) {
  return (
    <SectionContext.Provider value={value}>{children}</SectionContext.Provider>
  );
}

SectionContextProvider.propTypes = {
  value: propTypes.string,
  children: propTypes.node,
};

export const useSectionContext = () => React.useContext(SectionContext);
