import propTypes from "prop-types";
import { BooleanQuestion } from "./BooleanQuestion";
import { SelectionQuestion } from "./SelectionQuestion";
import { NumericQuestion } from "./NumericQuestion";
import { TextQuestion } from "./TextQuestion";
import { RenderByDisclosureSource } from "../Disclosures/RenderByDisclosureSource";
import {
  BASE_QUES_TYPES,
  STANDARD_QUE_ANS_TYPE,
  QUES_INPUT_TYPES,
  CUSTOM_QUES_TYPE,
  CUSTOM_TABLE_TYPE,
  ERROR_MESSAGES,
  EMPLOYMENT_INCOME_PAGE_BQ_IDS,
  S41_SuperContributionAmt_BQIds,
  S41_IncomeExcludingSuper_BQIds,
  S41_IncomeIncludingSuper_BQIds,
  CASE_ATTRIBUTES,
  DISCLOSURE_SOURCES,
} from "../../utils/CONSTANTS";
import { useAnswerBaseQuestion } from "../../utils/useAnswerBaseQuestion";
import { PseudoPicklist } from "../CustomTypes/PseudoPicklist";
import { AssetsLiabilitiesTable } from "../CustomTypes/AnlTable/AnLTable";
import { OccupationSearch } from "../CustomTypes/OccupationSearch";
import { WarningMessage } from "../WarningMessage";
import HeightQueInput from "../CustomTypes/Height";
import WeightQueInput from "../CustomTypes/Weight";
import BmiQuestion from "../CustomTypes/Bmi";
import IncomeTable from "../CustomTypes/IncomeTables/IncomeTable";
import SelfIncomeExpensesTable from "../CustomTypes/IncomeTables/SelfIncomeExpenses";
import EmploymentHistoryTable from "../CustomTypes/EmploymentHistory/EmploymentHistoryTable";
import { setIncomeExcludingSuper } from "../../globalStore/incomeExcludingSuper-slice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IncomeExcludingSuper from "../CustomTypes/IncomeExcludingSuper";
import IncomeIncludingSuper from "../CustomTypes/IncomeIncludingSuper";
import { setInconsistentFlagOfBQ } from "../../globalStore/inconsistentAnswersToBQ-slice";
import { ReadOnlyQuestion } from "./ReadOnlyQuestion";
import { addErrorMessage } from "../../globalStore/errorData-slice";
import { useErrorData, useCaseId } from "../../globalStore/hooks";
import { RecordNetworkError } from "../RecordNetworkError";
import { setIncomeIncludingSuper } from "../../globalStore/incomeIncludingSuper-slice";
import {
  GelContainerLite,
  GelBoxLayout,
  GelRowLayout,
} from "@tal-gel/components";
import { useLinkedQuestionContext } from "../../contexts/LinkedQuestionContext";
import { useUpdateCaseAttributes } from "../../utils/useUpdateCaseAttributes";
import { calculateIncomeIncludingSuper } from "../../utils/commonFunctions";
import { tabularClasses } from "./tabularClasses";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { styled } from "@mui/material/styles";

function RenderQuestionInput({ question, baseQuestionValue, withLabel }) {
  const {
    answerType,
    answerText,
    answerValue,
    caseDataQuestionMeta,
    code,
    customProperties,
    questionType,
    conditionalQuestionMeta,
    questionText,
  } = question;
  const { CUSTOM_TYPE, DEFAULT_VALUE, TABLE_TYPE } = customProperties || {};

  const { dataType } = caseDataQuestionMeta || {};
  const dispatch = useDispatch();
  const linkedQuestionContext = useLinkedQuestionContext();
  const classes = tabularClasses();
  const [inputValue, setInputValue] = useState("metric");

  const ToggleButtonStyled = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: "#303e46",
    },
    "&.MuiButtonBase-root": {
      fontSize: "13px",
      fontWeight: "700",
      height: "40px",
      fontFamily: "Pluto Sans",
    },
  });

  const onChange = (event) => {
    setInputValue(event.target.value);
  };

  function handleUserResponse(e) {
    const { name, value } = e.target;
    if (value) {
      baseQuestionValue(value, name);
    }
  }

  const questionInput = {
    ...question,
    callbackFunctions: [...question.callbackFunctions, handleUserResponse],
  };

  useEffect(() => {
    if (
      code === EMPLOYMENT_INCOME_PAGE_BQ_IDS.App_Inc_INC_EMP_IND0 ||
      code === EMPLOYMENT_INCOME_PAGE_BQ_IDS.App_Inc_SGP_IND0
    ) {
      dispatch(
        setIncomeExcludingSuper({
          questionCode: code,
          answerValue,
        })
      );
    }

    if (
      S41_SuperContributionAmt_BQIds.includes(code) ||
      S41_IncomeExcludingSuper_BQIds.includes(code)
    ) {
      dispatch(
        setIncomeIncludingSuper({
          questionCode: code,
          answerValue,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, code, answerValue]);

  useEffect(() => {
    if (
      conditionalQuestionMeta &&
      conditionalQuestionMeta.requiresLinkedDisclosure
    ) {
      dispatch(
        setInconsistentFlagOfBQ({
          questionCode: code,
        })
      );
    }
  }, [dispatch, conditionalQuestionMeta, code]);

  switch (CUSTOM_TYPE) {
    case CUSTOM_QUES_TYPE.READONLY:
      const readOnlyAnswerValue = (DEFAULT_VALUE && !answerText) || answerText;
      if (code === EMPLOYMENT_INCOME_PAGE_BQ_IDS.App_Inc_DAI_IND0) {
        return (
          <IncomeExcludingSuper question={questionInput}></IncomeExcludingSuper>
        );
      }

      if (S41_IncomeIncludingSuper_BQIds.includes(code)) {
        return (
          <IncomeIncludingSuper question={questionInput}></IncomeIncludingSuper>
        );
      }

      if (DEFAULT_VALUE && !answerText) {
        baseQuestionValue(DEFAULT_VALUE, code);
        //answerBaseQuestion({ baseQuestionCode: code, answer: DEFAULT_VALUE });
      }
      return (
        <ReadOnlyQuestion
          questionText={questionText}
          readonlyValue={readOnlyAnswerValue}
        />
      );

    case CUSTOM_QUES_TYPE.PICKLIST_HEADING:
      return (
        <>
          {" "}
          {linkedQuestionContext &&
          linkedQuestionContext.hasLinkedQuestionCustomProp ? (
            <GelContainerLite
              gutter={{ left: 0, right: 0, top: 20, bottom: 15 }}
              style={classes.tabularDisclosure}
            >
              <PseudoPicklist question={question} />
            </GelContainerLite>
          ) : (
            <PseudoPicklist question={question} />
          )}
        </>
      );

    case CUSTOM_QUES_TYPE.TABLE:
      switch (TABLE_TYPE) {
        case CUSTOM_TABLE_TYPE.ASSETS_LIABILITIES:
          return <AssetsLiabilitiesTable question={question} />;
        case CUSTOM_TABLE_TYPE.SELF_INCOME_EXPENSE:
          return (
            <SelfIncomeExpensesTable question={question} time={Date.now()} />
          );
        case CUSTOM_TABLE_TYPE.SELF_INCOME_ADDBACK_ITEMS:
          return <IncomeTable question={question} />;
        case CUSTOM_TABLE_TYPE.EMP_HISTORY:
          return <EmploymentHistoryTable question={question} />;
        case CUSTOM_TABLE_TYPE.EMP_INCOME:
          return <IncomeTable question={question} />;
        default:
          return (
            <p>{TABLE_TYPE}: table design finalized, build in progress.</p>
          );
      }
    case CUSTOM_QUES_TYPE.OCCUPATION_SEARCH:
      return <OccupationSearch question={question} />;
    case CUSTOM_QUES_TYPE.BMI:
      return <BmiQuestion question={questionInput} />;
    case CUSTOM_QUES_TYPE.HEIGHT:
      const questionWeight = questionInput.relatedQuestion;
      const questionInputWeight = {
        ...questionWeight,
        callbackFunctions: [...question.callbackFunctions, handleUserResponse],
      };
      return (
        <GelRowLayout gutter="medium">
          <GelBoxLayout gap="xlarge" space="auto" distribution="start">
            {/* <GelRadioGroup
              gap="small"
              value={inputValue}
              onChange={onChange}
              buttonGroup
            >
              <GelRadio value="metric">METRIC</GelRadio>
              <GelRadio value="imperial">IMPERIAL</GelRadio>
            </GelRadioGroup> */}
            <ToggleButtonGroup
              value={inputValue}
              exclusive
              onChange={onChange}
              aria-label="text alignment"
            >
              <ToggleButtonStyled value="metric" aria-label="metric">
                METRIC
                {/* <GelLabel>METRIC</GelLabel> */}
              </ToggleButtonStyled>
              <ToggleButtonStyled value="imperial" aria-label="imperial">
                IMPERIAL
                {/* <GelLabel>IMPERIAL</GelLabel> */}
              </ToggleButtonStyled>
            </ToggleButtonGroup>
          </GelBoxLayout>
          <GelRowLayout style={{ height: "auto" }}>
            <GelBoxLayout
              gap="medium"
              space="auto"
              distribution="start"
              alignment="start"
            >
              <HeightQueInput
                question={questionInput}
                measureUnit={inputValue}
              />

              <WeightQueInput
                question={questionInputWeight}
                measureUnit={inputValue}
              />
              {/* <QuestionInputContainer
              question={questionInput.relatedQuestion}
              name={questionInput.relatedQuestion.code}
              withLabel
              answerBaseQuestionRequired={true}
            /> */}
            </GelBoxLayout>
          </GelRowLayout>
        </GelRowLayout>
      );
    case CUSTOM_QUES_TYPE.WEIGHT:
      return (
        <WeightQueInput question={questionInput} measureUnit={inputValue} />
      );
    default:
  }

  if (
    (questionType === BASE_QUES_TYPES.STANDARD &&
      answerType === STANDARD_QUE_ANS_TYPE.YES_NO) ||
    questionType === BASE_QUES_TYPES.CONDITIONAL ||
    (questionType === BASE_QUES_TYPES.CASE_DATA &&
      dataType === QUES_INPUT_TYPES.BOOLEAN)
  )
    return (
      <BooleanQuestion
        question={questionInput}
        withLabel={withLabel}
        normalRadioButton={
          linkedQuestionContext &&
          linkedQuestionContext.hasLinkedQuestionCustomProp &&
          linkedQuestionContext.hasLinkedQuestions
        }
      />
    );

  if (questionType === BASE_QUES_TYPES.CASE_DATA) {
    if (!dataType) {
      return <p>Data type not received.</p>;
    }
    switch (dataType.toUpperCase()) {
      case QUES_INPUT_TYPES.BOOLEAN:
        return (
          <BooleanQuestion question={questionInput} withLabel={withLabel} />
        );
      case QUES_INPUT_TYPES.NUMERIC:
        return (
          <NumericQuestion
            question={questionInput}
            withLabel={withLabel}
            nullable={false}
          />
        );
      case QUES_INPUT_TYPES.TEXT:
        return (
          <TextQuestion
            question={questionInput}
            maxCharacters={100}
            withLabel={withLabel}
            nullable={false}
          />
        );
      case QUES_INPUT_TYPES.NOTE:
        return (
          <TextQuestion
            question={questionInput}
            noteField
            maxCharacters={500}
            withLabel={withLabel}
            nullable={false}
          />
        );
      case QUES_INPUT_TYPES.SELECTION:
        return <SelectionQuestion question={questionInput} nullable={false} />;
      case QUES_INPUT_TYPES.MULTISELECTION:
        return <p>Case data question type {dataType}</p>;
      case QUES_INPUT_TYPES.DATE:
        return <p>Case data question type {dataType}</p>;
      default:
        return (
          <p>Case data question type &quote;{dataType}&quote; not found.</p>
        );
    }
  }

  return "";
}
RenderQuestionInput.propTypes = {
  question: propTypes.shape({
    code: propTypes.string.isRequired,
    answerValue: propTypes.string,
    answerType: propTypes.string,
    answerText: propTypes.string,
    questionType: propTypes.string,
    questionText: propTypes.string,
    conditionalQuestionMeta: propTypes.object,
    customProperties: propTypes.shape({
      CUSTOM_TYPE: propTypes.string,
      DEFAULT_VALUE: propTypes.string,
    }),
    caseDataQuestionMeta: propTypes.shape({
      dataType: propTypes.string.isRequired,
      listName: propTypes.string,
    }),
    callbackFunctions: propTypes.arrayOf(propTypes.func),
  }).isRequired,
  onError: propTypes.func,
  withLabel: propTypes.bool,
  baseQuestionValue: propTypes.func,
};

export function QuestionInputContainer({
  question,
  withLabel,
  answerBaseQuestionRequired = true,
}) {
  const { CMS_WARN, CMS_INFO } = question.customProperties || {};
  const { code, parentConditionalQuestions, answerValue, disclosureSource } =
    question;

  const dispatch = useDispatch();
  const caseId = useCaseId();
  const errorData = useErrorData();
  const isError = errorData.find(
    (questionError) =>
      questionError.baseQuestionCode === question.code &&
      questionError.caseId === caseId &&
      questionError.serverError === errorData[0].serverError //true
  );

  //const [isError, setError] = useState(null);
  const inconsistentAnswersToBQData = useSelector(
    (state) => state.inconsistentAnswersToBQData
  );

  const incomeIncludingSuperData = useSelector(
    (state) => state.incomeIncludingSuperData
  );

  const isAnswerInconsistent = inconsistentAnswersToBQData[code];

  const { answerBaseQuestion, error, called } = useAnswerBaseQuestion(
    answerValue || null
  );
  const classes = tabularClasses();
  const linkedQuestionContext = useLinkedQuestionContext();
  const { updateCaseAttributes } = useUpdateCaseAttributes();

  useEffect(() => {
    dispatch(
      addErrorMessage({
        baseQuestionCode: question.code,
        serverError: question && question.answerValue !== null && isError,
        caseId: caseId,
        errorType: "serverError",
      })
    );
  }, [dispatch]);
  function answerBaseQuestionCallback(result, currentAnswer) {
    var annualIncomeIncludingSuper = 0;
    if (parentConditionalQuestions && parentConditionalQuestions.length > 0) {
      for (let parentConditionalQuestion of parentConditionalQuestions) {
        dispatch(
          setInconsistentFlagOfBQ({
            questionCode: parentConditionalQuestion.baseQuestionCode,
          })
        );
      }
    }

    if (
      S41_SuperContributionAmt_BQIds.includes(code) ||
      S41_IncomeExcludingSuper_BQIds.includes(code)
    ) {
      const answerValueToString = answerValue ? answerValue.toString() : "";

      if (currentAnswer?.toString() !== answerValueToString) {
        if (S41_IncomeExcludingSuper_BQIds.includes(code)) {
          const employerSuperContribution =
            incomeIncludingSuperData[
              EMPLOYMENT_INCOME_PAGE_BQ_IDS.App_Inc_S41SCA_DBCIONLY_IND0
            ].toString() ||
            incomeIncludingSuperData[
              EMPLOYMENT_INCOME_PAGE_BQ_IDS.App_Inc_S41SCA_IND0
            ].toString();
          annualIncomeIncludingSuper = calculateIncomeIncludingSuper({
            currentAnnualIncomeExSuper: +currentAnswer,
            employerSuperContribution: +employerSuperContribution,
          });
        } else if (S41_SuperContributionAmt_BQIds.includes(code)) {
          const currentAnnualIncomeExSuper =
            incomeIncludingSuperData[
              EMPLOYMENT_INCOME_PAGE_BQ_IDS.App_Inc_S41IES_DBCIONLY_IND0
            ].toString() ||
            incomeIncludingSuperData[
              EMPLOYMENT_INCOME_PAGE_BQ_IDS.App_Inc_S41IES_IND0
            ].toString();
          annualIncomeIncludingSuper = calculateIncomeIncludingSuper({
            currentAnnualIncomeExSuper: +currentAnnualIncomeExSuper,
            employerSuperContribution: +currentAnswer,
          });
        }
        updateCaseAttributes({
          updateAttributes: [
            {
              attributeName: CASE_ATTRIBUTES.TAXABLE_ANNUAL_INCOME_INPUT,
              attributeValue: annualIncomeIncludingSuper.toString(),
            },
          ],
          refetchAllQuestions: false,
        });
      }
    }
    dispatch(
      addErrorMessage({
        baseQuestionCode: question.code,
        serverError: false,
        caseId: caseId,
        errorType: "serverError",
      })
    );
  }

  function baseQuestionValue(value, name) {
    answerBaseQuestionRequired &&
      answerBaseQuestion({
        baseQuestionCode: name,
        answer: value,
        answerBaseQuestionCallback: answerBaseQuestionCallback,
      });
    return null;
  }

  return (
    <>
      {CMS_INFO && <WarningMessage messageCode={CMS_INFO} infoType />}
      <RenderQuestionInput
        question={question}
        baseQuestionValue={baseQuestionValue}
        withLabel={withLabel}
      />
      {isError && (
        <WarningMessage message={ERROR_MESSAGES.SERVER_FAILURE} errorType />
      )}
      {CMS_WARN && <WarningMessage messageCode={CMS_WARN} warningType />}
      {isAnswerInconsistent && (
        <WarningMessage
          message={ERROR_MESSAGES.INCONSISTENT_ANSWERS}
          errorType
        />
      )}
      {error && (
        <RecordNetworkError
          baseQuestionCode={code}
          serverError={error ? true : false}
          caseId={caseId}
          iscalled={called}
        />
      )}
      {/* DisclosureSource SPECIFIED needs to be treated differently and 
      so its excluded here but will still have tabular format. 
      please refer function DiclosureSourceSpecified  */}
      {linkedQuestionContext &&
      linkedQuestionContext.hasLinkedQuestionCustomProp &&
      disclosureSource &&
      disclosureSource !== DISCLOSURE_SOURCES.NONE &&
      disclosureSource !== DISCLOSURE_SOURCES.SPECIFIED ? (
        <GelContainerLite
          gutter={{ left: 0, right: 0, top: 20, bottom: 15 }}
          style={classes.tabularDisclosure}
        >
          {RenderByDisclosureSource(question)}
        </GelContainerLite>
      ) : (
        RenderByDisclosureSource(question)
      )}
    </>
  );
}

QuestionInputContainer.propTypes = {
  question: propTypes.shape({
    code: propTypes.string.isRequired,
    answerValue: propTypes.string,
    answerType: propTypes.string,
    disclosureSource: propTypes.string,
    parentConditionalQuestions: propTypes.array,
    customProperties: propTypes.shape({
      CUSTOM_TYPE: propTypes.string,
      DEFAULT_VALUE: propTypes.string,
      CMS_WARN: propTypes.string,
      CMS_INFO: propTypes.string,
    }),
    caseDataQuestionMeta: propTypes.shape({
      dataType: propTypes.string.isRequired,
      listName: propTypes.string,
    }),
  }).isRequired,
  withLabel: propTypes.bool,
  answerBaseQuestionRequired: propTypes.bool,
};
