import { createSlice } from "@reduxjs/toolkit";
import { resetStore, setHandoverData } from "./shared-actions";
const initialState = "";

export const currentUserRoleSlice = createSlice({
  name: "currentUserRole",
  initialState,

  reducers: {
    setCurrentUserRole: (_, action) => {
      return action.payload.currentUserRole;
    },
    resetCurrentUserRole: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(resetStore.fulfilled, () => {
      return initialState;
    });

    builder.addCase(setHandoverData.type, (_, action) => {
      return action.payload.currentUserRole;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentUserRole, resetCurrentUserRole } =
  currentUserRoleSlice.actions;

export default currentUserRoleSlice.reducer;
