import { gql } from "@apollo/client";
export const LIST_FIELDS = gql`
  fragment ListFields on ListByNameType {
    listName
    listItems {
      code
      description
      label @client
      value @client
    }
  }
`;
