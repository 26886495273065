import { createSlice } from "@reduxjs/toolkit";
import { resetStore } from "./shared-actions";
import { EMPLOYMENT_INCOME_PAGE_BQ_IDS } from "../utils/CONSTANTS";
import { calculateIncomeIncludingSuper } from "../utils/commonFunctions";
const initialState = {};

export const incomeIncludingSuperSlice = createSlice({
  name: "incomeIncludingSuper",
  initialState,

  reducers: {
    setIncomeIncludingSuper: (state, action) => {
      const { questionCode, answerValue } = action.payload;
      const numberAnswerValue = +answerValue;

      if (state[questionCode] !== numberAnswerValue) {
        if (typeof numberAnswerValue !== "number") {
          return state;
        }

        state[questionCode] = numberAnswerValue;

        if (
          questionCode ===
            EMPLOYMENT_INCOME_PAGE_BQ_IDS.App_Inc_S41IES_DBCIONLY_IND0 ||
          questionCode ===
            EMPLOYMENT_INCOME_PAGE_BQ_IDS.App_Inc_S41SCA_DBCIONLY_IND0
        ) {
          state.App_Inc_S41IIS_DBCIONLY_IND0 = calculateIncomeIncludingSuper({
            currentAnnualIncomeExSuper: +state.App_Inc_S41IES_DBCIONLY_IND0,
            employerSuperContribution: +state.App_Inc_S41SCA_DBCIONLY_IND0,
          });
        } else {
          state.App_Inc_S41IIS_IND0 = calculateIncomeIncludingSuper({
            currentAnnualIncomeExSuper: +state.App_Inc_S41IES_IND0,
            employerSuperContribution: +state.App_Inc_S41SCA_IND0,
          });
        }
      }
    },
    resetIncomeIncludingSuper: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(resetStore.fulfilled, () => {
      return initialState;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setIncomeIncludingSuper, resetIncomeIncludingSuper } =
  incomeIncludingSuperSlice.actions;

export default incomeIncludingSuperSlice.reducer;
