import { createSlice } from "@reduxjs/toolkit";
import { resetStore } from "./shared-actions";
import { getPageStatus } from "../utils/commonFunctions";
import { URE_PAGE_CODES } from "../utils/CONSTANTS";

const initialState = {};
export const initPageStatusData = (data) => {
  return data.map((page) => ({
    ...page,
    pageStatus: getPageStatus(page.code),
  }));
};

export const pagesSlice = createSlice({
  name: "pages",
  initialState,

  reducers: {
    loadPages: (state, action) => {
      let pageStatusData = initPageStatusData(action.payload);
      if (state && state.pages) {
        const updatedState = state.pages.map(
          (page) => pageStatusData.find((el) => el.code === page.code) || page
        );
        return {
          ...state,
          pages: updatedState,
        };
      } else {
        return { ...state, pages: pageStatusData };
      }
    },
    updatePageStatus: (state, action) => {
      const index =
        state &&
        state.pages.findIndex((page) => action.payload.code === page.code);
      if (index !== -1) {
        let updatedPages = [...state.pages];
        updatedPages[index].pageStatus = getPageStatus(action.payload.code);
        if (action.payload.code === URE_PAGE_CODES.App_HLstyle) {
          updatedPages[index].isPageComplete = false;
        }
        state.pages = updatedPages;
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(resetStore.fulfilled, () => {
      return initialState;
    });
  },
});

export const { loadPages, updatePageStatus } = pagesSlice.actions;

export default pagesSlice.reducer;
