import propTypes from "prop-types";
import { useState, useEffect } from "react";
import { GelRowLayout, GelParagraph } from "@tal-gel/components";

import { useAnswerBaseQuestionNoRefresh } from "../../../utils/useAnswerBaseQuestionNoRefresh";
import { useUpdateCaseAttributes } from "../../../utils/useUpdateCaseAttributes";

import { AddCustomItemForm } from "./AddCustomItemForm";
import { BaseQuestionItemRows } from "./BaseQuestionItemRows";
import { AdditionalCustomItemRows } from "./AdditionalCustomItemRows";
import { TotalRow } from "./TotalRow";
import { AttributesQuery_TYPE } from "../../../utils/CONSTANTS";
import { useDispatch } from "react-redux";
import {
  setAnlTableValue,
  addNewAnLValue,
  suffleAfterDelete,
} from "../../../globalStore/assetsAndLiabilities-slice";
import { getGelTokens } from "@tal-gel/theming";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { convertIntoArray } from "../../../utils/commonFunctions";

const PREFIX = "TableColumn";

const classes = {
  headingRowBgStyle: `${PREFIX}-headingRowBgStyle`,
};

const StyledGelRowLayout = styled(GelRowLayout)(() => ({
  [`& .${classes.headingRowBgStyle}`]: {
    backgroundColor: getGelTokens().global.themeColorGrayT05,
    padding: "10px 0px",
    border: "1px solid " + getGelTokens().global.themeColorGrayT20,
  },
}));

export function TableColumn({
  columnHeading,
  childBaseQuestions,
  totalBaseQuestion,
  linkedCaseDataAttributes,
  initialAdditionalCustomItems,
}) {
  const dispatch = useDispatch();
  const { answerBaseQuestionNoRefresh } = useAnswerBaseQuestionNoRefresh();

  const { updateCaseAttributes } = useUpdateCaseAttributes();

  const initialAllValues =
    (childBaseQuestions &&
      childBaseQuestions.length &&
      childBaseQuestions.reduce((result, { code, answerValue }) => {
        return {
          ...result,
          [code]:
            (typeof answerValue !== "undefined" &&
              answerValue !== null &&
              +answerValue) ||
            answerValue,
        };
      }, {})) ||
    {};
  const [allValues, setAllValues] = useState(initialAllValues);
  const [totalValue, setTotalValue] = useState(
    (totalBaseQuestion && +totalBaseQuestion.answerValue) || 0
  );
  const [additionalCustomItems, setAdditionalCustomItems] = useState(
    initialAdditionalCustomItems || []
  );
  const { customProperties } = totalBaseQuestion;
  const { CDI_NAME } = customProperties;
  const assetsAndLiabilitiesData = useSelector(
    (state) => state.assetsAndLiabilitiesData
  );

  const existingCutomItems = convertIntoArray(assetsAndLiabilitiesData).filter(
    (elem) => elem.isCustom && elem.tableType === columnHeading
  );

  useEffect(() => {
    const baseQNCustomItems = Object.values(allValues).concat(
      additionalCustomItems.map(({ value }) => value)
    );

    const baseQNValue = baseQNCustomItems.filter(
      (value) => value !== null && typeof value === "number"
    );

    if (baseQNValue.length > 0) {
      const newTotalValue = baseQNValue.reduce((result, c) => result + c, 0);
      const stateUpdates = [];

      !CDI_NAME &&
        answerBaseQuestionNoRefresh({
          baseQuestionCode: totalBaseQuestion.code,
          answer: newTotalValue,
        });
      stateUpdates.push(() => setTotalValue(newTotalValue));
      stateUpdates.forEach((callbackFunction) => callbackFunction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues, additionalCustomItems]);

  function attributeValueForCustomItem(label, value) {
    const objJson = value !== null ? { label, value } : { label };
    return JSON.stringify(objJson);
  }

  function removeCustomItem(itemIndex, name) {
    let updateAttributes;
    const newAdditionalCustomItems = additionalCustomItems.flatMap(
      (item, index) => (index !== itemIndex ? [item] : [])
    );
    const excludeCurrentFromExistng = existingCutomItems.flatMap(
      (item, index) => (item.label !== name ? [item] : [])
    );
    let updateAttributesStateCopy;

    if (!CDI_NAME) {
      // reset old values
      updateAttributes = linkedCaseDataAttributes.map(
        (attributeName, index) => {
          let attributeValue = null;
          if (index < newAdditionalCustomItems.length) {
            attributeValue = JSON.stringify(newAdditionalCustomItems[index]);
          }
          return {
            attributeName,
            attributeValue,
          };
        }
      );
    } else {
      updateAttributes = linkedCaseDataAttributes.map(
        (attributeName, index) => {
          let attributeValue = null;
          let unSaved = false;
          if (index < excludeCurrentFromExistng.length) {
            const customItem = excludeCurrentFromExistng[index];
            attributeValue = attributeValueForCustomItem(
              customItem.label,
              customItem.cdiValue
            );
            unSaved = customItem.unSaved;
          }
          return {
            attributeName,
            attributeValue,
            unSaved,
          };
        }
      );

      updateAttributesStateCopy = linkedCaseDataAttributes.map(
        (attributeName, index) => {
          let attributeValue = null;
          if (index < excludeCurrentFromExistng.length) {
            const customItem = excludeCurrentFromExistng[index];
            const existingInDb = initialAdditionalCustomItems.find(
              (elem) => elem.label === customItem.label
            );
            if (existingInDb)
              attributeValue = attributeValueForCustomItem(
                existingInDb.label,
                existingInDb.value
              );
          }
          return {
            attributeName,
            attributeValue,
          };
        }
      );
    }

    if (updateAttributesStateCopy && updateAttributesStateCopy.length > 0) {
      updateCaseAttributes({
        type: AttributesQuery_TYPE.LIFE,
        updateAttributes: updateAttributesStateCopy,
      });
      !CDI_NAME && setAdditionalCustomItems(newAdditionalCustomItems);
      if (CDI_NAME) {
        dispatch(suffleAfterDelete(updateAttributes));
      }
    }
  }

  function findCustomItem(name) {
    return existingCutomItems.find((item) => {
      return item.label === name;
    });
  }

  function addCustomValue(e) {
    const { name, value } = e.target;
    const newValue = +value;
    let changedItemIndex = -1;
    const newAdditionalCustomItems = additionalCustomItems.map(
      (customItem, i) => {
        if (customItem.label === name && customItem.value !== newValue) {
          changedItemIndex = i;
          return { ...customItem, value: +value };
        }
        return customItem;
      }
    );
    if (CDI_NAME) {
      const customItem = findCustomItem(name);
      if (customItem) {
        dispatch(
          setAnlTableValue({
            name: customItem.cdiName,
            value,
          })
        );
      }
    } else {
      if (changedItemIndex > -1) {
        setAdditionalCustomItems(newAdditionalCustomItems);
        updateCaseAttributes({
          type: AttributesQuery_TYPE.LIFE,
          updateAttributes: [
            {
              attributeName: linkedCaseDataAttributes[changedItemIndex],
              attributeValue: JSON.stringify(
                newAdditionalCustomItems[changedItemIndex]
              ),
            },
          ],
        });
      }
    }
  }
  function addValue(e) {
    const { name, value } = e.target;
    const intValue = +value;

    if (allValues[name] !== intValue) {
      if (!CDI_NAME) {
        setAllValues({ ...allValues, [name]: +intValue });
      } else {
        dispatch(setAnlTableValue({ name, value }));
      }
    }
  }

  function addNewCustomItem(itemName) {
    if (CDI_NAME) {
      const customItem = findCustomItem(itemName);
      if (!customItem) {
        const indexOfTobeNewlyAddedItem = existingCutomItems.length;
        dispatch(
          addNewAnLValue({
            cdiName: linkedCaseDataAttributes[indexOfTobeNewlyAddedItem],
            cdiValue: null,
            code: linkedCaseDataAttributes[indexOfTobeNewlyAddedItem],
            unSaved: true,
            tableType: columnHeading,
            isReadOnly: false,
            isCustom: true,
            label: itemName,
          })
        );
        return true;
      }
    } else if (
      additionalCustomItems.findIndex(({ label }) => label === itemName) === -1
    ) {
      const newAdditionalCustomItems = [
        ...additionalCustomItems,
        { label: itemName },
      ];
      const changedItemIndex = newAdditionalCustomItems.length - 1;
      setAdditionalCustomItems(newAdditionalCustomItems);
      updateCaseAttributes({
        type: AttributesQuery_TYPE.LIFE,
        updateAttributes: [
          {
            attributeName: linkedCaseDataAttributes[changedItemIndex],
            attributeValue: JSON.stringify(
              newAdditionalCustomItems[changedItemIndex]
            ),
          },
        ],
      });
      return true;
    }
    return false;
  }

  function canAddCustomItem(assetsAndLiabilitiesData) {
    const existingCutomItems = convertIntoArray(
      assetsAndLiabilitiesData
    ).filter((elem) => elem.isCustom && elem.tableType === columnHeading);

    const result =
      linkedCaseDataAttributes &&
      existingCutomItems &&
      existingCutomItems.length < linkedCaseDataAttributes.length;

    return result;
  }

  return (
    <StyledGelRowLayout gutter={"small"}>
      <GelRowLayout className={CDI_NAME && classes.headingRowBgStyle}>
        <GelParagraph large>{columnHeading}</GelParagraph>
      </GelRowLayout>
      <BaseQuestionItemRows
        baseQuestions={childBaseQuestions}
        addValue={addValue}
      />
      <AdditionalCustomItemRows
        columnHeading={columnHeading}
        caseDataQuestionMeta={
          (childBaseQuestions && childBaseQuestions[0]) || {}
        }
        customItems={additionalCustomItems}
        addCustomValue={addCustomValue}
        removeCustomItem={removeCustomItem}
      />
      {((!CDI_NAME &&
        additionalCustomItems &&
        linkedCaseDataAttributes &&
        additionalCustomItems.length < linkedCaseDataAttributes.length) ||
        (CDI_NAME && canAddCustomItem(assetsAndLiabilitiesData))) && (
        <AddCustomItemForm
          id={`additionalItemTextInput-${columnHeading}`}
          maxLength={50}
          addCustomItem={addNewCustomItem}
          existingCutomItems={existingCutomItems}
        />
      )}
      <TotalRow
        totalBaseQuestion={totalBaseQuestion}
        value={totalValue.toLocaleString()}
      />
    </StyledGelRowLayout>
  );
}
TableColumn.propTypes = {
  columnHeading: propTypes.string.isRequired,
  childBaseQuestions: propTypes.arrayOf(propTypes.object).isRequired,
  maxAdditionalItemCount: propTypes.number.isRequired,
  totalBaseQuestion: propTypes.object.isRequired,
  linkedCaseDataAttributes: propTypes.arrayOf(propTypes.string),
  initialAdditionalCustomItems: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string.isRequired,
      value: propTypes.number,
    }).isRequired
  ),
  // parentQuestion: propTypes.shape({
  //   baseQuestionCode: propTypes.string.isRequired,
  //   answerValue: propTypes.string,
  // }).isRequired,
};
