import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useBmiData } from "../../globalStore/hooks";
import { setWeight } from "../../globalStore/bmiData-slice";

import {
  GelContainerLite,
  GelRowLayout,
  GelBoxLayout,
} from "@tal-gel/components";
import { useAnswerBaseQuestion } from "../../utils/useAnswerBaseQuestion";
import { WarningMessage } from "../WarningMessage";
import WeightInput from "./WeightInput";
import { ERROR_MESSAGES, SECTION_STATUSES } from "../../utils/CONSTANTS";
import { FormField } from "../QuestionInput/FormField";
import { useSectionContext } from "../../contexts/SectionContext";

const unit = {
  st: "st",
  lb: "lb",
  kg: "kg",
};

export default function Weight({ question, measureUnit }) {
  const { answerValue, code, caseDataQuestionMeta, questionText } = question;
  const { answerBaseQuestion, error } = useAnswerBaseQuestion(answerValue, 700);
  const {
    validationMessage,
    minimum = 0,
    maximum,
  } = caseDataQuestionMeta || {};
  const [weightKg, setWeightKg] = useState(
    parseInt(answerValue) > 0 ? answerValue : ""
  );
  const [weightSt, setWeightSt] = useState("");
  const [weightLb, setWeightLb] = useState("");
  const [compareError, setCompareError] = useState(false);
  const [requiredError, setRequiredError] = useState(false);
  const [invalidError, setInvalidError] = useState(false);
  const bmiData = useBmiData();
  const dispatch = useDispatch();
  const sectionContext = useSectionContext();
  const minWeightLb = 77;
  const maxWeightLb = 442;

  useEffect(() => {
    const hasRequiredError =
      (typeof bmiData !== "undefined" && bmiData.weight && !weightKg) ||
      (!weightKg && sectionContext === SECTION_STATUSES.Incomplete);
    const hasComparisonError =
      typeof bmiData !== "undefined" &&
      (typeof bmiData.weight !== "undefined" || weightKg.trim() !== "") &&
      ((typeof minimum !== "undefined" && +weightKg < minimum) ||
        (typeof maximum !== "undefined" && +weightKg > maximum));
    if (
      !hasComparisonError &&
      !hasRequiredError &&
      parseInt(weightKg) > 0 &&
      ((bmiData && bmiData.weight !== weightKg) || !bmiData)
    ) {
      answerBaseQuestion({ baseQuestionCode: code, answer: weightKg });
      dispatch(setWeight({ weight: weightKg }));
    }
    if (hasRequiredError !== requiredError) {
      setRequiredError(hasRequiredError);
    }
    if (hasComparisonError !== compareError) {
      setCompareError(hasComparisonError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, weightKg, maximum, minimum, sectionContext]);

  useEffect(() => {
    if (answerValue && parseInt(answerValue) > 0) {
      dispatch(setWeight({ weight: answerValue }));
      const { st, lb } = convertToImperial({ kgs: answerValue });
      setWeightSt(st);
      setWeightLb(lb);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (measureUnit) setInvalidError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measureUnit]);

  function convertToImperial({ kgs }) {
    let st = "";
    let lb = "";
    if (kgs && !isNaN(kgs)) {
      const allLbs = Math.round(parseFloat(kgs) * 2.20462262);
      st = parseInt(allLbs / 14).toString();
      lb = parseInt(allLbs % 14).toString();
    }
    return {
      st,
      lb,
    };
  }

  function convertToMetric({ st, lb }) {
    let kgs = "";
    if (st && !isNaN(st) && lb && !isNaN(lb)) {
      const totalLbs = parseFloat(st) * 14 + parseFloat(lb);
      kgs = Math.round(totalLbs / 2.20462262).toString();
    }
    return {
      kgs,
    };
  }

  function weightChangeEvent(value) {
    const { st, lb } = convertToImperial({ kgs: value });
    setWeightSt(st);
    setWeightLb(lb);
    setWeightKg(value);
  }

  function handleWeightKGChange(e) {
    const { value } = e.target;
    weightChangeEvent(value);
  }

  function handleWeightImperialValueChange({ st, lb }) {
    const { kgs } = convertToMetric({ st, lb });
    setWeightKg(kgs);
  }

  function handleWeightLbChange(e) {
    const { value } = e.target;
    setWeightLb(value);
    handleWeightImperialValueChange({ st: weightSt, lb: value });
  }
  function handleWeightStChange(e) {
    const { value } = e.target;
    setWeightSt(value);
    handleWeightImperialValueChange({ st: value, lb: weightLb });
  }

  function handleBlur(e) {
    if (
      (!weightKg || compareError) &&
      answerValue &&
      parseInt(answerValue) > 0
    ) {
      weightChangeEvent(answerValue);
    }
    const requiredError = !weightKg && !answerValue;
    setRequiredError(requiredError);
    setInvalidError(
      (!weightKg || compareError) && answerValue && parseInt(answerValue) > 0
        ? true
        : false
    );
  }
  return (
    <FormField questionText={questionText}>
      <GelContainerLite gutter="none">
        <GelRowLayout gutter="small">
          <GelBoxLayout space="auto" distribution="start">
            {measureUnit === "metric" ? (
              <WeightInput
                name={code}
                value={weightKg}
                unit={unit.kg}
                onChange={handleWeightKGChange}
                onBlur={handleBlur}
                hasError={compareError || requiredError}
                suffix={unit.kg}
              />
            ) : (
              <GelBoxLayout space="auto" distribution="start">
                <WeightInput
                  name={code}
                  value={weightSt}
                  unit={unit.st}
                  onChange={handleWeightStChange}
                  onBlur={handleBlur}
                  hasError={compareError || (requiredError && !weightSt)}
                  suffix={unit.st}
                />
                {/* <GelLabel>{unit.st}</GelLabel> */}
                <WeightInput
                  name={code}
                  value={weightLb}
                  unit={unit.lb}
                  onChange={handleWeightLbChange}
                  onBlur={handleBlur}
                  hasError={compareError || (requiredError && !weightLb)}
                  suffix={unit.lb}
                />
                {/* <GelLabel>{unit.lb}</GelLabel> */}
              </GelBoxLayout>
            )}
            {/* <GelLabel>{unit.kg}</GelLabel> */}
            {/* <GelLabel>or</GelLabel> */}
            {/* <WeightInput
              name={code}
              value={weightSt}
              unit={unit.st}
              onChange={handleWeightStChange}
              onBlur={handleBlur}
              hasError={compareError || requiredError}
            />
            <GelLabel>{unit.st}</GelLabel>
            <WeightInput
              name={code}
              value={weightLb}
              unit={unit.lb}
              onChange={handleWeightLbChange}
              onBlur={handleBlur}
              hasError={compareError || requiredError}
            />
            <GelLabel>{unit.lb}</GelLabel> */}
          </GelBoxLayout>
          {(compareError || requiredError || error) && (
            <WarningMessage
              message={
                (requiredError && ERROR_MESSAGES.REACT_APP_ERR_MSG_REQ) ||
                (compareError &&
                  (measureUnit === "metric"
                    ? validationMessage
                    : parseInt(weightSt) > 0 &&
                      (parseInt(weightLb) > minWeightLb ||
                        parseInt(weightLb) < maxWeightLb)
                    ? ERROR_MESSAGES.WEIGHT_ST_LB_RANGE
                    : ERROR_MESSAGES.WEIGHT_LB_RANGE)) ||
                (error && ERROR_MESSAGES.SERVER_FAILURE)
              }
              errorType
            />
          )}
          {invalidError && (
            <WarningMessage
              message={invalidError && ERROR_MESSAGES.INVALID_INPUT}
              errorType
            />
          )}
        </GelRowLayout>
      </GelContainerLite>
    </FormField>
  );
}

Weight.propTypes = {
  question: PropTypes.object,
  measureUnit: PropTypes.string,
};
