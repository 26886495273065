import { useMutation } from "@apollo/client";
import { useDebouncedCallback } from "use-debounce";
import { getBaseQuestionDataFromCache } from "../utils/commonFunctions";
import { ANSWER_BASE_QUESTION_MUTATION } from "../graphql/mutations/answerBaseQuestion";
import { cache } from "../graphql/config/GraphQLAPIProvider";
import { useCaseId } from "../globalStore/hooks";
import { GET_ALL_QUESTIONS_QUERY } from "../graphql/queries/getAllQuestions";
import { CONFIGURABLES } from "../utils/CONSTANTS";

export function useAnswerBaseQuestion(
  currentAnswer,
  wait = 0,
  onErrorCallback
) {
  const caseId = useCaseId();
  const [updateAnswerForBaseQuestion, { data, ...rest }] = useMutation(
    ANSWER_BASE_QUESTION_MUTATION,
    {
      onError() {
        if (onErrorCallback) onErrorCallback();
      },
    }
  );

  const answerBaseQuestion = useDebouncedCallback(async function ({
    baseQuestionCode,
    answer,
    refetchAllQuestions = false,
    answerBaseQuestionCallback,
  }) {
    try {
      if (
        (currentAnswer === null && answer === "") ||
        currentAnswer === answer
      ) {
        return "update not required";
      }
      let baseQuestionProperties = null;
      const { properties } = getBaseQuestionDataFromCache({
        baseQuestionCode: baseQuestionCode,
      });
      if (properties) {
        baseQuestionProperties = properties.map(function (obj) {
          return {
            propertyName: obj.propertyName,
            propertyValue: obj.propertyValue,
          };
        });
      }
      const result = await updateAnswerForBaseQuestion({
        variables: {
          answerBaseQuestionInput: {
            caseId,
            baseQuestionCode,
            answer: answer.toString(),
            version: CONFIGURABLES.REACT_APP_FACADE_VERSION,
            baseQuestionProperties: baseQuestionProperties,
          },
        },
        refetchQueries: [
          refetchAllQuestions
            ? { query: GET_ALL_QUESTIONS_QUERY, variables: { caseId } }
            : {},
        ],
        awaitRefetchQueries: true,
      });
      if (answerBaseQuestionCallback)
        answerBaseQuestionCallback(result, answer);
      cache.modify({
        id: cache.identify({
          code: baseQuestionCode,
          __typename: "BaseQuestionsType",
        }),
        fields: {
          answerValue() {
            return answer;
          },
          answerText() {
            return answer;
          },
        },
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
  wait);
  function handleUserResponse(e) {
    const { name, value } = e.target;
    if (value) {
      answerBaseQuestion({
        baseQuestionCode: name,
        answer: value,
      });
    }
  }
  return { answerBaseQuestion, handleUserResponse, data, ...rest };
}
