import { gql } from "@apollo/client";
export const DISCLOSURE_FIELDS = gql`
  fragment DisclosureFields on DisclosureType {
    baseQuestionCode
    aliasName
    triggeredCondAlias
    reflexiveQuestions {
      properties {
        propertyName
        propertyValue
      }
      customProperties @client
      questionText
      answerType
      answerText
      answerValue
      options
      listItems @client
      callbackFunctions @client
    }
  }
`;
