import { useLazyQuery, useQuery } from "@apollo/client";
import { useCaseId } from "../globalStore/hooks";

import { GET_CONDITIONS_QUERY } from "../graphql/queries/getConditions";

export function useQueryConditions({ category, limit, searchText }) {
  const caseId = useCaseId();
  const { data, ...rest } = useQuery(GET_CONDITIONS_QUERY, {
    variables: {
      caseId,
      category,
      searchText,
      limit,
    },
  });

  let result;
  if (data) {
    result = data.conditions[0];
  }

  return { data: result, ...rest };
}

export function useQueryConditionsLazy({ category, limit }) {
  const caseId = useCaseId();
  const [executeQuery, { data, ...rest }] = useLazyQuery(GET_CONDITIONS_QUERY);

  function queryConditionsLazy({ searchText }) {
    if (searchText) {
      executeQuery({
        variables: {
          caseId,
          category,
          searchText,
          limit,
        },
      });
    }
  }
  let result;
  if (data) {
    result = data.conditions[0];
  }

  return [queryConditionsLazy, { data: result, ...rest }];
}
