import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { addErrorMessage } from "../globalStore/errorData-slice";
import propTypes from "prop-types";

export function RecordNetworkError({
  baseQuestionCode,
  serverError,
  caseId,
  iscalled,
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (iscalled) {
      dispatch(
        addErrorMessage({
          baseQuestionCode,
          serverError,
          caseId,
          errorType: "serverError",
        })
      );
    }
  }, []);
  return null;
}
RecordNetworkError.propTypes = {
  baseQuestionCode: propTypes.string,
  serverError: propTypes.string,
  caseId: propTypes.string,
  iscalled: propTypes.bool,
};
