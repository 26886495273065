import { createSlice } from "@reduxjs/toolkit";
import { resetStore } from "./shared-actions";
import {
  getBaseQuestionDataFromCache,
  checkChildBQForInconsistency,
} from "../utils/commonFunctions";

const initialState = {};

export const inconsistentAnswersToBQSlice = createSlice({
  name: "inconsistentAnswersToBQ",
  initialState,

  reducers: {
    setInconsistentFlagOfBQ: (state, action) => {
      const { questionCode } = action.payload;
      const { conditionalQuestionMeta } = getBaseQuestionDataFromCache({
        baseQuestionCode: questionCode,
      });
      let isChildBQInconsistent = checkChildBQForInconsistency(
        conditionalQuestionMeta
      );
      if (state[questionCode] !== isChildBQInconsistent) {
        state[questionCode] = isChildBQInconsistent;
      }
    },
    resetInconsistentFlagOfBQ: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(resetStore.fulfilled, () => {
      return initialState;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setInconsistentFlagOfBQ, resetInconsistentFlagOfBQ } =
  inconsistentAnswersToBQSlice.actions;

export default inconsistentAnswersToBQSlice.reducer;
