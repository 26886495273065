export const QUES_INPUT_TYPES = {
  BOOLEAN: "BOOLEAN",
  SELECTION: "SELECTION",
  NUMERIC: "NUMERIC",
  NUMBER: "NUMBER",
  MULTISELECTION: "MULTISELECTION",
  TEXT: "TEXT",
  DATE: "DATE",
  NOTE: "NOTE",
};

export const BASE_QUES_TYPES = {
  HEADING: "HEADING",
  CONDITIONAL: "CONDITIONAL",
  STANDARD: "STANDARD",
  CASE_DATA: "CASE_DATA",
};

export const STANDARD_QUE_ANS_TYPE = {
  ENTER_DETAILS: "ENTER_DETAILS",
  YES_NO: "YES_NO",
};

export const DISCLOSURE_SOURCES = {
  SEARCH: "SEARCH",
  SPECIFIED: "SPECIFIED",
  NONE: "NONE",
  PICKLIST: "PICKLIST",
};

export const CUSTOM_QUES_TYPE = {
  BTN: "BTN",
  READONLY: "READONLY",
  PICKLIST: "PICKLIST",
  PICKLIST_HEADING: "PICKLIST_HEADING",
  TABLE: "TABLE",
  OCCUPATION_SEARCH: "OCCUPATION_SEARCH",
  BMI: "BMI",
  WEIGHT: "WEIGHT",
  HEIGHT: "HEIGHT",
  WARNING: "WARNING",
};

export const CUSTOM_TABLE_TYPE = {
  ASSETS_LIABILITIES: "ASSETS_LIABILITIES",
  SELF_INCOME_EXPENSE: "SELF_INCOME_EXPENSE",
  SELF_INCOME_ADDBACK_ITEMS: "SELF_INCOME_ADDBACK_ITEMS",
  EMP_HISTORY: "EMP_HISTORY",
  EMP_INCOME: "INCOME",
};

export const USER_ROLE = {
  ADVISER: "adviser",
  STAFF: "staff",
};

export const AttributesQuery_ATTRIBUTE_TYPES = {
  WRITABLE: "WRITABLE",
  CALCULATED: "CALCULATED",
  RISK_BASED: "RISK_BASED",
};
export const AttributesQuery_TYPE = {
  LIFE: "LIFE",
  CASEL: "CASE",
};

export const REG_EXP = {
  NUMERIC_POSITIVE_EMPTY: /^(\s*|\d{1,10})$/,
};

export const NUMERIC_QUESTION_TYPE = {
  DEFAULT_MIN_VALUE: 0,
  DEFAULT_MAX_VALUE: 999999999,
};

export const TI_HANDOVER_BQ_CODES = {
  App_TacTI_IND0: "App_TacTI_IND0",
  App_TacTI_CCI_IND0: "App_TacTI_CCI_IND0",
};

export const PROPERTIES = {
  DECIMAL_POINTS: "DECIMAL_POINT",
  PROPERTY_NAME: "propertyName",
};

export const ERROR_TYPES = {
  UNAUTHENTICATED: "UNAUTHENTICATED",
};

export const APPLICATION_STATUS = {
  SUBMITTED: "Submitted",
  TELE_INTERVIEW: "TeleInterview",
};

export const URE_PAGE_CODES = {
  App_HLstyle: "App_HLstyle",
  App_Inc: "App_Inc",
  App_Occ: "App_Occ",
  App_ExiIns: "App_ExiIns",
  App_TacTI: "App_TacTI",
};

export const BOOLEAN_STRING = {
  true: "true",
  false: "false",
};

export const TI_APP_READONLY_PAGES = [
  URE_PAGE_CODES.App_Inc,
  URE_PAGE_CODES.App_Occ,
  URE_PAGE_CODES.App_ExiIns,
];

export const ERROR_MESSAGES = {
  SERVER_FAILURE: "An error has occurred, please re-enter the value.",
  INCONSISTENT_ANSWERS:
    "Your answer to this question is not consistent with your answer(s) to the subsequent question(s)",
  INVALID_INPUT:
    "Blank or invalid value is not accepted. Please enter a valid value.",
  EMP_TABLE_ROW_REQUIRED: "The first employment history record is mandatory.",
  REACT_APP_ERR_MSG_REQ: "This is a required field",
  REACT_APP_DISCLOSURE_SEARCH_REQ_ERR_MSG: "Please enter details",
  REACT_APP_INVALID_DATE: "Please enter a valid date",
  HEIGHT_RANGE_ERROR: "Please enter a value between 4 ft 3 in and 7 ft 5 in.",
  WEIGHT_ST_LB_RANGE: "Please enter a value between 5 st 7 lb and 31 st 8 lb",
  WEIGHT_LB_RANGE: "Please enter a value between 77 and 442 lb.",
};

export const INFO_MESSAGES = {
  SAVE_INCOME_AB_DETAILS:
    "Please click save to confirm income/expenses and addback items details.",
  SAVE_INCOME_DETAILS: "Please click save to confirm income details.",
  SAVE_ASSETS_LIABILITIES_DETAILS:
    "Please click save to confirm asset and liability details.",
  SAVE_EMPLOYMENT_HISTORY_DETAILS:
    "Please click save to confirm employment history details.",
  UNSAVED_WARNING_MESSAGE: "Unsaved value",
};

export const EMPLOYMENT_INCOME_PAGE_BQ_IDS = {
  App_Inc_SEITAB_C1R4_IND0: "App_Inc_SEITAB_C1R4_IND0",
  App_Inc_SEITAB_C2R4_IND0: "App_Inc_SEITAB_C2R4_IND0",
  App_Inc_INC_EMP_IND0: "App_Inc_INC_EMP_IND0",
  App_Inc_SGP_IND0: "App_Inc_SGP_IND0",
  App_Inc_DAI_IND0: "App_Inc_DAI_IND0",
  Is_Refetch_Required: "Is_Refetch_Required",
  App_Inc_S41IES_DBCIONLY_IND0: "App_Inc_S41IES_DBCIONLY_IND0",
  App_Inc_S41SCA_DBCIONLY_IND0: "App_Inc_S41SCA_DBCIONLY_IND0",
  App_Inc_S41IIS_DBCIONLY_IND0: "App_Inc_S41IIS_DBCIONLY_IND0",
  App_Inc_S41IES_IND0: "App_Inc_S41IES_IND0",
  App_Inc_S41SCA_IND0: "App_Inc_S41SCA_IND0",
  App_Inc_S41IIS_IND0: "App_Inc_S41IIS_IND0",
};

export const S41_SuperContributionAmt_BQIds = [
  EMPLOYMENT_INCOME_PAGE_BQ_IDS.App_Inc_S41SCA_IND0,
  EMPLOYMENT_INCOME_PAGE_BQ_IDS.App_Inc_S41SCA_DBCIONLY_IND0,
];

export const S41_IncomeExcludingSuper_BQIds = [
  EMPLOYMENT_INCOME_PAGE_BQ_IDS.App_Inc_S41IES_DBCIONLY_IND0,
  EMPLOYMENT_INCOME_PAGE_BQ_IDS.App_Inc_S41IES_IND0,
];

export const S41_IncomeIncludingSuper_BQIds = [
  EMPLOYMENT_INCOME_PAGE_BQ_IDS.App_Inc_S41IIS_IND0,
  EMPLOYMENT_INCOME_PAGE_BQ_IDS.App_Inc_S41IIS_DBCIONLY_IND0,
];

export const TELE_INTERVIEW_PAGE_BQ_IDS = {
  App_TacTI_IND0: "App_TacTI_IND0",
  App_TacTI_CCI_IND0: "App_TacTI_CCI_IND0",
  App_TacTIR_IND0: "App_TacTIR_IND0",
  App_TacTIR_CCI_IND0: "App_TacTIR_CCI_IND0",
  App_TacTI_ST_CCI_IND0: "App_TacTI_ST_CCI_IND0",
  App_TacTI_Conf_IND0: "App_TacTI_Conf_IND0",
  App_TacTI_CCI_Conf_IND0: "App_TacTI_CCI_Conf_IND0",
};

export const REACT_APP_TI_HANDOVER_BASEQ_CODES = [
  TELE_INTERVIEW_PAGE_BQ_IDS.App_TacTI_IND0,
  TELE_INTERVIEW_PAGE_BQ_IDS.App_TacTI_CCI_IND0,
  TELE_INTERVIEW_PAGE_BQ_IDS.App_TacTIR_IND0,
  TELE_INTERVIEW_PAGE_BQ_IDS.App_TacTIR_CCI_IND0,
  TELE_INTERVIEW_PAGE_BQ_IDS.App_TacTI_ST_CCI_IND0,
];

export const REACT_APP_TI_CONFIDENTIAL_BASEQ_CODES = [
  TELE_INTERVIEW_PAGE_BQ_IDS.App_TacTI_Conf_IND0,
  TELE_INTERVIEW_PAGE_BQ_IDS.App_TacTI_CCI_Conf_IND0,
];

export const PAGE_STATUSES = {
  Completed: "Completed",
  Incomplete: "Incomplete",
  Not_Started: "Not_Started",
};

export const SECTION_STATUSES = {
  Completed: "Completed",
  Incomplete: "Incomplete",
  Not_Started: "Not_Started",
};

export const CASE_ATTRIBUTES = {
  TAXABLE_ANNUAL_INCOME_INPUT: "TAXABLE_ANNUAL_INCOME_INPUT",
  DERIVED_ANNUAL_INCOME_INPUT: "DERIVED_ANNUAL_INCOME_INPUT",
};

export const CDI_NAMES = {
  EMPLOYMENT_INCOME_PAGE: {
    SEMP_INCOME_EXP_TABLE: "SEMP_INCOME_EXP_TABLE",
    SEMP_ADDBACKS_TABLE: "SEMP_ADDBACKS_TABLE",
    EMPLOYED_INCOME_TABLE: "EMPLOYED_INCOME_TABLE",
  },
  EMPLOYMENT_HISTORY_PAGE: {
    EMPLOYMENT_HISTORY_TABLE: "EMPLOYMENT_HISTORY_TABLE",
  },
};

export const CONFIGURABLES = {
  REACT_APP_FACADE_VERSION: 1,
  REACT_APP_EMP_HISTORY_MAX_ROWS: 4,
  REACT_APP_EMP_HISTORY_MIN_DATE: "1900-01-01",
};

export const LIST_NAMES = {
  REACT_APP_HINT_TEXT_LIST_NAME: "CMS texts",
};

export const ALERT_TYPES = {
  INFO_ALERT: "INFO_ALERT",
  INFO_ALERT_TABULAR: "INFO_ALERT_TABULAR",
};
